import { omit } from 'lodash'
import { FC } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { tss } from 'tss-react/mui'

import { Box, Grid, Typography } from '@mui/material'

import { MerchantAccount } from '@shared/api'
import { Checkbox, FieldGroupRow } from '@shared/components'

const useStyles = tss.withName('ShowFields').create(({ theme }) => ({
  title: {
    color: theme.palette['neutral-700'],
    fontFamily: 'Inter',
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: '20px',
    marginBottom: '16px',
  },
}))

interface ShowFieldsProps {
  merchantAccount: MerchantAccount
  isEdit?: boolean
}

const ShowFields: FC<ShowFieldsProps> = ({
  merchantAccount,
  isEdit = false,
}) => {
  const { classes } = useStyles()
  const { t } = useTranslation()
  const { control, watch, setValue } = useFormContext<MerchantAccount>()

  const vtZip = watch('vt_zip')
  const vtStreet = watch('vt_street')

  const getCvvDescription = () => {
    const { require_cvv_on_tokenized_cnp, require_cvv_on_keyed_cnp } =
      merchantAccount
    if (require_cvv_on_tokenized_cnp && require_cvv_on_keyed_cnp) {
      return t(
        'merchant-portal.merchant-account-settings.required-keyed-tokenized-transaction'
      )
    } else if (require_cvv_on_tokenized_cnp) {
      return t(
        'merchant-portal.merchant-account-settings.required-tokenized-transaction'
      )
    } else if (require_cvv_on_keyed_cnp) {
      return t(
        'merchant-portal.merchant-account-settings.required-keyed-transaction'
      )
    } else {
      return ''
    }
  }

  return (
    <>
      <FieldGroupRow>
        <Typography className={classes.title}>
          {t('merchant-portal.merchant-account-settings.show-fields')}
        </Typography>
      </FieldGroupRow>
      {merchantAccount.payment_method === 'cc' && (
        <>
          <FieldGroupRow>
            <Controller
              name="vt_billing_phone"
              control={control}
              render={({ field }) => (
                <Checkbox
                  {...omit(field, 'ref')}
                  checked={!!field.value}
                  label={t('common.phone-billing')}
                  disabled={!isEdit}
                  testId="vt-billing-phone-checkbox"
                />
              )}
            />
          </FieldGroupRow>

          <FieldGroupRow columnSpacing="80px">
            <Grid item xs={6}>
              <Controller
                name="vt_zip"
                control={control}
                render={({ field }) => (
                  <Checkbox
                    {...omit(field, 'ref')}
                    checked={!!field.value}
                    onChange={(e) => {
                      if (!!field.value) {
                        setValue('vt_require_zip', false)
                      }
                      field.onChange(e)
                    }}
                    label={t(
                      'merchant-portal.merchant-account-settings.billing-postal-code'
                    )}
                    disabled={!isEdit}
                    testId="vt-zip-checkbox"
                  />
                )}
              />
            </Grid>

            <Grid item xs={6}>
              <Controller
                name="vt_require_zip"
                control={control}
                render={({ field }) => (
                  <Checkbox
                    {...omit(field, 'ref')}
                    checked={!!field.value}
                    label={t(
                      'merchant-portal.merchant-account-settings.require-postal-code'
                    )}
                    disabled={!vtZip || !isEdit}
                    testId="vt-require-zip-checkbox"
                  />
                )}
              />
            </Grid>
          </FieldGroupRow>

          <FieldGroupRow columnSpacing="80px">
            <Grid item xs={6}>
              <Controller
                name="vt_street"
                control={control}
                render={({ field }) => (
                  <Checkbox
                    {...omit(field, 'ref')}
                    onChange={(e) => {
                      if (!!field.value) {
                        setValue('vt_require_street', false)
                      }
                      field.onChange(e)
                    }}
                    checked={!!field.value}
                    label={t('common.street-billing')}
                    disabled={!isEdit}
                    testId="vt-street-checkbox"
                  />
                )}
              />
            </Grid>

            <Grid item xs={6}>
              <Controller
                name="vt_require_street"
                control={control}
                render={({ field }) => (
                  <Checkbox
                    {...omit(field, 'ref')}
                    checked={!!field.value}
                    label={t(
                      'merchant-portal.merchant-account-settings.require-street'
                    )}
                    disabled={!vtStreet || !isEdit}
                    testId="vt-require-street-checkbox"
                  />
                )}
              />
            </Grid>
          </FieldGroupRow>

          <FieldGroupRow>
            <Controller
              name="vt_clerk_number"
              control={control}
              render={({ field }) => (
                <Checkbox
                  {...omit(field, 'ref')}
                  checked={!!field.value}
                  label={t('common.clerk-number')}
                  disabled={!isEdit}
                  testId="vt-clerk-number-checkbox"
                />
              )}
            />
          </FieldGroupRow>
        </>
      )}

      <FieldGroupRow>
        <Controller
          name="vt_show_currency"
          control={control}
          render={({ field }) => (
            <Checkbox
              {...omit(field, 'ref')}
              checked={!!field.value}
              label={t('merchant-portal.merchant-account-settings.currency')}
              disabled={!isEdit}
              testId="vt-show-currency-checkbox"
            />
          )}
        />
      </FieldGroupRow>

      <FieldGroupRow>
        <Controller
          name="vt_show_custom_fields"
          control={control}
          render={({ field }) => (
            <Checkbox
              {...omit(field, 'ref')}
              checked={!!field.value}
              label={t(
                'merchant-portal.merchant-account-settings.custom-fields'
              )}
              disabled={!isEdit}
              testId="vt-show-custom-fields-checkbox"
            />
          )}
        />
      </FieldGroupRow>

      <FieldGroupRow>
        <Controller
          name="vt_show_company_name"
          control={control}
          render={({ field }) => (
            <Checkbox
              {...omit(field, 'ref')}
              checked={!!field.value}
              label={t(
                'merchant-portal.merchant-account-settings.customer-company-name'
              )}
              disabled={!isEdit}
              testId="vt-show-company-name-checkbox"
            />
          )}
        />
      </FieldGroupRow>

      {merchantAccount.payment_method === 'cc' && (
        <>
          <FieldGroupRow>
            <Controller
              name="vt_cvv"
              control={control}
              render={({ field }) => (
                <Checkbox
                  {...omit(field, 'ref')}
                  checked={!!field.value}
                  label={t('merchant-portal.merchant-account-settings.cvv')}
                  description={getCvvDescription()}
                  disabled={
                    merchantAccount.require_cvv_on_keyed_cnp ||
                    merchantAccount.require_cvv_on_tokenized_cnp ||
                    !isEdit
                  }
                  testId="vt-cvv-checkbox"
                />
              )}
            />
          </FieldGroupRow>

          <FieldGroupRow>
            <Controller
              name="vt_order_num"
              control={control}
              render={({ field }) => (
                <Checkbox
                  {...omit(field, 'ref')}
                  checked={!!field.value}
                  label={t('common.order-number')}
                  disabled={!isEdit}
                  testId="vt-order-num-checkbox"
                />
              )}
            />
          </FieldGroupRow>
        </>
      )}

      {isEdit && (
        <>
          <FieldGroupRow columnSpacing="80px">
            <Grid item xs={6}>
              <Controller
                name="vt_enable_sales_tax"
                control={control}
                render={({ field }) => (
                  <Checkbox
                    {...omit(field, 'ref')}
                    checked={!!field.value}
                    label={t('common.tax')}
                    disabled
                    testId="vt-enable-sales-tax-checkbox"
                  />
                )}
              />
            </Grid>

            <Grid item xs={6}>
              <Controller
                name="vt_override_sales_tax_allowed"
                control={control}
                render={({ field }) => (
                  <Checkbox
                    {...omit(field, 'ref')}
                    checked={!!field.value}
                    label={t(
                      'merchant-portal.merchant-account-settings.allow-overriding-calculated-tax'
                    )}
                    disabled
                    testId="vt-override-sales-tax-allowed-checkbox"
                  />
                )}
              />
            </Grid>
          </FieldGroupRow>
        </>
      )}
    </>
  )
}

export default ShowFields
