import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import {
  PageLayoutContainer,
  PageLayoutContainerMain,
  PageLayoutDivider,
} from '@shared/components'
import { useFtpPortalHubCommunication, useLocations } from '@shared/hooks'

import { GeneralSettings } from './components/general-settings/GeneralSettings'
import { ServicesSettings } from './components/services-settings/ServicesSettings'
import { TagsView } from './components/tags-view/TagsView'

export default function SettingsLocation() {
  const { setAppBarTitle } = useFtpPortalHubCommunication()
  const { selectedLocation } = useLocations()
  const { t } = useTranslation()

  useEffect(() => {
    setAppBarTitle(t('common.location'), null, t('common.settings'))
  }, [])

  return (
    <PageLayoutContainer isButtonBarAtBottom>
      <PageLayoutContainerMain isFullWidth>
        <GeneralSettings locationInfo={selectedLocation} />
        <PageLayoutDivider />
        <ServicesSettings />
        <PageLayoutDivider />
        <TagsView tags={selectedLocation?.tags || []} />
      </PageLayoutContainerMain>
    </PageLayoutContainer>
  )
}
