import { ColDef } from 'ag-grid-community'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import DateRangeFilter from '@shared/ag-grid/pagination-table/filters/date-range-filter/DateRangeFilter'
import PaginationTable from '@shared/ag-grid/pagination-table/PaginationTable'
import {
  ReportSettledTransaction,
  SurchargeCalculation,
} from '@shared/api/src/schemas/types'
import { HasPermission, UserNotAllowed, AccountType } from '@shared/components'
import {
  useFtpPortalHubCommunication,
  useLocations,
  useEnforceLogin,
  useFilterModel,
} from '@shared/hooks'
import {
  filterSettledTransactionTypesValues,
  filterAccountTypeCCValues,
} from '@shared/mapping/gateway-transactions'
import {
  EnumServiceName,
  settledTransactionCCStatusCodes,
  settledTransactionsCCEntryModes,
} from '@shared/types'
import {
  currency,
  filterParams,
  DataSource,
  formatDatetime,
  compareLabels,
} from '@shared/utils'

export default function SettledTransactionsCC() {
  //TODO: add translation when email receipt or other functionality is added
  const { t } = useTranslation()

  const { setAppBarTitle } = useFtpPortalHubCommunication()
  const { selectedLocation, setSelectedLocation } = useLocations()
  const { user } = useEnforceLogin()

  const useQuery = () => {
    return new URLSearchParams(location.search)
  }

  const TransactionsReportPrivs = ['v2.reports.get', 'v2.transactions.get']

  useEffect(() => {
    setAppBarTitle(
      t('common.settled-transactions-cc'),
      null,
      t('common.reporting')
    )
  }, [])

  const datasource = useMemo(() => {
    return new DataSource(
      'settled-transactions-reports-cc',
      {
        filterVariant: 'filterBy',
      },
      {
        'filter[location_id]': selectedLocation?.id,
      }
    )
  }, [selectedLocation?.id])

  const columnDefs: ColDef<
    ReportSettledTransaction & {
      transaction_amount?: number
      transaction_date_ts?: number
      surcharge?: SurchargeCalculation
    }
  >[] = useMemo(
    () => [
      {
        headerName: t('common.transaction-id'),
        field: 'trxn_src_ck',
        floatingFilter: true,
        filter: 'agTextColumnFilter',
        sortable: true,
        filterParams: {
          filterOptions: ['contains'],
          maxNumConditions: 1,
        },
      },
      {
        headerName: t('common.date-transaction'),
        field: 'transaction_date_ts',
        floatingFilter: true,
        filter: DateRangeFilter,
        valueGetter: (params) => {
          const timestamp = params.data?.transaction_date_ts
          return formatDatetime(timestamp * 1000, user?.tz) || '-'
        },
        filterParams: {
          type: 'past',
          forceOnlyCustom: true,
          showTimePicker: true,
        },
        sortable: true,
        sort: 'desc',
      },
      {
        headerName: t('common.amount-transaction'),
        field: 'transaction_amount',
        floatingFilter: true,
        filter: 'agNumberColumnFilter',
        type: 'rightAligned',
        filterParams: {
          allowedCharPattern: '\\d\\-\\.\\$\\,',
          numberParser: (value: number) => {
            return value ? value * 100 : null
          },
          filterOptions: ['equals'],
          maxNumConditions: 1,
        },
        sortable: true,
        valueGetter: (params) =>
          currency(params.data?.transaction_amount / 100),
      },
      {
        headerName: t('common.transaction-type'),
        field: 'transaction_type',
        valueGetter: (params) => {
          const type = filterSettledTransactionTypesValues.find(
            (item) => item.value === params.data?.transaction_type
          )
          return type ? type.label : params.data?.transaction_type
        },
        filter: 'agSetColumnFilter',
        filterParams: {
          values: filterSettledTransactionTypesValues.map((item) => item.value),
          valueFormatter: (params) => {
            const type = filterSettledTransactionTypesValues.find(
              (item) => item.value === params.value
            )
            return type ? type.label : params.value
          },
          comparator: compareLabels(
            Object.fromEntries(
              filterSettledTransactionTypesValues.map(({ value, label }) => [
                value,
                label,
              ])
            )
          ),
        },
        floatingFilter: true,
        sortable: true,
      },
      {
        headerName: t('merchant-portal.batch-number'),
        field: 'batch_number',
        floatingFilter: true,
        filter: 'agTextColumnFilter',
        filterParams: {
          filterOptions: ['contains'],
          maxNumConditions: 1,
        },
        sortable: true,
      },
      {
        headerName: t('common.account-type'),
        field: 'account_type',
        filter: 'agSetColumnFilter',
        floatingFilter: true,
        filterParams: {
          values: filterAccountTypeCCValues.map((option) => option.value),
          valueFormatter: (params) => {
            const option = filterAccountTypeCCValues.find(
              (option) => option.value === params.value
            )
            return option ? option.label : params.value
          },
        },
        cellRenderer: (data) => {
          return <AccountType type={data.value} />
        },
        sortable: true,
      },
      {
        headerName: t('common.amount-surcharge'),
        field: 'surcharge_amount',
        floatingFilter: true,
        filter: 'agNumberColumnFilter',
        type: 'rightAligned',
        filterParams: {
          allowedCharPattern: '\\d\\-\\.\\$\\,',
          numberParser: (value: number) => {
            return value ? value * 100 : null
          },
          filterOptions: ['equals'],
          maxNumConditions: 1,
        },
        valueGetter: (params) => {
          const data = params.data
          const surchargeAmount = data.surcharge?.surcharge_amount
          return surchargeAmount ? currency(surchargeAmount / 100) : '$0.00'
        },
      },
      {
        headerName: t('common.status'),
        field: 'transaction_status',
        floatingFilter: true,
        filter: 'agSetColumnFilter',
        sortable: true,
        cellRenderer: (params) => {
          const status = settledTransactionCCStatusCodes[params.value]
          return status ? status : params.value
        },
        filterParams: {
          values: Object.keys(settledTransactionCCStatusCodes),
          valueFormatter: (params) => {
            const option = settledTransactionCCStatusCodes[params.value]
            return option ? option : params.value
          },
          comparator: compareLabels(settledTransactionCCStatusCodes),
        },
      },
      {
        headerName: t('common.auth-code'),
        field: 'auth_code',
        floatingFilter: true,
        filter: 'agTextColumnFilter',
        sortable: true,
        filterParams: {
          filterOptions: ['contains'],
          maxNumConditions: 1,
        },
      },
      {
        headerName: t('common.token-last-four'),
        field: 'last_four_digits',
        filterParams: {
          numberParser: (value: number) => value, // Needed to prevent removing leading zeros
          filterOptions: ['equals'],
          maxNumConditions: 1,
        },
        floatingFilter: true,
        filter: 'agNumberColumnFilter',
        sortable: true,
      },
      {
        headerName: t('merchant-portal.merchant-account-id'),
        field: 'merchant_id',
        floatingFilter: true,
        filter: 'agTextColumnFilter',
        sortable: true,
        filterParams: {
          filterOptions: ['contains'],
          maxNumConditions: 1,
        },
      },
      {
        headerName: t('common.account-holder-name'),
        field: 'account_holder_name',
        floatingFilter: true,
        filter: 'agTextColumnFilter',
        sortable: true,
        filterParams: {
          filterOptions: ['contains'],
          maxNumConditions: 1,
        },
      },
      {
        headerName: t('merchant-portal.card-classification'),
        field: 'card_classification',
        filter: 'agSetColumnFilter',
        floatingFilter: true,
        filterParams: {
          values: filterAccountTypeCCValues.map((item) => item.value),
          valueFormatter: (params) => {
            const option = filterAccountTypeCCValues.find(
              (item) => item.value === params.value
            )
            return option ? option.label : params.value
          },
          comparator: compareLabels(
            Object.fromEntries(
              filterAccountTypeCCValues.map(({ value, label }) => [
                value,
                label,
              ])
            )
          ),
        },
        cellRenderer: (params) => {
          const option = filterAccountTypeCCValues.find(
            (item) => item.value === params.value
          )
          return option ? option.label : params.value
        },
        sortable: true,
      },
      {
        headerName: t('common.entry-mode'),
        field: 'entry_mode',
        floatingFilter: true,
        filter: 'agSetColumnFilter',
        sortable: true,
        cellRenderer: (params) => {
          const option = settledTransactionsCCEntryModes.find(
            (item) => item.code === params.value
          )
          return option ? option.description : params.value
        },
        filterParams: {
          filterOptions: ['contains'],
          maxNumConditions: 1,
          values: settledTransactionsCCEntryModes.map((option) => option.code),
          valueFormatter: (params) => {
            const option = settledTransactionsCCEntryModes.find(
              (item) => item.code === params.value
            )
            return option ? option.description : params.value
          },
          comparator: compareLabels(
            Object.fromEntries(
              settledTransactionsCCEntryModes
                .slice()
                .sort((a, b) => a.description.localeCompare(b.description))
                .map(({ code, description }) => [code, description])
            )
          ),
        },
      },
      {
        headerName: t('common.order-number'),
        field: 'order_number',
        floatingFilter: true,
        filter: 'agNumberColumnFilter',
        sortable: true,
        filterParams: {
          filterOptions: ['contains'],
          maxNumConditions: 1,
        },
      },
      {
        headerName: t('merchant-portal.provider-name'),
        field: 'provider_name',
        floatingFilter: true,
        filter: 'agTextColumnFilter',
        sortable: true,
        filterParams: {
          filterOptions: ['contains'],
          maxNumConditions: 1,
        },
      },
    ],
    []
  )

  const defaultColDef = useMemo(
    () => ({
      resizable: true,
    }),
    []
  )

  useEffect(() => {
    const handleLocationChange = async (event) => {
      const newLocation = event.detail.location
      await setSelectedLocation(newLocation)
    }

    window.addEventListener(
      'FTP_NAVIGATOR_LOCATION_CHANGED',
      handleLocationChange
    )

    return () => {
      window.removeEventListener(
        'FTP_NAVIGATOR_LOCATION_CHANGED',
        handleLocationChange
      )
    }
  }, [])

  return (
    <section>
      <HasPermission
        allPermissions={TransactionsReportPrivs}
        unauthorizedComponent={<UserNotAllowed />}
      >
        <>
          <PaginationTable<ReportSettledTransaction>
            columnDefs={columnDefs}
            rowModelType={'serverSide'}
            serverSideDatasource={datasource}
            defaultColDef={defaultColDef}
            showExportButton={true}
            showClearFiltersButton={true}
            serviceName={EnumServiceName.SettledTransactionsReportsCc}
            guidingId="reports-settledtransactionscc"
          />
        </>
      </HasPermission>
    </section>
  )
}
