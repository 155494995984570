import { omit } from 'lodash'
import { FC, useMemo } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { tss } from 'tss-react/mui'

import { HelpOutline } from '@mui/icons-material'
import { Box, Grid, Tooltip, Typography } from '@mui/material'

import { MerchantAccount } from '@shared/api'
import {
  Checkbox,
  FieldGroupRow,
  FieldGroupViewItem,
  SelectComponent,
} from '@shared/components'
import { useEnforceLogin } from '@shared/hooks'
import { getTransactionTypesForAccount } from '@shared/utils'

const useStyles = tss.withName('GeneralSettings').create(({ theme }) => ({
  label: {
    color: `${theme?.palette['neutral-900']} !important`,
    fontFamily: 'Inter !important',
    fontSize: '14px !important',
    fontWeight: '500 !important',
    lineHeight: '20px !important',
  },
  title: {
    color: theme.palette['neutral-700'],
    fontFamily: 'Inter',
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: '20px',
    marginBottom: '16px',
  },
}))

interface GeneralSettingsProps {
  merchantAccount: MerchantAccount
  isEdit?: boolean
}

const GeneralSettings: FC<GeneralSettingsProps> = ({
  merchantAccount,
  isEdit = false,
}) => {
  const { classes } = useStyles()
  const { t } = useTranslation()
  const { control } = useFormContext()
  const { user } = useEnforceLogin()

  const transactionTypeOptions = useMemo(() => {
    const validDefaults = ['sale', 'debit', 'authonly']
    return getTransactionTypesForAccount(merchantAccount, user)
      .filter(({ value }) => validDefaults.includes(value))
      .map(({ label, value }) => ({
        label: t(
          `merchant-portal.merchant-account-settings.${label
            .replace(/ /g, '')
            .toLowerCase()}`
        ),
        value,
      }))
  }, [merchantAccount, user])

  return (
    <>
      <FieldGroupRow>
        <Typography className={classes.title}>
          {t('common.general-settings')}
        </Typography>
      </FieldGroupRow>
      <>
        <FieldGroupRow>
          <Controller
            name="vt_enable"
            control={control}
            render={({ field }) => (
              <Checkbox
                {...omit(field, 'ref')}
                checked={!!field.value}
                label={t(
                  'merchant-portal.merchant-account-settings.virtual-terminal-enabled'
                )}
                disabled
                testId="vt-enable-checkbox"
              />
            )}
          />
        </FieldGroupRow>
        <FieldGroupRow>
          <Controller
            name="debit_allow_void"
            control={control}
            render={({ field }) => (
              <Checkbox
                {...omit(field, 'ref')}
                checked={!!field.value}
                label={t(
                  'merchant-portal.merchant-account-settings.allowed-void-on-debit'
                )}
                disabled
                testId="debit-allow-void-checkbox"
              />
            )}
          />
        </FieldGroupRow>
        <FieldGroupRow>
          <Controller
            name="display_avs"
            control={control}
            render={({ field }) => (
              <Checkbox
                {...omit(field, 'ref')}
                checked={!!field.value}
                label={t(
                  'merchant-portal.merchant-account-settings.display-avs-on-receipt'
                )}
                disabled={!isEdit}
                testId="display-avs-checkbox"
              />
            )}
          />
        </FieldGroupRow>
        <FieldGroupRow>
          <Controller
            name="vt_enable_tip"
            control={control}
            render={({ field }) => (
              <Checkbox
                {...omit(field, 'ref')}
                checked={!!field.value}
                label={
                  <Box display="flex" alignItems="center">
                    <Typography className={classes.label}>
                      {t('common.tip')}
                    </Typography>
                    <Tooltip
                      title={t(
                        'merchant-portal.merchant-account-settings.tip-tooltip'
                      )}
                    >
                      <HelpOutline
                        color="disabled"
                        fontSize="small"
                        sx={{
                          marginLeft: '8px',
                        }}
                      />
                    </Tooltip>
                  </Box>
                }
                disabled={!isEdit}
                testId="vt-enable-tip-checkbox"
              />
            )}
          />
        </FieldGroupRow>

        <FieldGroupRow marginY="24px">
          <Grid item xs={12}>
            {isEdit ? (
              <Controller
                name="default_transaction_type"
                control={control}
                render={({ field }) => (
                  <SelectComponent
                    {...field}
                    label={t(
                      'merchant-portal.merchant-account-settings.default-transaction-type'
                    )}
                    options={transactionTypeOptions}
                    style={{
                      height: '44px',
                    }}
                    testId="default-transaction-type-select"
                  />
                )}
              />
            ) : (
              <FieldGroupViewItem
                title={t(
                  'merchant-portal.merchant-account-settings.default-transaction-type'
                )}
                value={t(
                  `merchant-portal.merchant-account-settings.${merchantAccount.default_transaction_type
                    .replace(/ /g, '-')
                    .toLowerCase()}`
                )}
                valueTestID="default-transaction-type-value"
              />
            )}
          </Grid>
        </FieldGroupRow>
      </>
    </>
  )
}

export default GeneralSettings
