export const countryCodeToCountryName = (
  code: 'US' | 'CA' | null | undefined
) => {
  switch (code) {
    case 'US':
      return 'United States of America'
    case 'CA':
      return 'Canada'
    default:
      return ''
  }
}

export default countryCodeToCountryName
