import { useTranslation } from 'react-i18next'

import { Batch } from '@shared/api/src'
import { StatusProps, Status } from '@shared/components'
import { codeToBatchTransactionStatus } from '@shared/mapping/gateway-transactions'
import { BatchStatusType } from '@shared/types'

interface BatchStatusProps {
  statusCode: Batch['processing_status_id']
}

const BatchStatus = ({ statusCode }: BatchStatusProps) => {
  const { t } = useTranslation()

  const getStatus = (status: BatchStatusType): StatusProps => {
    let statusData: StatusProps = {
      text: undefined,
      backgroundColor: undefined,
    }

    switch (status) {
      case BatchStatusType['RE-PROCESS']:
        statusData.text = t('common.re-process')
        statusData.backgroundColor = 'yellow'
        break
      case BatchStatusType.PROCESSING:
        statusData.text = t('common.processing')
        statusData.backgroundColor = 'yellow'
        break
      case BatchStatusType.SETTLED:
        statusData.text = t('common.settled')
        statusData.backgroundColor = 'teal'
        break
      case BatchStatusType.TO_SETTLE:
        statusData.text = t('common.to-settle')
        statusData.backgroundColor = 'sky'
        break
      case BatchStatusType.ERROR:
        statusData.text = t('common.error')
        statusData.backgroundColor = 'red'
        break
      default:
        statusData.text = t('common.unknown')
        statusData.backgroundColor = 'gray'
        break
    }

    return statusData
  }

  const { text, backgroundColor } = getStatus(
    codeToBatchTransactionStatus(statusCode)
  )

  return <Status text={text} backgroundColor={backgroundColor} />
}

export default BatchStatus
