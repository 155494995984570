export const accountTypeLabel: { [key: string]: string } = {
  amex: 'American Express',
  diners: 'Diners Club',
  disc: 'Discover',
  discover: 'Discover',
  jcb: 'JCB',
  mastercard: 'Mastercard',
  mc: 'Mastercard',
  visa: 'Visa',
  checking: 'Checkings',
  'personal checking': 'Personal Checkings',
  'business checking': 'Business Checkings',
  savings: 'Savings',
  'personal savings': 'Personal Savings',
  'business savings': 'Business Savings',
  debit: 'Debit',
  cash: 'Cash',
  ebt: 'EBT',
}

export type AccountTypeProps = {
  type: string
  showImage?: boolean
  showLabel?: boolean
  style?: React.CSSProperties
  isEbt?: boolean
}
