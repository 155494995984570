import { DateTime } from 'luxon'

import { ProductTransactionAutoSettle } from '../../api/src'

export function formatAutoCloseTimes(times: ProductTransactionAutoSettle[]) {
  return times
    .map((t) => {
      const dateTime = DateTime.fromFormat(t.batch_close_time, 'HHmm')
      return dateTime.toFormat('h:mma')
    })
    .join(', ')
}
