import { Statement } from '../../types'

enum Months {
  January = '01',
  February = '02',
  March = '03',
  April = '04',
  May = '05',
  June = '06',
  July = '07',
  August = '08',
  September = '09',
  October = '10',
  November = '11',
  December = '12',
}

const months: Record<string, string> = Months

/**
 * Returns the statement's document period formatted
 */
export const getDocumentPeriod = (statement: Statement): number => {
  if (statement.document_period_identifier === null) {
    const descriptionParts = statement.document_description.split(' ')
    const concat = `${descriptionParts[1]}${months[descriptionParts[0]]}`

    return parseInt(concat)
  }

  return statement.document_period_identifier
}
