import React from 'react'
export const SettingsIcon = () => (
  <svg
    width="32"
    height="33"
    viewBox="0 0 32 33"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="settings-icon">
      <path
        id="Path"
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M5.19977 21.262L7.06377 21.5473C8.43977 21.758 9.42244 22.99 9.3211 24.378L9.18377 26.2593C9.14377 26.8073 9.44244 27.3233 9.9371 27.562L11.3158 28.226C11.8104 28.4646 12.4011 28.3753 12.8051 28.0033L14.1904 26.7233C15.2118 25.7793 16.7878 25.7793 17.8104 26.7233L19.1958 28.0033C19.5998 28.3766 20.1891 28.4646 20.6851 28.226L22.0664 27.5606C22.5598 27.3233 22.8571 26.8086 22.8171 26.262L22.6798 24.378C22.5784 22.99 23.5611 21.758 24.9371 21.5473L26.8011 21.262C27.3438 21.1793 27.7811 20.7726 27.9038 20.2366L28.2438 18.746C28.3664 18.21 28.1491 17.654 27.6958 17.3446L26.1398 16.2793C24.9918 15.4926 24.6411 13.9566 25.3344 12.75L26.2744 11.1153C26.5478 10.6393 26.5024 10.0433 26.1598 9.61396L25.2064 8.41796C24.8638 7.98862 24.2931 7.81129 23.7678 7.97262L21.9651 8.52462C20.6331 8.93262 19.2131 8.24862 18.7011 6.95396L18.0104 5.20329C17.8078 4.69129 17.3131 4.35529 16.7624 4.35662L15.2344 4.36062C14.6838 4.36196 14.1904 4.70062 13.9904 5.21396L13.3171 6.94462C12.8104 8.24729 11.3838 8.93662 10.0478 8.52596L8.17044 7.94996C7.64377 7.78729 7.07044 7.96596 6.72777 8.39796L5.78111 9.59529C5.43844 10.0286 5.39711 10.626 5.67577 11.102L6.63711 12.7406C7.34511 13.9486 6.99844 15.4993 5.84377 16.29L4.30644 17.3433C3.85311 17.654 3.63577 18.21 3.75844 18.7446L4.09844 20.2353C4.21977 20.7726 4.65711 21.1793 5.19977 21.262V21.262Z"
        stroke="#D64123"
        stroke-width="1.7"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        id="Path_2"
        d="M18.5545 13.8446C19.9652 15.2553 19.9652 17.5433 18.5545 18.954C17.1439 20.3646 14.8559 20.3646 13.4452 18.954C12.0345 17.5433 12.0345 15.2553 13.4452 13.8446C14.8559 12.434 17.1439 12.434 18.5545 13.8446"
        stroke="#D64123"
        stroke-width="1.7"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
  </svg>
)
