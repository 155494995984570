import { Box } from '@mui/material'

import { UserPref } from '@shared/api/src'

import NotificationPreference from '../notification-preference/NotificationPreference'

interface NotificationListProps {
  prefs: UserPref[]
  subscriptions: string[]
  isEdit?: boolean
  onChange?: (value: string, type: string) => void
  selectedPrefs?: { id: string; type: string }[]
}

export default function NotificationList({
  prefs,
  subscriptions,
  isEdit = false,
  onChange,
  selectedPrefs,
}: NotificationListProps) {
  return (
    <Box sx={{ maxWidth: '816px' }}>
      {prefs?.map((pref, index) => {
        return (
          <NotificationPreference
            key={index}
            id={pref.id}
            title={pref?.title}
            emailChecked={
              !isEdit
                ? subscriptions.includes(pref.id)
                : !!selectedPrefs?.find(
                    (p) => p.id === pref.id && p.type === 'email'
                  )
            }
            isEdit={isEdit}
            onChange={(value, type) => {
              onChange(value, type)
            }}
          />
        )
      })}
    </Box>
  )
}
