import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { tss } from 'tss-react/mui'

import { Box, CircularProgress, Grid, Paper } from '@mui/material'

import { ACHReject, api } from '@shared/api/src'
import { HasPermission, NoData } from '@shared/components'
import { useLocations } from '@shared/hooks'
import { EnumServiceName } from '@shared/types'

import { getFiltersForUserTimezone } from '../utils/GetFiltersForUserTimezone'
import { InfoPanel } from '../utils/InfoPanel'

const useStyles = tss.withName('ACHRejectsChart').create({
  loadingContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
  },
  paperContainer: {
    backgroundColor: 'white',
    padding: '2rem',
    height: '240px',
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '1rem',
  },
  titleH2: {
    margin: 0,
    padding: 0,
  },
})

const ACHRejectsChart = ({
  locationId,
  merchantAccountId,
  tz,
}: {
  locationId: string
  merchantAccountId?: string
  tz?: string
}) => {
  const { classes } = useStyles()
  const { t } = useTranslation()

  const { hasMerchantAccountACHActive } = useLocations()

  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const [achRejectsTransactions, setAchRejectsTransactions] = useState<
    ACHReject[]
  >([])

  useEffect(() => {
    if (!locationId) return

    const fetchData = async () => {
      try {
        await fetchAchRejectsData()
      } catch (error) {
        setIsLoading(false)
        setIsError(true)
        console.error(error)
      }
    }
    fetchData()
  }, [locationId, merchantAccountId])

  const fetchAchRejectsData = async () => {
    if (!hasMerchantAccountACHActive) return

    try {
      setIsLoading(true)
      const response = await api.service('ach-rejects').find({
        query: {
          filter: {
            location_id: locationId,
            product_transaction_id: merchantAccountId,
          },
        },
      })
      setAchRejectsTransactions(response.length ? response : [])
      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
      setIsError(true)
      console.error(error)
    }
  }

  const getTitleById = (id: string) => {
    switch (id) {
      case 'today':
        return t('common.filter-today')
      case 'received_last7days':
        return t('common.filter-last-7-days')
      default:
        return id
    }
  }

  const checkTransactions = () => {
    if (achRejectsTransactions.length === 0 || !achRejectsTransactions) {
      return false
    } else {
      return true
    }
  }

  const filters = getFiltersForUserTimezone(tz)

  const achRejectsTransactionsWithFilters = achRejectsTransactions.map(
    (transaction) => ({
      ...transaction,
      filter: filters[transaction.id],
    })
  )

  return (
    <Paper
      className={classes.paperContainer}
      elevation={0}
      data-guiding-id="mp-dashboard-achrejects"
    >
      <div className={classes.titleContainer}>
        <h2 className={classes.titleH2}>{t('merchant-portal.ach-rejects')}</h2>
      </div>
      {!isLoading &&
      checkTransactions() &&
      achRejectsTransactionsWithFilters.length ? (
        <HasPermission
          allPermissions={['v2.reports.get']}
          unauthorizedComponent={
            <NoData
              title={t('common.validations.not-available-feature')}
              label={t('common.validations.please-contact-support')}
              icon="error"
            />
          }
        >
          <Grid container spacing={2}>
            {achRejectsTransactionsWithFilters.map((transaction) => (
              <Grid item xs={12} sm={6} key={transaction.id}>
                <InfoPanel
                  to={`/merchant/reports/gateway/ach-rejects?filter[date_reported_ts][$gte]=${transaction.filter.$gte}&filter[date_reported_ts][$lte]=${transaction.filter.$lte}`}
                  title={getTitleById(transaction.id)}
                  cashAmount={transaction.values[1]}
                  details={`${transaction.values[0]} ${t(
                    'merchant-portal.rejects'
                  )}`}
                  serviceName={EnumServiceName.AchRejectsReports}
                  guidingId={`mp-dashboard-achrejects-${transaction.id}`}
                />
              </Grid>
            ))}
          </Grid>
        </HasPermission>
      ) : (
        <Box className={classes.loadingContainer}>
          {isLoading ? (
            <CircularProgress data-testid="loading-icon" size={45} />
          ) : isError ? (
            <NoData
              title={t('common.validations.loading-data')}
              label={t('common.validations.loading-data-explainer')}
              icon="error"
              style={{ marginBottom: 5 }}
            />
          ) : (
            <NoData
              title={t('common.validations.not-available-data')}
              label={t('common.validations.not-available-data-display')}
              style={{ marginBottom: 5 }}
            />
          )}
        </Box>
      )}
    </Paper>
  )
}

export default ACHRejectsChart
