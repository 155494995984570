import { useState, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { tss } from 'tss-react/mui'

import { Box, Typography } from '@mui/material'

import { ProductRecurring, QuickInvoiceSetting } from '@shared/api/src'
import {
  ActionModal,
  Checkbox,
  SelectComponent as Select,
} from '@shared/components'
import { useAuthorization, useLocations } from '@shared/hooks'
import { checkPermission } from '@shared/utils'

import NotificationPreference from '../../components/NotificationPreference'
import { updateCustomerNotificationSettings } from '../../utils/update-customer-notification-settings/UpdateCustomerNotificationSettings'

export interface EditMyNotificationsProps<T> {
  open?: boolean
  handleClose: (event: any) => void
  recurring?: ProductRecurring
  quickInvoice?: QuickInvoiceSetting
  handleShowNotification: (message: string, type: 'success' | 'error') => void
}

const useStyles = tss
  .withName('EditCustomerNotifications')
  .create(({ theme }) => ({
    modal: {
      width: '65%',
      maxWidth: '700px',
      overflowX: 'hidden',
      overflowY: 'hidden',
    },
    description: {
      fontFamily: 'Inter',
      fontSize: '14px',
      fontWeight: '400',
      lineHeight: '20px',
      color: theme.palette['neutral-600'],
      marginBottom: '24px',
    },
    sectionTitle: {
      display: 'flex',
      color: theme.palette['neutral-700'],
      fontFamily: 'Inter',
      fontSize: '16px',
      fontWeight: '500',
    },
    selectContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      gap: '16px',
      margin: '16px 0',
      width: '100%',
    },
    selectContainerTitle: {
      color: theme.palette['neutral-900'],
      fontFamily: 'Inter',
      fontSize: '14px',
      fontWeight: '500',
      display: 'flex',
    },
    checkAll: {
      marginTop: '20px',
      marginBottom: '20px',
      display: 'flex',
      justifyContent: 'flex-end',
      backgroundColor: theme.palette['neutral-100'],
    },
    notificationBox: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      alignSelf: 'stretch',
      borderRadius: '6px',
      border: `1px solid ${theme.palette['neutral-200']}`,
      background: theme.palette['neutral-100'],
      padding: '8px 0px',
    },
  }))

export default function EditCustomerNotifications({
  open,
  handleClose,
  recurring,
  quickInvoice,
  handleShowNotification,
}: EditMyNotificationsProps<any>) {
  const { t } = useTranslation()
  const { classes } = useStyles()
  const { selectedLocation, setSelectedLocation } = useLocations()
  const { userPermissionSet } = useAuthorization()

  const [isSaving, setIsSaving] = useState(false)
  const [applyToAllCustomers, setApplyToAllCustomers] = useState(false)

  const locationHasTransactions =
    (selectedLocation.product_transactions as unknown[]) &&
    (selectedLocation.product_transactions as unknown[]).length > 0

  const [recurringNotificationSettings, setRecurringNotificationSettings] =
    useState({
      notification_days_default: recurring?.notification_days_default ?? 0,
      expire_notification_email_enable:
        recurring?.expire_notification_email_enable ?? false,
      send_declined_notifications:
        recurring?.send_declined_notifications ?? false,
    })

  const [
    quickInvoiceNotificationSettings,
    setQuickInvoiceNotificationSettings,
  ] = useState({
    default_notification_days_before_due_date:
      quickInvoice?.default_notification_days_before_due_date || 0,
    default_notification_days_after_due_date:
      quickInvoice?.default_notification_days_after_due_date || 0,
    default_notification_on_due_date:
      quickInvoice?.default_notification_on_due_date ?? false,
  })

  const [locationNotificationSettings, setLocationNotificationSettings] =
    useState({
      contact_email_trx_receipt_default:
        selectedLocation?.contact_email_trx_receipt_default ?? false,
    })

  const daysOptions = useMemo(
    () =>
      Array.from({ length: 7 }, (_, index) => {
        const dayLabel = index + 1 === 1 ? t('common.day') : t('common.days')
        return {
          label: `${index + 1} ${dayLabel}`,
          value: index + 1,
        }
      }),
    [t]
  )

  const saveMyNotifications = async () => {
    setIsSaving(true)

    try {
      const settings = {
        quickInvoice: quickInvoiceNotificationSettings,
        recurring: recurringNotificationSettings,
        location: locationNotificationSettings,
      }

      await updateCustomerNotificationSettings(
        selectedLocation.id,
        applyToAllCustomers,
        recurring?.id || '',
        quickInvoice?.id || '',
        settings,
        userPermissionSet
      )

      setSelectedLocation({
        ...selectedLocation,
        contact_email_trx_receipt_default:
          settings.location.contact_email_trx_receipt_default,
      })

      handleShowNotification(
        t(
          'merchant-portal.notifications-settings.customer-notifications-successfully-updated'
        ),
        'success'
      )
      handleClose(true)
    } catch (error) {
      handleShowNotification(error.message, 'error')
      console.error(error)
    } finally {
      setIsSaving(false)
    }
  }

  const handleNotificationChange = (
    section: string,
    id: string,
    value?: any
  ) => {
    const setStateMap = {
      quickInvoice: setQuickInvoiceNotificationSettings,
      recurring: setRecurringNotificationSettings,
      location: setLocationNotificationSettings,
    }

    setStateMap[section]((prev) => {
      let newValue = value !== undefined ? value : !prev[id]

      if (
        id === 'notification_days_default' ||
        id === 'default_notification_days_after_due_date' ||
        id === 'default_notification_days_before_due_date'
      ) {
        newValue = Number(newValue) || 0
      }

      return {
        ...prev,
        [id]: newValue,
      }
    })
  }

  const handleSelectAllChange = (section: string, checked: boolean) => {
    const setStateMap = {
      quickInvoice: setQuickInvoiceNotificationSettings,
      recurring: setRecurringNotificationSettings,
      location: setLocationNotificationSettings,
    }

    const updateSettingsMap = {
      quickInvoice: {
        default_notification_on_due_date: checked,
        default_notification_days_before_due_date: checked ? 1 : 0,
        default_notification_days_after_due_date: checked ? 1 : 0,
      },
      recurring: {
        notification_days_default: checked ? 1 : 0,
        expire_notification_email_enable: checked,
        send_declined_notifications: checked,
      },
      location: {
        contact_email_trx_receipt_default: checked,
      },
    }

    setStateMap[section](updateSettingsMap[section])
  }

  const isAllSelected = (settings: any, keys: string[]) => {
    return keys.every((key) => settings[key])
  }

  const allSelected = {
    quickInvoice: isAllSelected(quickInvoiceNotificationSettings, [
      'default_notification_on_due_date',
      'default_notification_days_before_due_date',
      'default_notification_days_after_due_date',
    ]),
    recurring: isAllSelected(recurringNotificationSettings, [
      'notification_days_default',
      'expire_notification_email_enable',
      'send_declined_notifications',
    ]),
    location: isAllSelected(locationNotificationSettings, [
      'contact_email_trx_receipt_default',
    ]),
  }

  const renderNotificationPreference = (
    section: string,
    title: string,
    description: string,
    id: string,
    checked: boolean
  ) => (
    <NotificationPreference
      title={t(title)}
      description={t(description)}
      emailChecked={checked}
      isEdit
      onChange={() => handleNotificationChange(section, id)}
      id={id}
    />
  )

  const renderDaySelect = (
    section: string,
    label: string,
    value: number,
    id: string
  ) => (
    <Box className={classes.selectContainer}>
      <Typography className={classes.selectContainerTitle}>
        {t(label)}
      </Typography>
      <Select
        options={daysOptions}
        value={typeof value !== 'number' ? 1 : value}
        onChange={(e) =>
          handleNotificationChange(section, id, Number(e.target.value))
        }
        style={{ width: '120px', marginRight: '16px' }}
      />
    </Box>
  )
  return (
    <ActionModal
      open={open}
      title={t(
        'merchant-portal.notifications-settings.edit-customer-notifications'
      )}
      onClose={() => handleClose(false)}
      isScrollableContent
      buttons={[
        {
          label: t('common.cancel'),
          color: 'secondary',
          onClick: () => handleClose(false),
        },
        {
          label: t('common.save-changes'),
          onClick: saveMyNotifications,
          isLoading: isSaving,
          color: 'primary',
        },
      ]}
      className={classes.modal}
    >
      <Typography className={classes.description}>
        {t(
          'merchant-portal.notifications-settings.settings-for-notifications-sent-to-customers-at-this-locations'
        )}
      </Typography>

      {checkPermission(userPermissionSet, 'v2.adminlocations.put') &&
        locationHasTransactions && (
          <>
            <Typography className={classes.sectionTitle}>
              {t('merchant-portal.notifications-settings.transaction')}
            </Typography>

            <div className={classes.checkAll}>
              <Checkbox
                checked={allSelected.location}
                label={t('merchant-portal.notifications-settings.all-email')}
                onChange={(e) =>
                  handleSelectAllChange(
                    'location',
                    (e.target as HTMLInputElement).checked
                  )
                }
              />
            </div>

            {renderNotificationPreference(
              'location',
              'merchant-portal.notifications-settings.customer-receives-transaction-receipt',
              'merchant-portal.notifications-settings.customer-receives-transaction-receipt-description',
              'contact_email_trx_receipt_default',
              locationNotificationSettings.contact_email_trx_receipt_default
            )}

            <Box className={classes.notificationBox}>
              <Checkbox
                label={t(
                  'merchant-portal.notifications-settings.apply-to-all-current-customers'
                )}
                description={t(
                  'merchant-portal.notifications-settings.apply-to-all-current-customers-description'
                )}
                checked={applyToAllCustomers}
                onChange={(e) => {
                  setApplyToAllCustomers(!applyToAllCustomers)
                }}
              />
            </Box>
          </>
        )}

      {checkPermission(userPermissionSet, 'v2.productrecurrings.put') &&
        recurring &&
        Object.keys(recurring).length > 0 && (
          <>
            <Typography
              className={classes.sectionTitle}
              style={{ paddingTop: '32px' }}
            >
              {t('common.recurring-billing')}
            </Typography>

            <div className={classes.checkAll}>
              <Checkbox
                checked={allSelected.recurring}
                label={t('merchant-portal.notifications-settings.all-email')}
                onChange={(e) =>
                  handleSelectAllChange(
                    'recurring',
                    (e.target as HTMLInputElement).checked
                  )
                }
              />
            </div>

            {renderNotificationPreference(
              'recurring',
              'merchant-portal.notifications-settings.notification-before-recurring-billing',
              'merchant-portal.notifications-settings.notification-before-recurring-billing-description',
              'notification_days_default',
              Boolean(recurringNotificationSettings.notification_days_default)
            )}

            {recurringNotificationSettings.notification_days_default > 0 &&
              renderDaySelect(
                'recurring',
                'merchant-portal.notifications-settings.number-of-days-before-next-run-date',
                recurringNotificationSettings.notification_days_default,
                'notification_days_default'
              )}

            {renderNotificationPreference(
              'recurring',
              'merchant-portal.notifications-settings.token-expiring-soon',
              'merchant-portal.notifications-settings.token-expiring-soon-description',
              'expire_notification_email_enable',
              recurringNotificationSettings.expire_notification_email_enable
            )}

            {renderNotificationPreference(
              'recurring',
              'merchant-portal.notifications-settings.transaction-declined',
              'merchant-portal.notifications-settings.transaction-declined-description',
              'send_declined_notifications',
              recurringNotificationSettings.send_declined_notifications
            )}
          </>
        )}

      {checkPermission(userPermissionSet, 'v2.quickinvoicesettings.put') &&
        quickInvoice &&
        !!Object.keys(quickInvoice).length && (
          <>
            <Typography className={classes.sectionTitle}>
              {t('common.quick-invoice')}
            </Typography>

            <div className={classes.checkAll}>
              <Checkbox
                checked={allSelected.quickInvoice}
                label={t('merchant-portal.notifications-settings.all-email')}
                onChange={(e) =>
                  handleSelectAllChange(
                    'quickInvoice',
                    (e.target as HTMLInputElement).checked
                  )
                }
              />
            </div>

            {renderNotificationPreference(
              'quickInvoice',
              'merchant-portal.notifications-settings.notification-on-due-date',
              'merchant-portal.notifications-settings.notification-on-due-date-description',
              'default_notification_on_due_date',
              quickInvoiceNotificationSettings.default_notification_on_due_date
            )}

            {renderNotificationPreference(
              'quickInvoice',
              'merchant-portal.notifications-settings.notification-before-due-date',
              'merchant-portal.notifications-settings.notification-before-due-date-description',
              'default_notification_days_before_due_date',
              Boolean(
                quickInvoiceNotificationSettings.default_notification_days_before_due_date
              )
            )}

            {quickInvoiceNotificationSettings.default_notification_days_before_due_date >
              0 &&
              renderDaySelect(
                'quickInvoice',
                'merchant-portal.notifications-settings.number-of-days-before-due-date',
                quickInvoiceNotificationSettings.default_notification_days_before_due_date,
                'default_notification_days_before_due_date'
              )}

            {renderNotificationPreference(
              'quickInvoice',
              'merchant-portal.notifications-settings.notification-after-due-date',
              'merchant-portal.notifications-settings.notification-after-due-date-description',
              'default_notification_days_after_due_date',
              Boolean(
                quickInvoiceNotificationSettings.default_notification_days_after_due_date
              )
            )}

            {quickInvoiceNotificationSettings.default_notification_days_after_due_date >
              0 &&
              renderDaySelect(
                'quickInvoice',
                'merchant-portal.notifications-settings.number-of-days-after-due-date',
                quickInvoiceNotificationSettings.default_notification_days_after_due_date,
                'default_notification_days_after_due_date'
              )}
          </>
        )}
    </ActionModal>
  )
}
