import { Datum } from '@nivo/line'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { tss } from 'tss-react/mui'

import {
  Grid,
  Paper,
  CircularProgress,
  Box,
  useMediaQuery,
  useTheme,
  SelectChangeEvent,
} from '@mui/material'

import { api } from '@shared/api'
import { RecurringTransactionsHistory } from '@shared/api/src/schemas/types'
import {
  HasPermission,
  SelectComponent,
  BaseChart,
  BarChart,
  NoData,
} from '@shared/components'
import { useLocations } from '@shared/hooks'
import { ChartEntity, Filter } from '@shared/types'
import { currency, formatRawDate } from '@shared/utils'

import mapBarChart from './utils/mapBarChart'

const useStyles = tss
  .withName('RecurringBillingHistoryChart')
  .create(({ theme }) => ({
    p: {
      padding: 0,
      margin: '0',
    },
    selectorContainer: {
      textAlign: 'left',
      padding: '10 10',
      position: 'relative',
      display: 'grid',
      placeContent: 'end',
    },
    tooltip: {
      padding: '0.5rem',
      backgroundColor: '#4B5563',
      color: '#F3F4F6',
      fontSize: '0.75rem',
      fontFamily: 'Inter',
    },
    loadingContainer: {
      display: 'grid',
      height: '100%',
      placeContent: 'center',
    },
    chartContainer: {
      position: 'relative',
    },
  }))

const filters: Filter[] = [
  {
    id: 'last30days',
    name: 'Last 30 days',
  },
  {
    id: 'last12months',
    name: 'Last 12 months',
  },
]

const entities: ChartEntity[] = [
  { id: 'paid', name: 'Paid', code: 111 },
  { id: 'unpaid', name: 'Unpaid', code: 101 },
]

const colorsByChartEntity: Record<string, string> = {
  paid: '#4CB48E',
  unpaid: '#FFA96B',
}

const RecurringBillingHistoryChart = (props: {
  merchantAccountId?: string
}) => {
  //TODO: Implement Merchant Account Filter in the future
  const { merchantAccountId } = props
  const { classes } = useStyles()
  const { t } = useTranslation()
  const theme = useTheme()
  const { hasRecurringActive, selectedLocation } = useLocations()

  const [recurringTransactions, setRecurringTransactions] = useState<Datum[]>(
    []
  )
  const [filterType, setFilterType] = useState('last30days')

  const [barTransactions, setBarTransactions] = useState<Datum>(undefined)

  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)

  useEffect(() => {
    if (!selectedLocation) return

    const fetchData = async () => {
      try {
        await fetchRecurringData()
      } catch (error) {
        setIsLoading(false)
        setIsError(true)
        console.error(error)
      }
    }

    fetchData()
  }, [filterType, selectedLocation, merchantAccountId])

  useEffect(() => {
    if (
      recurringTransactions &&
      Object.keys(recurringTransactions).length > 0
    ) {
      const arrayTransactions = recurringTransactions[filterType]
      setBarTransactions(arrayTransactions)
    } else {
      setBarTransactions(undefined)
    }
  }, [filterType, recurringTransactions])

  const formatX = (filter: string, date: number) => {
    if (filter === 'last30days') {
      return formatRawDate(new Date(date), 'MM/dd')
    }
    if (filter === 'last12months') {
      return formatRawDate(new Date(date), 'MMM')
    }
  }

  const fetchRecurringData = async () => {
    if (!hasRecurringActive) {
      setRecurringTransactions([])
      return
    }

    const filter: { location_id: string; product_transaction_id?: string } = {
      location_id: selectedLocation.id,
    }
    // if (merchantAccountId) {
    //   filter.product_transaction_id = merchantAccountId
    // }

    try {
      setIsLoading(true)
      const response = await api
        .service('recurring-transactions-history')
        .find({
          query: {
            filter,
          },
        })

      let arrayFilter = []
      arrayFilter = filters.filter((f) => f.id === filterType)

      setRecurringTransactions(
        mapBarChart(
          arrayFilter,
          response?.length ? response : [],
          entities,
          formatX
        )
      )

      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
      setIsError(true)
      console.error(error)
    }
  }

  const handleChange = (event: SelectChangeEvent) => {
    setFilterType(event.target.value as string)
  }

  const getColorByChartEntity = (chartEntity: { id: string }): string =>
    colorsByChartEntity[chartEntity.id]

  const isMobileSm = useMediaQuery(theme?.breakpoints.down('sm'))
  const desktopTitleIsWrapped = useMediaQuery('(max-width: 1551px)')

  const getBarChartMarginBottom = () => {
    if (isMobileSm) return 150
    return desktopTitleIsWrapped ? 110 : 80
  }

  const selector = (
    <div className={classes.selectorContainer}>
      <SelectComponent
        placeholder={t('common.date-range-select')}
        options={[
          {
            label: t('common.filter-last-30-days'),
            value: 'last30days',
          },
          {
            label: t('common.filter-last-12-months'),
            value: 'last12months',
          },
        ]}
        value={filterType}
        onChange={handleChange}
        style={{ minWidth: '150px', height: '32px' }}
        guidingId="mp-dashboard-recurringtransactionhistory-filter"
      />
    </div>
  )

  const transactionsInfo = (
    <Grid container spacing={2} justifyContent="center" alignItems="center">
      <Grid item xs={10} md={6}>
        <div>
          <p className={classes.p}>{t('common.total-transactions')}</p>
          <p className={classes.p} style={{ fontWeight: 'bold' }}>
            {barTransactions ? barTransactions['transactions'] : 0}
          </p>
        </div>
      </Grid>
      <Grid item xs={12} md={6}>
        <div>
          <p className={classes.p}>{t('common.total-amount')}</p>
          <p className={classes.p} style={{ fontWeight: 'bold' }}>
            {currency(barTransactions ? Number(barTransactions['amount']) : 0)}
          </p>
        </div>
      </Grid>
    </Grid>
  )

  const checkTransactions = () => {
    if (barTransactions.transactions === 0) {
      return false
    } else {
      return true
    }
  }

  return (
    <div className={classes.chartContainer}>
      <BaseChart
        title={t('merchant-portal.recurring-billing-history')}
        extraButton={selector}
        transactionsInfo={transactionsInfo}
        guidingId="mp-dashboard-recurringtransactionhistory"
      >
        <HasPermission
          allPermissions={['v2.reports.get', 'v2.recurrings.get']}
          unauthorizedComponent={
            <NoData
              title={t('common.validations.not-available-feature')}
              label={t('common.validations.please-contact-support')}
              icon="error"
            />
          }
        >
          {!isLoading && !!barTransactions && checkTransactions() ? (
            <BarChart
              data={barTransactions.data.length > 0 ? barTransactions.data : []}
              keys={['paid', 'unpaid']}
              groupMode="stacked"
              indexBy="date"
              valueScale={{
                type: 'linear',
              }}
              axisLeft={{
                format: (value) => currency(value),
              }}
              colors={(chartEntity) => colorsByChartEntity[chartEntity.id]}
              margin={{
                top: 50,
                bottom: getBarChartMarginBottom(),
                left: barTransactions.amount
                  ? 9 * barTransactions.amount.toString().length
                  : 22,
              }}
              axisBottom={{
                tickValues: barTransactions.data
                  .filter((d, i) => filterType !== 'last30days' || i % 4 === 0)
                  .map((d) => d.date),
              }}
              enableGridY={false}
              enableLabel={false}
              legends={[
                {
                  dataFrom: 'keys',
                  data: [
                    {
                      id: 'paid',
                      label: t('common.paid'),
                      color: getColorByChartEntity({ id: 'paid' }),
                    },
                    {
                      id: 'unpaid',
                      label: t('common.unpaid'),
                      color: getColorByChartEntity({ id: 'unpaid' }),
                    },
                  ],
                  itemTextColor: '#000',
                  anchor: 'top-left',
                  direction: 'row',
                  translateX: -22,
                  translateY: -35,
                  itemsSpacing: 10,
                  itemDirection: 'left-to-right',
                  itemWidth: 80,
                  itemHeight: 20,
                  symbolShape: 'circle',
                  symbolSize: 10,
                },
              ]}
              tooltip={({ id, indexValue, data }) => {
                const amount = data[id].toString()
                const dataStatus =
                  id === 'paid' ? t('common.paid') : t('common.unpaid')

                return (
                  <Paper elevation={3} className={classes.tooltip}>
                    <Grid container direction={'column'}>
                      <Grid item>
                        <span>{dataStatus}</span>
                      </Grid>
                      <Grid item>
                        <span>
                          {t('common.date')}: {indexValue}
                        </span>
                      </Grid>
                      <Grid item>
                        <span>
                          {t('common.transactions')}:{' '}
                          {data ? data[id + 'TotalTransactions'] : 0}
                        </span>{' '}
                      </Grid>
                      <Grid item>
                        <span>
                          {t('common.amount')}:{' '}
                          {data ? currency(parseFloat(amount)) : currency(0)}
                        </span>{' '}
                      </Grid>
                    </Grid>
                  </Paper>
                )
              }}
              onMouseEnter={(_datum, event: any) => {
                event.currentTarget.style.cursor = 'pointer' // Hacky way, otherwise nivo doesn't allow to change cursor
              }}
            />
          ) : (
            <Box className={classes.loadingContainer}>
              {isLoading ? (
                <CircularProgress data-testid="loading-icon" size={45} />
              ) : isError ? (
                <NoData
                  title={t('common.validations.loading-data')}
                  label={t('common.validations.loading-data-explainer')}
                  icon="error"
                />
              ) : (
                <NoData
                  title={t('common.validations.not-available-data')}
                  label={t('common.validations.not-available-data-display')}
                />
              )}
            </Box>
          )}
        </HasPermission>
      </BaseChart>
    </div>
  )
}

export default RecurringBillingHistoryChart
