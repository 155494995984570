import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { api } from '@shared/api/src'
import {
  ProductRecurring,
  QuickInvoiceSetting,
  UserPref,
  UserPrefSubscribe,
} from '@shared/api/src/schemas/types'
import {
  HasPermission,
  PageLayoutContainer,
  PageLayoutContainerMain,
  PageLayoutDivider,
} from '@shared/components'
import {
  useEnforceLogin,
  useFtpPortalHubCommunication,
  useLocations,
} from '@shared/hooks'

import CustomerNotifications from './customer-notifications/CustomerNotifications'
import MyNotifications from './my-notifications/MyNotifications'
import MyNotificationsMethods from './my-notifications-methods/MyNotificationsMethods'

export default function Notifications() {
  const { setAppBarTitle } = useFtpPortalHubCommunication()
  const { t } = useTranslation()

  const { user } = useEnforceLogin()
  const { selectedLocation } = useLocations()

  const [allUserPrefs, setAllUserPrefs] = useState<UserPref[]>([])
  const [subscribedUserPrefs, setSubscribedUserPrefs] = useState<
    UserPrefSubscribe[]
  >([])
  const [productRecurring, setProductRecurring] =
    useState<ProductRecurring | null>()
  const [quickInvoiceSetting, setQuickInvoiceSetting] =
    useState<QuickInvoiceSetting | null>()

  useEffect(() => {
    setAppBarTitle(t('common.notifications'), null, t('common.settings'))
  }, [setAppBarTitle, t])

  const getAllUserPrefs = async () => {
    const prefs = await api.service('user-prefs').find()
    setAllUserPrefs(prefs)
  }

  const getSubscribedUserPrefs = async () => {
    const subscribed = await api.service('user-pref-subscribes').find({
      query: {
        filter: {
          user_id: user?.id,
          location_id: selectedLocation.id,
        },
      },
    })
    setSubscribedUserPrefs(subscribed)
  }

  const getAllProductsRecurring = async () => {
    const recurrings = await api.service('product-recurrings').find({
      query: {
        filter: {
          location_id: selectedLocation.id,
        },
      },
    })
    setProductRecurring(recurrings[0] || null)
  }

  const getAllQuickInvoiceSettings = async () => {
    const settings = await api.service('quick-invoice-settings').find({
      query: {
        filter: {
          location_id: selectedLocation.id,
        },
      },
    })

    setQuickInvoiceSetting(settings[0] || null)
  }

  const getInitialData = async () => {
    try {
      await Promise.all([
        getAllUserPrefs(),
        getSubscribedUserPrefs(),
        getAllProductsRecurring(),
        getAllQuickInvoiceSettings(),
      ])
    } catch (e) {
      console.error(e)
    }
  }

  useEffect(() => {
    if (!selectedLocation) return

    getInitialData()
  }, [selectedLocation, user])

  return (
    <PageLayoutContainer>
      <PageLayoutContainerMain isFullWidth>
        <MyNotificationsMethods user={user} />
        <PageLayoutDivider />

        <HasPermission
          allPermissions={['v2.userprefs.get', 'v2.useruserprefs.get']}
        >
          <>
            <MyNotifications
              allPrefs={allUserPrefs}
              subscribedPrefs={subscribedUserPrefs}
            />
            <PageLayoutDivider />
          </>
        </HasPermission>

        <CustomerNotifications
          location={selectedLocation}
          recurring={productRecurring}
          quickInvoice={quickInvoiceSetting}
          onSettingsUpdated={getInitialData}
        />
      </PageLayoutContainerMain>
    </PageLayoutContainer>
  )
}
