import { ColDef } from 'ag-grid-community'
import { AgGridReact } from 'ag-grid-react'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { tss } from 'tss-react/mui'

import { Link, SelectChangeEvent } from '@mui/material'

import PaginationTable from '@shared/ag-grid/pagination-table/PaginationTable'
import { MerchantAccount } from '@shared/api/src/schemas/types'
import {
  HasPermission,
  Loading,
  SelectComponent,
  SelectOption,
  UserNotAllowed,
} from '@shared/components'
import AppTheme from '@shared/design'
import {
  useFtpPortalHubCommunication,
  useAuthorization,
  useLocations,
} from '@shared/hooks'
import Download from '@shared/icons/Download'
import { EnumServiceName, Statement } from '@shared/types'
import {
  checkPermission,
  sortMerchantAccounts,
  DataSource,
  compareLabels,
  formatYearMonth,
} from '@shared/utils'

import { getReportDownloadUrl } from './api'
import { statementTypeCodes } from '../types'

const StatementsPrivs = ['v2.billingstatements.get']

const useStyles = tss.withName('MerchantStatements').create(({ theme }) => ({
  downloadLink: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '8px',
    cursor: 'pointer',
    textDecoration: 'none',
    color: theme.palette['neutral-700'],
    borderRadius: '6px',
  },
}))

export default function Statements() {
  const { t } = useTranslation()
  const { classes } = useStyles(AppTheme)
  const gridRef = useRef<AgGridReact>(null)
  const { setAppBarTitle } = useFtpPortalHubCommunication()
  const { userPermissionSet } = useAuthorization()
  const { selectedLocation } = useLocations()

  const [merchantAccounts, setMerchantAccounts] = useState<MerchantAccount[]>(
    []
  )
  const [selectedMerchantAccount, setSelectedMerchantAccount] =
    useState<MerchantAccount | null>(null)
  const [hasLoadedMerchantAccounts, setHasLoadedMerchantAccounts] =
    useState(false)

  const defaultColDef = useMemo(
    () => ({
      resizable: true,
    }),
    []
  )

  const getGridRef = useCallback((ref) => {
    gridRef.current = ref
  }, [])

  useEffect(() => {
    setAppBarTitle(t('common.statements'))
  }, [setAppBarTitle, t])

  useEffect(() => {
    if (!selectedLocation) return

    const sorted = sortMerchantAccounts(selectedLocation.product_transactions)

    const defaultMerchantAccount =
      sorted.find((ma) => ma.id === selectedLocation.default_cc) ??
      sorted.find((ma) => ma.id === selectedLocation.default_ach) ??
      sorted[0]

    setMerchantAccounts(sorted)
    setSelectedMerchantAccount(defaultMerchantAccount || null)
    setHasLoadedMerchantAccounts(true)
  }, [selectedLocation])

  const getReportDownloadUrlApi = useCallback(
    async (documentId: string) => {
      if (
        !selectedMerchantAccount ||
        !checkPermission(userPermissionSet, 'v2.files.get')
      )
        return

      try {
        const url = await getReportDownloadUrl(
          documentId,
          selectedMerchantAccount,
          userPermissionSet
        )
        if (url) {
          window.open(url, '_blank', 'noreferrer, noopener')
        }
      } catch (error) {
        console.error(error)
      }
    },
    [selectedMerchantAccount, userPermissionSet]
  )

  const columnDefs: ColDef<Statement>[] = useMemo(
    () => [
      {
        headerName: t('merchant-portal.statement-type'),
        field: 'statement_type_code',
        filter: 'agSetColumnFilter',
        floatingFilter: true,
        sortable: true,
        filterParams: {
          values: Object.keys(statementTypeCodes).map((key) => Number(key)),
          valueFormatter: (params) => {
            const type = statementTypeCodes[params.value]
            return type ? type : params.value
          },
          comparator: compareLabels(statementTypeCodes),
        },
        valueGetter: (params) => {
          const typeCode = params.data?.statement_type_code
          return statementTypeCodes[typeCode]
        },
      },
      {
        headerName: t('common.date'),
        field: 'document_period_identifier',
        valueGetter: (params) => {
          const period = params?.data?.document_period_identifier
          return formatYearMonth(String(period))
        },
      },
      {
        headerName: '',
        colId: 'download',
        cellRenderer: (params) => (
          <Link
            href="#"
            onClick={async (e) => {
              e.preventDefault()
              await getReportDownloadUrlApi(params.data.document_id)
            }}
            className={classes.downloadLink}
          >
            <Download style={{ marginRight: 4 }} />
            {t('common.download')}
          </Link>
        ),
        sortable: false,
        filter: false,
      },
    ],
    [getReportDownloadUrlApi, selectedLocation, t]
  )

  const datasource = useMemo(() => {
    if (!selectedMerchantAccount) return null
    return new DataSource(
      'statements',
      {},
      {
        merchantId: selectedMerchantAccount.id,
      }
    )
  }, [selectedMerchantAccount])

  const merchantAccountsOptions: SelectOption<string>[] = useMemo(() => {
    return merchantAccounts.map((ma) => ({
      label: ma.title,
      value: ma.id,
    }))
  }, [merchantAccounts])

  const handleChange = (event: SelectChangeEvent) => {
    setSelectedMerchantAccount(
      merchantAccounts.find(({ id }) => id === event.target.value) || null
    )
  }

  return (
    <section>
      <HasPermission
        allPermissions={StatementsPrivs}
        unauthorizedComponent={<UserNotAllowed />}
      >
        <>
          {!hasLoadedMerchantAccounts ? (
            <Loading />
          ) : merchantAccountsOptions.length > 1 ? (
            <div
              style={{
                marginBottom: 20,
                display: 'flex',
                justifyContent: 'flex-end',
              }}
            >
              <SelectComponent
                data-testid="select-component"
                guidingId="merchant-statements-merchantaccount"
                options={merchantAccountsOptions}
                value={selectedMerchantAccount?.id ?? ''}
                onChange={handleChange}
              />
            </div>
          ) : null}

          <PaginationTable<Statement>
            getRowId={(data) => data.data.document_id}
            guidingId="statements"
            columnDefs={columnDefs}
            rowModelType="serverSide"
            defaultColDef={defaultColDef}
            getGridRef={getGridRef}
            serverSideDatasource={datasource}
            serviceName={EnumServiceName.Statements}
          />
        </>
      </HasPermission>
    </section>
  )
}
