import { Link as RouterLink } from 'react-router-dom'
import { tss } from 'tss-react/mui'

import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import { Box, Typography, Paper } from '@mui/material'

import { useReportFilters } from '@shared/hooks'
import { EnumServiceName } from '@shared/types'
import { currency } from '@shared/utils'

interface InfoPanelProps {
  title: string
  cashAmount: number
  details: string
  to?: string
  serviceName?: EnumServiceName
  guidingId?: string
}

const useStyles = tss.withName('InfoPanel').create({
  panelLink: {
    display: 'block',
    flexGrow: 1,
    cursor: 'pointer',
    width: '100%',
    backgroundColor: '#eff6ff',
    border: '1px none #bfdbfe',
    borderRadius: '0.375rem',
    padding: '0.9rem 0.75rem 0.75rem',
    textDecoration: 'none',
    color: '#1f2937',
    '&:hover': {
      color: '#ffffff',
      backgroundColor: '#0284c7',
    },
  },
  panelContent: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  title: {
    fontWeight: 'bold',
    fontSize: '0.875rem',
    textDecoration: 'underline',
    marginBottom: '0.6rem',
  },
  details: {
    fontSize: '0.75rem',
  },
  cashAmount: {
    fontSize: '1.125rem',
  },
})

export const InfoPanel = ({
  title,
  cashAmount,
  details,
  to,
  serviceName,
  guidingId,
}: InfoPanelProps) => {
  const { classes } = useStyles()
  const { clearFilter } = useReportFilters()

  const handleClick = () => {
    if (serviceName) {
      clearFilter(serviceName)
    }
  }

  return (
    <Paper
      component={RouterLink}
      to={to}
      className={classes.panelLink}
      onClick={handleClick}
      data-guiding-id={guidingId}
    >
      <Box className={classes.panelContent}>
        <Typography variant="subtitle2" className={classes.title}>
          {title}
        </Typography>
        <ArrowForwardIcon />
      </Box>
      <Typography variant="h6" className={classes.cashAmount}>
        {currency(cashAmount)}
      </Typography>

      <Typography variant="caption" className={classes.details}>
        {details}
      </Typography>
    </Paper>
  )
}
