import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Box } from '@mui/material'

import { Location, ProductRecurring, QuickInvoiceSetting } from '@shared/api'
import {
  FieldGroupContainer,
  FieldGroupRow,
  FieldGroupViewItem,
  HasPermission,
  Notification,
} from '@shared/components'

import EditCustomerNotifications from './components/EditCustomerNotifications'
import NotificationPreference from '../components/notification-preference/NotificationPreference'

interface CustomerNotificationsProps {
  location: Location
  recurring?: ProductRecurring
  quickInvoice?: QuickInvoiceSetting
  onSettingsUpdated: () => void
}

export default function CustomerNotifications({
  location,
  recurring,
  quickInvoice,
  onSettingsUpdated,
}: CustomerNotificationsProps) {
  const { t } = useTranslation()
  const [editCustomerNotifications, setEditCustomerNotifications] =
    useState(false)

  const [notificationMessage, setNotificationMessage] = useState<string | null>(
    null
  )
  const [notificationType, setNotificationType] = useState<'success' | 'error'>(
    'success'
  )
  const [showNotification, setShowNotification] = useState(false)

  const locationHasTransactions =
    location.product_transactions && location.product_transactions.length > 0

  const handleShowNotification = (
    message: string,
    type: 'success' | 'error'
  ) => {
    setNotificationMessage(message)
    setNotificationType(type)
    setShowNotification(true)
  }

  const formatDaysValue = (days?: number) => {
    if (days === undefined) return ''
    return `${days} ${days === 1 ? t('common.day') : t('common.days')}`
  }

  return locationHasTransactions ||
    !!recurring?.id ||
    (!!quickInvoice?.id &&
      location?.product_transactions?.some(
        (prod) => !!prod.quick_invoice_allow
      )) ? (
    <>
      <Box>
        <FieldGroupContainer
          title={t(
            'merchant-portal.notifications-settings.customer-notifications'
          )}
          description={t(
            'merchant-portal.notifications-settings.settings-for-notifications-sent-to-customers-at-this-locations'
          )}
          buttonData={{
            label: t(
              'merchant-portal.notifications-settings.edit-customer-notifications'
            ),
            color: 'secondary',
            onClick: () => setEditCustomerNotifications(true),
          }}
        >
          <Box sx={{ maxWidth: '816px' }}>
            {locationHasTransactions && (
              <HasPermission permission="v2.adminlocations.put">
                <FieldGroupContainer
                  title={t(
                    'merchant-portal.notifications-settings.transaction'
                  )}
                  isSubContainer
                >
                  <NotificationPreference
                    title={t(
                      'merchant-portal.notifications-settings.customer-receives-transaction-receipt'
                    )}
                    description={t(
                      'merchant-portal.notifications-settings.this-settings-only-impacts-newly-added-customers'
                    )}
                    emailChecked={location.contact_email_trx_receipt_default}
                  />
                </FieldGroupContainer>
              </HasPermission>
            )}

            {recurring?.id && (
              <HasPermission permission="v2.productrecurrings.put">
                <FieldGroupContainer
                  title={t('common.recurring-billing')}
                  isSubContainer
                >
                  <NotificationPreference
                    title={t(
                      'merchant-portal.notifications-settings.notification-before-recurring-billing'
                    )}
                    description={t(
                      'merchant-portal.notifications-settings.when-creating-a-recurring-billing-this-will-be-the-defaulted-method'
                    )}
                    emailChecked={Boolean(recurring?.notification_days_default)}
                  />
                  {Boolean(recurring?.notification_days_default) && (
                    <FieldGroupRow>
                      <FieldGroupViewItem
                        title={t(
                          'merchant-portal.notifications-settings.number-of-days-before-next-run-date'
                        )}
                        value={formatDaysValue(
                          recurring?.notification_days_default
                        )}
                        isFullWidth
                      />
                    </FieldGroupRow>
                  )}

                  <NotificationPreference
                    title={t(
                      'merchant-portal.notifications-settings.token-expiring-soon'
                    )}
                    description={t(
                      'merchant-portal.notifications-settings.if-selected-the-customer-will-be-notified-their-payment-method-is-set-to-expire'
                    )}
                    emailChecked={recurring?.expire_notification_email_enable}
                  />

                  <NotificationPreference
                    title={t(
                      'merchant-portal.notifications-settings.recurring-billing-transaction-declined'
                    )}
                    description={t(
                      'merchant-portal.notifications-settings.if-selected-the-customer-will-be-notified-their-transaction-was-declined'
                    )}
                    emailChecked={recurring?.send_declined_notifications}
                  />
                </FieldGroupContainer>
              </HasPermission>
            )}

            {quickInvoice?.id &&
              location.product_transactions?.some(
                (prod) => !!prod.quick_invoice_allow
              ) && (
                <HasPermission permission="v2.quickinvoicesettings.put">
                  <FieldGroupContainer
                    title={t('common.quick-invoice')}
                    description={t(
                      'merchant-portal.notifications-settings.these-settings-are-applied-by-default-when-creating-quick-invoices'
                    )}
                    isSubContainer
                  >
                    <NotificationPreference
                      title={t(
                        'merchant-portal.notifications-settings.notification-on-due-date'
                      )}
                      description={t(
                        'merchant-portal.notifications-settings.if-selected-the-customer-will-be-notified-their-quick-invoice-is-due-today'
                      )}
                      emailChecked={
                        quickInvoice?.default_notification_on_due_date
                      }
                    />

                    <NotificationPreference
                      title={t(
                        'merchant-portal.notifications-settings.notification-before-due-date'
                      )}
                      description={t(
                        'merchant-portal.notifications-settings.if-selected-the-customer-will-be-notified-their-quick-invoice-is-due-before-the-due-date'
                      )}
                      emailChecked={Boolean(
                        quickInvoice?.default_notification_days_before_due_date
                      )}
                    />

                    {Boolean(
                      quickInvoice?.default_notification_days_before_due_date
                    ) && (
                      <FieldGroupRow>
                        <FieldGroupViewItem
                          title={t(
                            'merchant-portal.notifications-settings.number-of-days-before-due-date'
                          )}
                          value={formatDaysValue(
                            quickInvoice?.default_notification_days_before_due_date
                          )}
                          isFullWidth
                        />
                      </FieldGroupRow>
                    )}

                    <NotificationPreference
                      title={t(
                        'merchant-portal.notifications-settings.notification-after-due-date'
                      )}
                      description={t(
                        'merchant-portal.notifications-settings.if-selected-the-customer-will-be-notified-their-quick-invoice-is-due-after-the-due-date'
                      )}
                      emailChecked={Boolean(
                        quickInvoice?.default_notification_days_after_due_date
                      )}
                    />

                    {Boolean(
                      quickInvoice?.default_notification_days_after_due_date
                    ) && (
                      <FieldGroupRow>
                        <FieldGroupViewItem
                          title={t(
                            'merchant-portal.notifications-settings.number-of-days-after-due-date'
                          )}
                          value={formatDaysValue(
                            quickInvoice?.default_notification_days_after_due_date
                          )}
                          isFullWidth
                        />
                      </FieldGroupRow>
                    )}
                  </FieldGroupContainer>
                </HasPermission>
              )}
          </Box>
        </FieldGroupContainer>
      </Box>

      {editCustomerNotifications && (
        <EditCustomerNotifications
          recurring={recurring}
          quickInvoice={quickInvoice}
          open={editCustomerNotifications}
          handleClose={(saved: boolean) => {
            setEditCustomerNotifications(false)
            if (saved) {
              onSettingsUpdated()
            }
          }}
          handleShowNotification={handleShowNotification}
        />
      )}

      <Notification
        label={notificationMessage || ''}
        show={showNotification}
        type={notificationType}
        onClose={() => setShowNotification(false)}
      />
    </>
  ) : (
    <></>
  )
}
