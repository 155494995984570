import { useLocation } from 'react-router-dom'

export function useQueryParams(params: any[]) {
  const { search } = useLocation()
  const query = new URLSearchParams(search)

  return params.reduce((acc, param) => {
    const value = query.get(param)
    if (value !== null) {
      acc[param] = value
    }
    return acc
  }, {})
}
