const mapBarForecastChart = (filters, data, formatX) => {
  return filters.map((filter) => {
    const currentData = data.find((d) => d.id === filter.id)

    if (!currentData) return { id: filter.id, data: [] }

    const transformedData = currentData.range.map((time, index) => {
      const date = formatX(filter.id, time * 1000)
      const [transactions, amount] = currentData.values[0].values[index]

      return {
        date,
        transactions,
        amount,
      }
    })

    return {
      id: filter.id,
      data: transformedData,
      transactions: transformedData.reduce(
        (sum, entry) => sum + entry.transactions,
        0
      ),
      amount: transformedData
        .reduce((sum, entry) => sum + entry.amount, 0)
        .toFixed(2),
    }
  })
}
export default mapBarForecastChart
