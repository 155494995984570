import { useEffect, useState, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { tss } from 'tss-react/mui'

import { Grid, SelectChangeEvent } from '@mui/material'

import { GatewayTransaction, MerchantAccount } from '@shared/api/src'
import {
  SelectComponent,
  SelectOption,
  HasPermission,
  Loading,
} from '@shared/components'
import Button from '@shared/components/buttons/Button'
import AppTheme from '@shared/design'
import {
  useEnforceLogin,
  useFtpPortalHubCommunication,
  useNotification,
} from '@shared/hooks'
import { useLocations } from '@shared/hooks/useLocations'
import { PaymentMethodType } from '@shared/types'
import { sortMerchantAccounts } from '@shared/utils'

import ACHRejectsChart from './charts/ach-rejects/ACHRejectsChart'
import ChargebacksChart from './charts/chargebacks/ChargebacksChart'
import { GatewayTransactionsChart } from './charts/gateway-transactions/GatewayTransactionsChart'
import RecurringBillingDeclinesChart from './charts/recurring-billing/recurring-billing-declines/RecurringBillingDeclinesChart'
import RecurringBillingForecastChart from './charts/recurring-billing/recurring-billing-forecast/RecurringBillingForecastChart'
import RecurringBillingHistoryChart from './charts/recurring-billing/recurring-billing-history/RecurringBillingHistoryChart'
import { SettledTransactionsChart } from './charts/settled-transactions/SettledTransactionsChart'
import { fetchGatewayData } from './charts/utils/GetGatewayTransactionsData'

const useStyles = tss.withName('Dashboard').create(({ theme }) => ({
  root: {
    paddingBottom: '50px',
  },
  filterBar: {
    display: 'flex',
    padding: '12px',
    justifyContent: 'space-between',
    alignItems: 'center',
    alignSelf: 'stretch',
    borderRadius: '6px',
    background: '#FFF',
    marginBottom: '20px',
  },
  // optionsIcon: {
  //   color: theme?.palette['primary-color-700'],
  // },
  // optionsButton: {
  //   display: 'flex',
  //   padding: '12px',
  //   justifyContent: 'center',
  //   alignItems: 'center',
  //   borderRadius: '100px',
  //   border: '1px solid var(--ag-secondary-border-color, #E5E7EB)',
  //   background: 'var(--Gray-Tones-50, #F9FAFB)',
  //   boxShadow: '0px 1px 2px 0px rgba(0, 0, 0, 0.05)',
  // },
}))

export default function Dashboard() {
  const { classes } = useStyles(AppTheme)
  const { setAppBarTitle } = useFtpPortalHubCommunication()
  const { setNotification } = useNotification()
  const { t } = useTranslation()
  const {
    allMerchantAccounts,
    selectedLocation,
    hasProductRecurringService,
    hasMerchantAccountACHActive,
    hasMerchantAccountCCActive,
  } = useLocations()
  const { user } = useEnforceLogin()
  const [merchantAccounts, setMerchantAccounts] = useState<MerchantAccount[]>(
    []
  )
  const [selectedMerchantsAccountId, setSelectedMerchantsAccountId] =
    useState('')
  const [selectedMerchantAccounts, setSelectedMerchantAccounts] =
    useState<MerchantAccount[]>()

  //Gateway States
  const [gatewayAchTransactions, setGatewayAchTransactions] = useState<
    GatewayTransaction[]
  >([])
  const [gatewayCcTransactions, setGatewayCcTransactions] = useState<
    GatewayTransaction[]
  >([])
  const [isGatewayLoading, setGatewayIsLoading] = useState(true)
  const [isGatewayError, setGatewayIsError] = useState(true)

  useEffect(() => {
    setAppBarTitle(t('common.dashboard'))
  }, [])

  const getMerchantAccountsSelectOptions = (
    merchantAccounts: MerchantAccount[]
  ): SelectOption<MerchantAccount>[] =>
    sortMerchantAccounts(merchantAccounts, true, true).map<
      SelectOption<MerchantAccount>
    >((merchantAccount) => ({
      label: merchantAccount.title,
      value: merchantAccount.id,
      payment_method: merchantAccount.payment_method,
    }))

  const handleChange = (event: SelectChangeEvent) => {
    setSelectedMerchantsAccountId(event.target.value)
  }

  useEffect(() => {
    const selectedMerchantAccounts_ = allMerchantAccounts?.filter((account) =>
      selectedMerchantsAccountId.includes(account.id)
    )

    return setSelectedMerchantAccounts(selectedMerchantAccounts_)
  }, [selectedMerchantsAccountId, allMerchantAccounts])

  useEffect(() => {
    if (!allMerchantAccounts) return

    const merchantAccounts = allMerchantAccounts.filter(
      ({ payment_method }) => payment_method !== PaymentMethodType.CASH
    )

    setMerchantAccounts(merchantAccounts)
  }, [allMerchantAccounts])

  const merchantAccountsOptions =
    getMerchantAccountsSelectOptions(merchantAccounts)

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {}

  useEffect(() => {
    const fetchData = async () => {
      try {
        setGatewayIsLoading(true)
        const { achData, ccData } = await fetchGatewayData(
          selectedLocation.id,
          selectedMerchantsAccountId,
          hasMerchantAccountACHActive,
          hasMerchantAccountCCActive
        )
        setGatewayAchTransactions(achData)
        setGatewayCcTransactions(ccData)
      } catch (error) {
        setGatewayIsError(true)
        console.error('Error', error)
      } finally {
        setGatewayIsLoading(false)
        setGatewayIsError(false)
      }
    }
    selectedLocation ? fetchData() : null
  }, [selectedMerchantsAccountId, selectedLocation])

  const includesAccountTypes = useCallback(
    (t: PaymentMethodType): boolean => {
      const found =
        selectedMerchantAccounts?.length > 0
          ? selectedMerchantAccounts.some((a) => a.payment_method === t)
          : allMerchantAccounts.some((a) => a.payment_method === t)
      return found
    },
    [allMerchantAccounts, selectedMerchantAccounts]
  )

  if (hasProductRecurringService === undefined) {
    return <Loading />
  }

  return (
    // TODO: remove Box and its contents and replace with real content
    <section className={classes.root}>
      {merchantAccountsOptions.length > 1 && (
        <div className={classes.filterBar}>
          <SelectComponent
            guidingId="mp-dashboard-merchantaccount-filter"
            placeholder={t('common.merchant-account-filter')}
            options={merchantAccountsOptions}
            value={selectedMerchantsAccountId}
            onChange={handleChange}
            multiple
            sort={false}
            style={{
              width: '300px',
            }}
          />
          {/* <IconButton onClick={handleClick} className={classes.optionsButton}>
          <MoreHorizIcon
            data-testid="options-icon"
            fontSize="inherit"
            className={classes.optionsIcon}
          />
        </IconButton> */}
        </div>
      )}
      <Grid container spacing={3}>
        <HasPermission permission="v2.transactions.get">
          <Grid item xs={12} md={12} lg={6}>
            <GatewayTransactionsChart
              merchantAccounts={merchantAccounts}
              selectedMerchantAccounts={selectedMerchantAccounts}
              tz={user?.tz}
              ccTransactions={gatewayCcTransactions}
              achTransactions={gatewayAchTransactions}
              isLoading={isGatewayLoading}
              isError={isGatewayError}
            />
          </Grid>
        </HasPermission>
        <Grid item xs={12} md={12} lg={6}>
          <SettledTransactionsChart
            locationId={selectedLocation.id}
            merchantAccountId={selectedMerchantsAccountId}
            merchantAccounts={merchantAccounts}
            selectedMerchantAccounts={selectedMerchantAccounts}
          />
        </Grid>
        {hasProductRecurringService && (
          <Grid item xs={12} md={12} lg={6}>
            <RecurringBillingHistoryChart />
          </Grid>
        )}
        {hasProductRecurringService && (
          <Grid item xs={12} md={12} lg={6}>
            <RecurringBillingForecastChart
              merchantAccountId={selectedMerchantsAccountId}
            />
          </Grid>
        )}
        {hasProductRecurringService && (
          <Grid item xs={12} md={6}>
            <RecurringBillingDeclinesChart tz={user?.tz} />
          </Grid>
        )}

        {/* 
        TODO: Re-enable when chargebacks are available
        <Grid item xs={12} md={6}>
          <ChargebacksChart
            locationId={selectedLocation.id}
            merchantAccountId={selectedMerchantsAccountId}
            tz={user?.tz}
          />
        </Grid> */}

        {includesAccountTypes(PaymentMethodType.ACH) && (
          <Grid item xs={12} md={6}>
            <ACHRejectsChart merchantAccountId={selectedMerchantsAccountId} />
          </Grid>
        )}
      </Grid>
    </section>
  )
}
