import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Typography } from '@mui/material'

import { api } from '@shared/api'
import { Tags } from '@shared/api/src'
import {
  ActionModal,
  FieldGroupContainer,
  HasPermission,
  ListTypeahead,
} from '@shared/components'
import { useLocations, useAuthorization, useNotification } from '@shared/hooks'
import { toArrayFieldErrors, checkPermission } from '@shared/utils'

interface TagsViewProps {
  tags: Tags[]
}

export const TagsView: React.FC<TagsViewProps> = ({ tags }) => {
  const { t } = useTranslation()
  const { selectedLocation, locationTags, setLocationTags } = useLocations()
  const { userPermissionSet } = useAuthorization()
  const { setNotification } = useNotification()

  const [inputValue, setInputValue] = useState<string>('')
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false)
  const [tagToDelete, setTagToDelete] = useState<Tags | null>(null)

  const canDeleteTag = checkPermission(userPermissionSet, 'v2.tags.delete')

  const onDeleteTag = (tag: Tags) => {
    setTagToDelete(tag)
    setDeleteModalOpen(true)
  }

  const handleDeleteConfirm = async () => {
    if (tagToDelete) {
      try {
        setIsLoading(true)
        await api.service('tags').remove(tagToDelete.id)
        setLocationTags((prevTags) =>
          prevTags.filter((tag) => tag.id !== tagToDelete.id)
        )
        setNotification({
          label: t('merchant-portal.location.tag-deleted-successfully'),
          type: 'success',
        })
      } catch (error) {
        const errorsFormatted = toArrayFieldErrors(error)
        const errorMessage = errorsFormatted
          .map((err) => err.message)
          .join(', ')

        setNotification({
          label: errorMessage,
          type: 'error',
        })
      } finally {
        setIsLoading(false)
        setDeleteModalOpen(false)
        setTagToDelete(null)
      }
    }
  }

  const onButtonClick = async () => {
    setIsSubmitting(true)
    try {
      const newTag = await api.service('tags').create({
        title: inputValue.trim(),
        location_id: selectedLocation.id,
      })

      setLocationTags((prevTags) => [...prevTags, newTag])
      setNotification({
        label: t('merchant-portal.location.tag-created-successfully'),
        type: 'success',
      })
    } catch (error) {
      const errorsFormatted = toArrayFieldErrors(error)
      const errorMessage = errorsFormatted.map((err) => err.message).join(', ')

      setNotification({
        label: errorMessage,
        type: 'error',
      })
    } finally {
      setIsSubmitting(false)
      setInputValue('')
    }
  }

  return (
    <HasPermission permission="v2.tags.post">
      <>
        <FieldGroupContainer title={t('common.tags')}>
          <ListTypeahead<Tags>
            buttonLabel={t('merchant-portal.location.add-tag')}
            onButtonClick={onButtonClick}
            isButtonLoading={isSubmitting}
            isButtonEnabled={!!inputValue.trim()}
            inputProps={{
              value: inputValue,
              label: t('common.description'),
              onChange: (e: React.ChangeEvent<HTMLInputElement>) =>
                setInputValue(e.target.value),
              placeholder: t('merchant-portal.location.enter-tag'),
            }}
            tagsItems={locationTags}
            tagsNameSelector={(tag) => tag.title}
            onDeleteTag={canDeleteTag ? onDeleteTag : undefined}
            tagBackgroundColor="E5E7EB"
            tagTextColor="1F2937"
          />
        </FieldGroupContainer>

        <ActionModal
          open={isDeleteModalOpen}
          onClose={() => setDeleteModalOpen(false)}
          title={t('merchant-portal.location.delete-tag')}
          buttons={[
            {
              label: t('common.cancel'),
              color: 'secondary',
              onClick: () => setDeleteModalOpen(false),
            },
            {
              label: t('common.yes-delete'),
              color: 'primary',
              isLoading: isLoading,
              onClick: handleDeleteConfirm,
            },
          ]}
        >
          <Typography variant="body2">
            {t('common.modal.are-you-sure-like-to-delete')}{' '}
            <Typography component="span" fontWeight="bold">
              {tagToDelete?.title}
            </Typography>
            ? {t('common.modal.this-cannot-be-undone')}
          </Typography>
        </ActionModal>
      </>
    </HasPermission>
  )
}

export default TagsView
