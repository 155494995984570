import { api, UserPrefSubscribe } from '@shared/api/src'
// import { CustomerNotificationPrefs } from '../EditCustomerNotifications/CustomerNotificationsEditor'

export const updateMyNotificationSubscriptions = async (
  locationId: string,
  userId: string,
  originalSubs: UserPrefSubscribe[],
  updatedSubIds: string[]
) => {
  const updatedSubIdSet = new Set(updatedSubIds)

  const deletedSubs = originalSubs.filter(
    (sub) => !updatedSubIdSet.has(sub.user_pref_id as string)
  )

  const oldSubIdSet = new Set(originalSubs.map((sub) => sub.user_pref_id))

  const createdSubs = updatedSubIds
    .filter((id) => !oldSubIdSet.has(id))
    .map((id) => ({
      location_id: locationId,
      user_id: userId,
      user_pref_id: id,
    }))

  //There's no bulk update path for notification subscriptions, so they need to be updated individually
  const createdSubPromises = createdSubs.map(
    async (sub) => await api.service('user-pref-subscribes').create(sub)
  )

  const deletedSubPromises = deletedSubs.map(
    async (sub) => await api.service('user-pref-subscribes').remove(sub.id)
  )

  await Promise.all([...createdSubPromises, ...deletedSubPromises])
}
