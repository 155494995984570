import React from 'react'

export const ChargebacksIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="33"
    viewBox="0 0 32 33"
    fill="none"
  >
    <path
      d="M16 12.5804V11.7307"
      stroke="#D64123"
      stroke-width="1.7"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M16 20.2195V21.0679"
      stroke="#D64123"
      stroke-width="1.7"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M13.7896 19.3085C14.1191 19.8487 14.682 20.2209 15.3636 20.2209H15.9999H16.7589C17.7473 20.2209 18.5463 19.4205 18.5463 18.4334C18.5463 17.6131 17.9874 16.8995 17.1924 16.6981L14.8047 16.0978C14.011 15.8991 13.4521 15.1854 13.4521 14.3651C13.4521 13.3767 14.2525 12.5777 15.2396 12.5777H15.9999H16.6361C17.3151 12.5777 17.878 12.9498 18.2061 13.4887"
      stroke="#D64123"
      stroke-width="1.7"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M7.99654 11.3971H3.99487V7.39547"
      stroke="#D64123"
      stroke-width="1.7"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M9.95337 26.969C11.1539 27.6639 12.4838 28.1561 13.9003 28.4042"
      stroke="#D64123"
      stroke-width="1.7"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M4.63794 20.6317C5.12614 21.9709 5.8331 23.2021 6.73215 24.2732"
      stroke="#D64123"
      stroke-width="1.7"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M18.0994 28.4042C19.516 28.1561 20.8445 27.6639 22.0463 26.969"
      stroke="#D64123"
      stroke-width="1.7"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M25.2678 24.2732C26.1669 23.2021 26.8738 21.9709 27.362 20.6317"
      stroke="#D64123"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M28.0049 16.3992C28.0049 9.76849 22.6306 4.39425 15.9999 4.39425C11.1579 4.39425 6.9948 7.26745 5.09668 11.3972"
      stroke="#D64123"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
)
