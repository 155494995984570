import { api } from '@shared/api'
import { checkPermission, UserPermissionSet } from '@shared/utils'

interface QuickInvoiceSettings {
  default_notification_on_due_date: boolean
  default_notification_days_before_due_date: number
  default_notification_days_after_due_date: number
}

interface RecurringSettings {
  notification_days_default: number
  expire_notification_email_enable: boolean
  send_declined_notifications: boolean
}

interface LocationSettings {
  contact_email_trx_receipt_default?: boolean
}

export interface CustomerNotificationSettings {
  quickInvoice: QuickInvoiceSettings
  recurring: RecurringSettings
  location: LocationSettings
}

export async function updateCustomerNotificationSettings(
  locationID: string,
  applyToAllCustomers: boolean,
  recurringID: string,
  quickInvoiceID: string,
  settings: CustomerNotificationSettings,
  userPermissionSet: UserPermissionSet
) {
  const promises = []

  if (checkPermission(userPermissionSet, 'v2.adminlocations.put')) {
    promises.push(
      api.service('admin-location').patch(locationID, {
        contact_email_trx_receipt_default:
          settings.location.contact_email_trx_receipt_default,
      })
    )
  }

  promises.push(
    api
      .service('locations')
      .updateAllContactEmailReceipts(locationID, applyToAllCustomers)
  )

  if (quickInvoiceID) {
    promises.push(
      api.service('quick-invoice-settings').patch(quickInvoiceID, {
        default_notification_on_due_date:
          settings.quickInvoice.default_notification_on_due_date,
        default_notification_days_before_due_date:
          settings.quickInvoice.default_notification_days_before_due_date,
        default_notification_days_after_due_date:
          settings.quickInvoice.default_notification_days_after_due_date,
      })
    )
  }

  if (recurringID) {
    promises.push(
      api.service('product-recurrings').patch(recurringID, {
        notification_days_default: settings.recurring.notification_days_default,
        expire_notification_email_enable:
          settings.recurring.expire_notification_email_enable,
        send_declined_notifications:
          settings.recurring.send_declined_notifications,
      })
    )
  }

  await Promise.all(promises)
}
