import React from 'react'
export const GatewayIcon = () => (
  <svg
    width="32"
    height="33"
    viewBox="0 0 32 33"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="money-icon">
      <path
        id="Path"
        d="M6.42139 23.5833C8.61605 26.498 12.0921 28.3993 16.0001 28.3993C22.6001 28.3993 28.0001 22.9993 28.0001 16.3993C28.0001 9.79929 22.6001 4.39929 16.0001 4.39929"
        stroke="#D64123"
        stroke-width="1.7"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        id="Path_2"
        d="M6.42133 23.5833C4.90933 21.5753 4 19.0913 4 16.3993C4 9.79929 9.4 4.39929 16 4.39929"
        stroke="#D64123"
        stroke-width="1.7"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        id="Path_3"
        d="M15.6667 9.7326V11.3993"
        stroke="#D64123"
        stroke-width="1.7"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        id="Path_4"
        d="M15.6667 23.066V21.3993"
        stroke="#D64123"
        stroke-width="1.7"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        id="Path_5"
        d="M18.9828 13.7326C18.8962 12.4313 17.8228 11.3993 16.5002 11.3993H14.6735C13.3802 11.3993 12.3335 12.4473 12.3335 13.7393C12.3335 14.8126 13.0642 15.7486 14.1042 16.01L17.2282 16.794C18.2695 17.0553 18.9988 17.9913 18.9988 19.0646C18.9988 20.358 17.9508 21.4046 16.6588 21.4046H14.8322C13.5068 21.4046 12.4335 20.37 12.3482 19.066"
        stroke="#D64123"
        stroke-width="1.7"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
  </svg>
)
