import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { tss } from 'tss-react/mui'

import { Grid, Typography } from '@mui/material'

import { MerchantAccount } from '@shared/api'
import { EnumeratedStatus, ActiveStatus, ContextMenu } from '@shared/components'
import appTheme from '@shared/design'
import { useAuthorization } from '@shared/hooks'
import { checkPermission } from '@shared/utils'

const useStyles = tss.withName('MerchantAccountCard').create(({ theme }) => ({
  container: {
    rowGap: '1rem',
    paddingBottom: '24px',
  },
  subContainer: {
    rowGap: '0.75rem',
    columnGap: '2.5rem',
  },
  title: {
    fontSize: '1rem',
    fontWeight: 500,
    lineHeight: '40px',
    color: theme.palette['neutral-700'],
    marginRight: '1rem',
  },
  textValues: {
    color: theme.palette['neutral-900'],
    fontSize: '0.875rem',
    fontWeight: 500,
  },
  contextMenuWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
    paddingRight: '16px',
  },
}))

interface MerchantAccountCardProps {
  merchantAccount: MerchantAccount
  openDeactivateAccount: (merchantAccount: MerchantAccount) => void
}

const MerchantAccountCard = ({
  merchantAccount,
  openDeactivateAccount,
}: MerchantAccountCardProps) => {
  const { t } = useTranslation()
  const { classes } = useStyles(appTheme)
  const navigate = useNavigate()
  const { userPermissionSet } = useAuthorization()

  const [enabledFeatures, setEnabledFeatures] = useState<string[]>([])
  const [generalSettings, setGeneralSettings] = useState<string[]>([])
  const [showFields, setShowFields] = useState<string[]>([])

  useEffect(() => {
    if (!merchantAccount) return

    const processFields = (props, accountData) => {
      return Object.keys(props)
        .filter((key) => accountData[key])
        .map((key) => {
          let displayValue = props[key]
          if (key.startsWith('vt_require')) {
            key = key.replace('_require', '')
            displayValue += ` (${t('common.required')})`
          }
          return displayValue
        })
    }

    const featureProps = {
      quick_invoice_allow: t('common.quick-invoice'),
      surcharge_id: t('merchant-portal.merchant-account-settings.surcharge'),
      vt_enable_sales_tax: t('common.tax'),
      vt_enable_tip: t('common.tip'),
    }

    const generalProps = {
      vt_enable: t(
        'merchant-portal.merchant-account-settings.virtual-terminal-enabled'
      ),
      debit_allow_void: t(
        'merchant-portal.merchant-account-settings.allowed-void-on-debit'
      ),
      display_avs: t(
        'merchant-portal.merchant-account-settings.display-avs-on-receipt'
      ),
    }

    const showProps = {
      vt_billing_phone: t('common.phone-billing'),
      vt_zip: t(
        'merchant-portal.merchant-account-settings.billing-postal-code'
      ),
      vt_street: t('common.street-billing'),
      vt_clerk_number: t('common.clerk-number'),
      vt_show_currency: t('merchant-portal.merchant-account-settings.currency'),
      vt_show_custom_fields: t(
        'merchant-portal.merchant-account-settings.custom-fields'
      ),
      vt_show_company_name: t(
        'merchant-portal.merchant-account-settings.customer-company-name'
      ),
      vt_cvv: t('merchant-portal.merchant-account-settings.cvv'),
      vt_order_num: t('common.order-number'),
      vt_require_zip: t(
        'merchant-portal.merchant-account-settings.billing-postal-code'
      ),
      vt_require_street: t('common.street-billing'),
    }

    setEnabledFeatures(processFields(featureProps, merchantAccount))
    setGeneralSettings(processFields(generalProps, merchantAccount))
    setShowFields(processFields(showProps, merchantAccount))
  }, [merchantAccount])

  const getTransactionType = (type) => {
    const types = {
      sale: t('merchant-portal.merchant-account-settings.sale'),
      debit: t('common.debit'),
      authonly: t('common.auth-only'),
    }

    return types[type]
  }

  const handleNavigateMerchantAccountView = () => {
    navigate(
      `/merchant/settings/merchant-accounts/${merchantAccount.id}/view`,
      {
        state: {
          backUrl: `/merchant/settings/merchant-accounts`,
        },
      }
    )
  }

  const handleNavigateMerchantAccountEdit = () => {
    navigate(
      `/merchant/settings/merchant-accounts/${merchantAccount.id}/edit`,
      {
        state: {
          backUrl: `/merchant/settings/merchant-accounts`,
        },
      }
    )
  }

  const options = [
    {
      label: t('common.view'),
      action: handleNavigateMerchantAccountView,
    },
    {
      label: t('common.edit'),
      action: handleNavigateMerchantAccountEdit,
      isVisible:
        merchantAccount.active &&
        checkPermission(userPermissionSet, 'v2.producttransactions.put'),
    },
    {
      label: t('common.deactivate'),
      action: () => openDeactivateAccount(merchantAccount),
      isVisible:
        merchantAccount.active &&
        checkPermission(userPermissionSet, 'v2.producttransactions.delete'),
    },
    {
      label: t('common.reactivate'),
      action: () => openDeactivateAccount(merchantAccount),
      isVisible:
        !merchantAccount.active &&
        checkPermission(
          userPermissionSet,
          'v2.producttransactions.post.activate'
        ),
    },
  ]

  const merchantAccountTitle = merchantAccount?.title
  const merchantAccountID = merchantAccount?.id
  const merchantAccountIndustryType = merchantAccount?.industry_type
  const merchantAccountDefaultTransactionType = getTransactionType(
    merchantAccount.default_transaction_type
  )

  return (
    <Grid container className={classes.container}>
      <Grid
        container
        item
        justifyContent="space-between"
        style={{
          height: '48px',
        }}
      >
        <Grid
          item
          xs={8}
          style={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Typography className={classes.title}>
            {merchantAccountTitle}
          </Typography>
          <ActiveStatus active={merchantAccount.active} />
        </Grid>

        <Grid item xs={4} className={classes.contextMenuWrapper}>
          <ContextMenu options={options} iconVariant="vertical" />
        </Grid>
      </Grid>

      <Grid container item className={classes.subContainer}>
        <Grid item xs={12}>
          <Typography fontWeight={600} fontSize="0.875rem">
            {t('common.details')}
          </Typography>
        </Grid>

        <Grid item xs={3}>
          <Grid container>
            <Grid item xs={12}>
              <Typography variant="caption" fontSize="0.875rem">
                {t(
                  'merchant-portal.merchant-account-settings.enabled-features'
                )}
              </Typography>
            </Grid>

            <Grid item xs={12}>
              {enabledFeatures.length
                ? enabledFeatures.map((feature, index) => (
                    <EnumeratedStatus key={index} value={feature} />
                  ))
                : '-'}
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={3}>
          <div>
            <Typography variant="caption" fontSize="0.875rem">
              ID
            </Typography>
            <Typography className={classes.textValues}>
              {merchantAccountID}
            </Typography>
          </div>
        </Grid>

        <Grid item xs={3}>
          <div>
            <Typography variant="caption" fontSize="0.875rem">
              {t('merchant-portal.merchant-account-settings.industry-type')}
            </Typography>
            <Typography className={classes.textValues}>
              {merchantAccountIndustryType}
            </Typography>
          </div>
        </Grid>
      </Grid>

      <Grid container item className={classes.subContainer}>
        <Grid item xs={12}>
          <Typography fontWeight={600} fontSize="0.875rem">
            {t('common.virtual-terminal')}
          </Typography>
        </Grid>

        <Grid item xs={3}>
          <Grid container>
            <Grid item xs={12}>
              <Typography variant="caption" fontSize="0.875rem">
                {t('common.general-settings')}
              </Typography>
            </Grid>

            <Grid item xs={12}>
              {generalSettings.length
                ? generalSettings.map((feature, index) => (
                    <EnumeratedStatus key={index} value={feature} />
                  ))
                : '-'}
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={3}>
          <Grid container>
            <Grid item xs={12}>
              <Typography variant="caption" fontSize="0.875rem">
                {t(
                  'merchant-portal.merchant-account-settings.default-transaction-type'
                )}
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Typography className={classes.textValues}>
                {merchantAccountDefaultTransactionType}
              </Typography>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={3}>
          <Grid container>
            <Grid item xs={12}>
              <Typography variant="caption" fontSize="0.875rem">
                {t('merchant-portal.merchant-account-settings.show-fields')}
              </Typography>
            </Grid>

            <Grid item xs={12}>
              {showFields.length
                ? showFields.map((feature, index) => (
                    <EnumeratedStatus key={index} value={feature} />
                  ))
                : '-'}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default MerchantAccountCard
