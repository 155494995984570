import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { tss } from 'tss-react/mui'

import {
  AlertColor,
  Box,
  CircularProgress,
  Grid,
  ToggleButton,
  ToggleButtonGroup,
} from '@mui/material'

import { MerchantAccount, api } from '@shared/api'
import { FieldGroupContainer, FieldGroupRow, NoData } from '@shared/components'
import {
  NotificationData,
  useFtpPortalHubCommunication,
  useLocations,
  useNotification,
} from '@shared/hooks'
import { PaymentMethodType } from '@shared/types'
import { sortMerchantAccounts } from '@shared/utils'

import MerchantAccountSection from './components/merchant-account-section/MerchantAccountSection'
import ToggleMerchantDeactivateModal from './components/merchant-deactivate-modal/ToggleMerchantDeactivateModal'

const useStyles = tss.withName('MerchantAccounts').create(({ theme }) => ({
  buttonWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    height: '44px',
    marginBottom: '16px',
    marginRight: '24px',
  },
  container: {
    padding: '16px',
    backgroundColor: theme?.palette.background.paper,
    borderRadius: '8px',
    paddingLeft: '0px',
    paddingRight: '0px',
    position: 'relative',
    marginBottom: '24px',
    height: '80px',
  },
  noDataContainer: {
    padding: '32px',
    backgroundColor: theme?.palette.background.paper,
    borderRadius: '8px',
    textAlign: 'center',
  },
  loadingWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
  },
}))

export default function MerchantAccounts() {
  const { classes } = useStyles()
  const { setAppBarTitle } = useFtpPortalHubCommunication()
  const { setNotification } = useNotification()
  const { selectedLocation } = useLocations()
  const { t } = useTranslation()
  const [merchantAccounts, setMerchantAccounts] = useState<MerchantAccount[]>(
    []
  )
  const [selectedMerchantAccount, setSelectedMerchantAccount] =
    useState<MerchantAccount>()
  const [active, setActive] = useState(true)
  const [isLoading, setIsLoading] = useState(true)
  const [openDeactivateModal, setOpenDeactivateModal] = useState(false)

  useEffect(() => {
    setAppBarTitle(
      t('merchant-portal.merchant-account-settings.title'),
      null,
      t('common.settings')
    )
  }, [])

  const getMerchantAccounts = useCallback(
    async (isActive: boolean) => {
      try {
        setIsLoading(true)
        const merchantAccounts = await api.service('merchant-accounts').find({
          query: {
            filter: {
              location_id: selectedLocation?.id,
              active: isActive,
            },
          },
        })
        setMerchantAccounts(
          merchantAccounts.filter(
            (merchantAccount) => merchantAccount.processor !== 'zach'
          )
        )
      } catch (error) {
        console.error(error)
      } finally {
        setIsLoading(false)
      }
    },
    [selectedLocation?.id]
  )

  useEffect(() => {
    getMerchantAccounts(active)
  }, [getMerchantAccounts, active])

  const handleToggleChange = (_, newActive) => {
    if (newActive !== null) {
      setActive(newActive === 'active')
    }
  }

  const openDeactivateAccount = (merchantAccount: MerchantAccount) => {
    setOpenDeactivateModal(true)
    setSelectedMerchantAccount(merchantAccount)
  }

  const onMerchantAccountUpdated = () => {
    setOpenDeactivateModal(false)
    getMerchantAccounts(active)
  }

  return (
    <>
      {selectedMerchantAccount && (
        <ToggleMerchantDeactivateModal
          open={openDeactivateModal}
          handleClose={() => {
            setOpenDeactivateModal(false)
            setSelectedMerchantAccount(undefined)
          }}
          merchantAccount={selectedMerchantAccount}
          onFinish={onMerchantAccountUpdated}
        />
      )}
      <FieldGroupContainer>
        <Box className={classes.container}>
          <FieldGroupRow justifyContent="flex-end">
            <Box className={classes.buttonWrapper}>
              <ToggleButtonGroup
                value={active ? 'active' : 'inactive'}
                exclusive
                onChange={handleToggleChange}
                disabled={isLoading}
                aria-label="merchant account status"
              >
                <ToggleButton
                  value="active"
                  aria-label="show active"
                  sx={{ textTransform: 'none' }}
                >
                  {t('common.active')}
                </ToggleButton>
                <ToggleButton
                  value="inactive"
                  aria-label="show inactive"
                  sx={{ textTransform: 'none' }}
                >
                  {t('common.inactive')}
                </ToggleButton>
              </ToggleButtonGroup>
            </Box>
          </FieldGroupRow>
        </Box>

        {isLoading ? (
          <Box className={classes.loadingWrapper}>
            <CircularProgress data-testid="loading-icon" />
          </Box>
        ) : merchantAccounts.length === 0 ? (
          <Box className={classes.noDataContainer}>
            <NoData
              title={t(
                'merchant-portal.merchant-account-settings.no-merchant-accounts-available'
              )}
              label={t('common.validations.please-contact-support')}
            />
          </Box>
        ) : (
          <Grid
            container
            style={{
              height: '100%',
            }}
          >
            {Object.values(PaymentMethodType).map((paymentMethod, index) => {
              const filteredAndSortedAccounts = sortMerchantAccounts(
                merchantAccounts.filter(
                  (merchantAccount) =>
                    merchantAccount.payment_method === paymentMethod
                ),
                false,
                false
              )

              return (
                <Grid key={index} item xs={12}>
                  <MerchantAccountSection
                    merchantAccounts={filteredAndSortedAccounts}
                    paymentMethod={paymentMethod}
                    openDeactivateAccount={openDeactivateAccount}
                  />
                </Grid>
              )
            })}
          </Grid>
        )}
      </FieldGroupContainer>
    </>
  )
}
