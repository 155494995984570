import React from 'react'

export const DepositsIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="33"
    viewBox="0 0 32 33"
    fill="none"
  >
    <path
      d="M10.6667 24.3993C6.98533 24.3993 4 23.206 4 21.7326"
      stroke="#D64123"
      stroke-width="1.7"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M3.99992 11.066V21.7326"
      stroke="#D64123"
      stroke-width="1.7"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M14.6667 20.3993C14.6667 21.8726 17.6507 23.066 21.3334 23.066C25.0161 23.066 28.0001 21.8726 28.0001 20.3993"
      stroke="#D64123"
      stroke-width="1.7"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M17.3334 11.066V14.3993"
      stroke="#D64123"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M22.6667 7.06596H28.0001"
      stroke="#D64123"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M25.3334 9.73263V4.39929"
      stroke="#D64123"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M27.9999 20.3993V25.7326"
      stroke="#D64123"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M14.6667 20.3993V25.7326"
      stroke="#D64123"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M28.0001 20.3993C28.0001 18.926 25.0161 17.7326 21.3334 17.7326C17.6507 17.7326 14.6667 18.926 14.6667 20.3993"
      stroke="#D64123"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M14.6667 25.7326C14.6667 27.206 17.6507 28.3993 21.3334 28.3993C25.0161 28.3993 28.0001 27.206 28.0001 25.7326"
      stroke="#D64123"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M10.6667 19.066C6.98533 19.066 4 17.8726 4 16.3993"
      stroke="#D64123"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <ellipse
      cx="10.6667"
      cy="11.066"
      rx="6.66667"
      ry="2.66667"
      stroke="#D64123"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
)
