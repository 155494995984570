import { useEffect, useState } from 'react'
import {
  useForm,
  useFormContext,
  FormProvider,
  Controller,
} from 'react-hook-form'
import { tss } from 'tss-react/mui'

import { Box, Typography } from '@mui/material'

import { SelectComponent as Select, Input } from '@shared/components'
import {
  useFtpPortalHubCommunication,
  useSub,
  usePub,
  PubSubEvent,
} from '@shared/hooks'

const FIRST_NAME_CHANGE_EVENT = new Event(
  'FIRST_NAME_CHANGE'
) as PubSubEvent<string>

const LAST_NAME_CHANGE_EVENT = new Event(
  'LAST_NAME_CHANGE'
) as PubSubEvent<string>

const FULL_NAME_INITIALS_CHANGE_EVENT = new Event(
  'FULL_NAME_INITIALS_CHANGE'
) as PubSubEvent<string>

const TRANSACTION_TYPE_CHANGE_EVENT = new Event(
  'TRANSACTION_TYPE_CHANGE'
) as PubSubEvent<TransactionType>

const TRANSACTION_TYPE_KEY_CHANGE_EVENT = new Event(
  'TRANSACTION_TYPE_KEY_CHANGE'
) as PubSubEvent<TransactionTypeKey>

const DEFAULT_TRANSACTION_TYPE_KEY_EVENT = new Event(
  'DEFAULT_TRANSACTION_TYPE_KEY'
) as PubSubEvent<TransactionTypeKey>

type TransactionType = 'S' | 'AO' | 'AVSO' | 'R'
type TransactionTypeKey = `#${TransactionType}`

interface FormData {
  first_name: string
  last_name: string
  full_name_initials: string
  transaction_type: TransactionType
  transaction_type_key: TransactionTypeKey
}

const useStyles = tss.withName('SamplePubSubForm').create(() => ({
  root: { display: 'grid', gridTemplateColumns: '1fr 1fr', gap: '1em' },
}))

export const SamplePubSubForm = () => {
  const { classes } = useStyles()
  const { setAppBarTitle } = useFtpPortalHubCommunication()
  const publish = usePub()

  const defaultValues: FormData = {
    first_name: null,
    last_name: null,
    full_name_initials: null,
    transaction_type: 'S',
    transaction_type_key: '#S',
  }

  const methods = useForm<FormData>({
    defaultValues,
  })
  const onSubmit = (data: FormData) => {
    // console.log({ data }) // temporary comment out to pass pnpm lint
  }

  // In this example some fields are out of the form context,
  // we'll communicate with them and update the form context
  // through the pub/sub mechanism.

  // Subscribe for full_name_initials changes and update the context accordingly.
  useSub(
    FULL_NAME_INITIALS_CHANGE_EVENT.type,
    (event: typeof FULL_NAME_INITIALS_CHANGE_EVENT) => {
      methods.setValue('full_name_initials', event.data)
    }
  )

  // Subscribe for transaction_type_key changes and update the context accordingly.
  useSub(
    TRANSACTION_TYPE_KEY_CHANGE_EVENT.type,
    (event: typeof TRANSACTION_TYPE_KEY_CHANGE_EVENT) => {
      methods.setValue('transaction_type_key', event.data)
    }
  )

  useEffect(() => {
    setAppBarTitle('Sample Pub/Sub Form')

    // Publish the default value to the fields that need it
    // and are out of the form context
    publish(
      DEFAULT_TRANSACTION_TYPE_KEY_EVENT,
      defaultValues.transaction_type_key
    )
  }, [])

  return (
    <Box className={classes.root}>
      <Box>
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <FirstNameInput />
            <LastNameInput />
            <TransactionTypeInput />
            <input type="submit" />
          </form>
        </FormProvider>
      </Box>

      {/* These are the Fields that are out of the Form Context */}
      <Box>
        <FullNameInitialsInput />
        <TransactionTypeKeyInput />
      </Box>
    </Box>
  )
}

const useInputStyles = tss.withName('EditUserProfile').create(({ theme }) => ({
  label: {
    color: `${theme.palette['neutral-700']} !important`,
    fontFamily: 'Inter !important',
    fontSize: '14px !important',
    fontWeight: '500 !important',
    lineHeight: '20px !important',
    paddingBottom: '.3em',
  },
  inputContainer: {
    paddingBottom: '1.5em',
  },
}))

const FirstNameInput = () => {
  const { classes } = useInputStyles()
  const { control } = useFormContext()
  const publish = usePub()

  // The full_name_initials input needs to know about the updated first_name,
  // so we publish it.
  const onChange = (firstName: string) => {
    publish(FIRST_NAME_CHANGE_EVENT, firstName)
  }

  return (
    <Box className={classes.inputContainer}>
      <Controller
        name="first_name"
        control={control}
        render={({ field }) => (
          <Input
            {...field}
            onChange={(event) => {
              field.onChange(event)
              onChange(event.target.value)
            }}
            label="First Name"
            placeholder="Enter first name"
            required
          />
        )}
      />
    </Box>
  )
}

const LastNameInput = () => {
  const { classes } = useInputStyles()
  const { control } = useFormContext()
  const publish = usePub()

  // The full_name_initials input needs to know about the updated last_name,
  // so we publish it.
  const onChange = (lastName: string) => {
    publish(LAST_NAME_CHANGE_EVENT, lastName)
  }

  return (
    <Box className={classes.inputContainer}>
      <Controller
        name="last_name"
        control={control}
        render={({ field }) => (
          <Input
            {...field}
            onChange={(event) => {
              field.onChange(event)
              onChange(event.target.value)
            }}
            label="Last Name"
            placeholder="Enter last name"
            required
          />
        )}
      />
    </Box>
  )
}

const FullNameInitialsInput = () => {
  const { classes } = useInputStyles()
  const publish = usePub()

  const [firstName, setFirstName] = useState<string | null>(null)
  const [lastName, setLastName] = useState<string | null>(null)

  const getFullNameInitials = (firstName?: string, lastName?: string) => {
    if (firstName || lastName) {
      return `${firstName ? firstName[0].toUpperCase() : ''}.${
        lastName ? lastName[0].toUpperCase() : ''
      }`
    }

    return ''
  }

  // Subscribe for first_name changes.
  useSub(
    FIRST_NAME_CHANGE_EVENT.type,
    (event: typeof FIRST_NAME_CHANGE_EVENT) => {
      setFirstName(event.data)

      // Now the form needs to know about the updated full_name_initials
      publish(
        FULL_NAME_INITIALS_CHANGE_EVENT,
        getFullNameInitials(event.data, lastName)
      )
    }
  )

  // Subscribe for last_name changes.
  useSub(
    LAST_NAME_CHANGE_EVENT.type,
    (event: typeof LAST_NAME_CHANGE_EVENT) => {
      setLastName(event.data)

      // Now the form needs to know about the updated full_name_initials
      publish(
        FULL_NAME_INITIALS_CHANGE_EVENT,
        getFullNameInitials(firstName, event.data)
      )
    }
  )

  return (
    <Box className={classes.inputContainer}>
      <Input
        onChange={() => {}}
        label="Full Name Initials"
        placeholder=""
        value={getFullNameInitials(firstName, lastName)}
        disabled
      />
    </Box>
  )
}

const TransactionTypeInput = () => {
  const { classes } = useInputStyles()
  const { control } = useFormContext()
  const publish = usePub()

  // The transaction_type_key input needs to know about the updated
  // transaction_type, so we publish it.
  const onSelectChange = (transactionType: TransactionType) => {
    publish(TRANSACTION_TYPE_CHANGE_EVENT, transactionType)
  }

  return (
    <Box className={classes.inputContainer}>
      <Controller
        name="transaction_type"
        control={control}
        render={({ field }) => (
          <Box>
            <span>
              <Typography className={classes.label}>
                Transaction Type
              </Typography>
            </span>

            <Select
              {...field}
              onChange={(event) => {
                field.onChange(event)
                onSelectChange(event.target.value as TransactionType)
              }}
              options={[
                {
                  value: 'S',
                  label: 'Sale',
                },
                {
                  value: 'AO',
                  label: 'Auth Only',
                },
                {
                  value: 'AVSO',
                  label: 'AVS Only',
                },
                {
                  value: 'R',
                  label: 'Refund',
                },
              ]}
              style={{
                padding: '12px 0',
                width: '100%',
                maxWidth: 'unset',
                border: 'unset',
              }}
              required
            />
          </Box>
        )}
      />
    </Box>
  )
}

const TransactionTypeKeyInput = () => {
  const { classes } = useInputStyles()
  const publish = usePub()

  const [transactionTypeKey, setTransactionTypeKey] =
    useState<TransactionTypeKey | null>(null)

  // Subscribe to receive the field default value.
  useSub(
    DEFAULT_TRANSACTION_TYPE_KEY_EVENT.type,
    (event: typeof DEFAULT_TRANSACTION_TYPE_KEY_EVENT) => {
      setTransactionTypeKey(event.data)
    }
  )

  // Subscribe for transaction_type changes.
  useSub(
    TRANSACTION_TYPE_CHANGE_EVENT.type,
    (event: typeof TRANSACTION_TYPE_CHANGE_EVENT) => {
      const transactionTypeKey = `#${event.data}` as TransactionTypeKey

      setTransactionTypeKey(transactionTypeKey)
      // Now the form needs to know about the updated transaction_type_key.
      publish(TRANSACTION_TYPE_KEY_CHANGE_EVENT, transactionTypeKey)
    }
  )

  return (
    <Box className={classes.inputContainer}>
      <Input
        onChange={() => {}}
        label="Transaction Type Key"
        placeholder=""
        value={transactionTypeKey}
        disabled
      />
    </Box>
  )
}
