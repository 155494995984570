import React from 'react'
import { tss } from 'tss-react/mui'

import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import IconButton from '@mui/material/IconButton'
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'

import appTheme from '@shared/design'
import { getMonthFromString, getDocumentPeriod } from '@shared/utils'

const useStyles = tss.withName('Statement').create(({ theme }) => ({
  statementContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: theme.spacing(1),
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.palette.grey[100],
    },
  },
  boldText: {
    fontWeight: 'bold',
    color: theme.palette.primary.main,
    marginLeft: theme.spacing(2),
    borderBottom: `2px solid ${theme.palette.primary.main}`,
  },
}))

const Statement = ({ statement, onDownload, guidingId }) => {
  const { classes } = useStyles(appTheme)

  return (
    <Paper
      elevation={0}
      className={classes.statementContainer}
      onClick={() => onDownload(statement.document_id)}
      data-guiding-id={guidingId}
    >
      <Typography variant="body1" className={classes.boldText}>
        {getMonthFromString(getDocumentPeriod(statement).toString())}
      </Typography>
      <IconButton>
        <ChevronRightIcon />
      </IconButton>
    </Paper>
  )
}

export default Statement
