import { pick } from 'lodash'
import { FC, useEffect, useState } from 'react'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { tss } from 'tss-react/mui'

import { AppBar } from '@mui/material'

import { api, MerchantAccount } from '@shared/api'
import {
  Button,
  ButtonBar,
  ButtonBarEnd,
  FieldGroupContainer,
  HasPermission,
  Input,
  Loading,
  PageLayoutContainer,
  PageLayoutContainerMain,
  PageLayoutDivider,
  UserNotAllowed,
} from '@shared/components'
import { useFtpPortalHubCommunication, useNotification } from '@shared/hooks'
import { toArrayFieldErrors } from '@shared/utils'

import AllowedTransactionTypes from '../components/allowed-transaction-types/AllowedTransactionTypes'
import GeneralSettings from '../components/general-settings/GeneralSettings'
import ShowFields from '../components/show-fields/ShowFields'

const useStyles = tss.withName('MerchantAccountEdit').create(({ theme }) => ({
  saveButtonContainer: {
    marginLeft: '8px',
  },
}))

const MerchantAccountEdit: FC = () => {
  const { t } = useTranslation()

  const properties = [
    'title',
    'vt_enable',
    'debit_allow_void',
    'display_avs',
    'vt_enable_tip',
    'ach_allow_debit',
    'ach_allow_credit',
    'ach_allow_refund',
    'default_transaction_type',
    'vt_billing_phone',
    'vt_zip',
    'vt_require_zip',
    'vt_street',
    'vt_require_street',
    'vt_clerk_number',
    'vt_show_company_name',
    'vt_show_currency',
    'vt_show_custom_fields',
    'vt_cvv',
    'vt_order_num',
    'vt_enable_sales_tax',
    'vt_override_sales_tax_allowed',
  ]

  const methods = useForm<Partial<MerchantAccount>>()
  const navigate = useNavigate()
  const { classes } = useStyles()
  const { id } = useParams()
  const { setNotification } = useNotification()
  const { state } = useLocation()
  const { setAppBarTitle } = useFtpPortalHubCommunication()

  const [merchantAccount, setMerchantAccount] = useState<MerchantAccount>()
  const [isLoading, setIsLoading] = useState(false)
  const [isInitialLoading, setIsInitialLoading] = useState(true)

  const getMerchant = async () => {
    setIsInitialLoading(true)
    try {
      const merchant = await api.service('product-transaction-details').get(id)
      setMerchantAccount(merchant)

      methods.reset(pick(merchant, properties))
    } catch (error) {
      setNotification({
        type: 'error',
        label: t('common.error'),
      })
    } finally {
      setIsInitialLoading(false)
    }
  }

  const onSubmit = async (data: MerchantAccount) => {
    setIsLoading(true)
    try {
      await api.service('product-transaction-details').patch(id, data)
      navigate(`/merchant/settings/merchant-accounts/${id}/view`)
    } catch (error) {
      const errorsFormatted = toArrayFieldErrors(error)
      let errorMessage = t('common.error')
      if (errorsFormatted.length) {
        errorMessage = errorsFormatted[0].message
      }
      setNotification({
        type: 'error',
        label: errorMessage,
      })
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    setAppBarTitle(
      t('merchant-portal.merchant-account-settings.edit'),
      undefined,
      [
        t('common.settings'),
        t('common.merchant-accounts'),
        merchantAccount?.title,
      ],
      state?.backUrl || '/merchant/settings/merchant-accounts'
    )
  }, [merchantAccount?.id])
  useEffect(() => {
    getMerchant()
  }, [])

  return (
    <HasPermission
      permission="v2.producttransactions.put"
      unauthorizedComponent={<UserNotAllowed />}
    >
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          {isInitialLoading ? (
            <Loading />
          ) : (
            <PageLayoutContainer isButtonBarAtBottom>
              <PageLayoutContainerMain isFullWidth>
                <Controller
                  name="title"
                  control={methods.control}
                  render={({ field }) => (
                    <Input
                      {...field}
                      label={t('common.name')}
                      guidingId="settings-merchantaccount-title"
                    />
                  )}
                />
                <PageLayoutDivider />
                <FieldGroupContainer title={t('common.virtual-terminal')}>
                  {merchantAccount.payment_method === 'cc' && (
                    <GeneralSettings merchantAccount={merchantAccount} isEdit />
                  )}
                  {merchantAccount.payment_method === 'ach' && (
                    <AllowedTransactionTypes isEdit />
                  )}
                  <ShowFields merchantAccount={merchantAccount} isEdit />
                </FieldGroupContainer>
              </PageLayoutContainerMain>

              <AppBar
                sx={{
                  bottom: 0,
                  top: 'auto',
                  position: 'fixed',
                  boxShadow: '0px -12px 79.9px 0px rgba(0, 0, 0, 0.10)',
                }}
              >
                <ButtonBar style={{ marginBottom: '0 !important' }}>
                  <ButtonBarEnd>
                    <Button
                      label={t('common.cancel')}
                      color="secondary"
                      testId="cancel-button"
                      onClick={() => {
                        navigate(
                          `/merchant/settings/merchant-accounts/${id}/view`
                        )
                      }}
                      guidingId="settings-merchantaccount-edit-cancel"
                    />
                    <Button
                      type="submit"
                      label={t('common.save')}
                      isLoading={isLoading}
                      containerClassName={classes.saveButtonContainer}
                      testId="save-button"
                      guidingId="settings-merchantaccount-edit-save"
                    />
                  </ButtonBarEnd>
                </ButtonBar>
              </AppBar>
            </PageLayoutContainer>
          )}
        </form>
      </FormProvider>
    </HasPermission>
  )
}

export default MerchantAccountEdit
