import { AxisTickProps } from '@nivo/axes'
import { useTheme } from '@nivo/core'
import { Datum, Serie } from '@nivo/line'
import { DateTime } from 'luxon'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { tss } from 'tss-react/mui'

import { ChevronLeft, ChevronRight } from '@mui/icons-material'
import {
  ToggleButtonGroup,
  ToggleButton,
  Grid,
  Paper,
  CircularProgress,
  Box,
  Button,
} from '@mui/material'

import { GatewayTransaction, MerchantAccount, api } from '@shared/api'
import {
  LineChart,
  LineChartPoint,
  NoData,
  HasPermission,
  BaseChart,
} from '@shared/components'
import { useLocations, useReportFilters } from '@shared/hooks'
import {
  Filter,
  GatewayResponse,
  PaymentMethodType,
  EnumServiceName,
} from '@shared/types'
import { currency } from '@shared/utils'

import { getTspanGroups } from '../utils/Tickers'

const useStyles = tss
  .withName('GatewayTransactionsChart')
  .create(({ theme }) => ({
    tooltip: {
      padding: '0.5rem',
      backgroundColor: '#4B5563',
      color: '#F3F4F6',
      fontSize: '0.75rem',
      fontFamily: 'Inter',
    },
    p: {
      margin: 0,
      padding: 0,
    },
    loadingContainer: {
      display: 'grid',
      height: '100%',
      placeContent: 'center',
      //marginTop: '25%',
    },
    selectorContainer: {
      position: 'relative',
      display: 'grid',
      placeContent: 'end',
    },
    navigationButton: {
      position: 'absolute',
      top: '50%',
      transform: 'translateY(-50%)',
      backgroundColor: 'transparent',
      border: 'none',
      cursor: 'pointer',
      zIndex: 'auto',
      '&:hover': {
        backgroundColor: 'transparent',
      },
      '& .MuiButton-startIcon': {
        margin: 0,
      },
      '& .MuiButton-endIcon': {
        margin: 0,
      },
    },
    leftButton: {
      left: 'calc(-40px)',
      top: 'calc(70% - 20px)',
    },
    rightButton: {
      right: 'calc(-40px)',
      top: 'calc(70% - 20px)',
    },
    chartContainer: {
      position: 'relative',
    },
    navigationIcon: {
      width: '120px',
      height: '120px',
      color: '#e5e7eb',
    },
  }))

export const GatewayTransactionsDescription =
  'hourly summary of all gateway transactions, settled or not.'

type LineData = {
  id: string
  data: Serie[]
  amount: number
  transactions: number
}

type BarData = {
  Auths: number
  AuthsTransactions: number
  AuthsColor: string

  Refunds: number
  RefundsTransactions: number
  RefundsColor: string

  Sales: number
  SalesTransactions: number
  SalesColor: string

  start: number
  end: number
}

type LineRecord = Record<Filter['id'], LineData>

type ChartEntityIDToNameMap = { id: string; name: string; code: number }

const filters: GatewayResponse[] = [
  {
    id: 'last24hours',
    name: 'last 24 hours',
    range: 6,
    property: 'filter[created_ts][$gte]',
    value: DateTime.now().minus({ days: 1 }).startOf('hour').toSeconds(),
    interval: 1,
  },
  {
    id: 'last48hours',
    name: 'last 48 hours',
    range: 12,
    property: 'filter[created_ts][$gte]',
    value: DateTime.now().minus({ days: 2 }).startOf('hour').toSeconds(),
    interval: 2,
  },
  {
    id: 'last72hours',
    name: 'last 72 hours',
    range: 18,
    property: 'filter[created_ts][$gte]',
    value: DateTime.now().minus({ days: 3 }).startOf('hour').toSeconds(),
    interval: 3,
  },
]

const colorsByChartEntity: Record<string, string> = {
  sale: '#4CB48E',
  auth: '#38BDF8',
  refund: '#A78BFA',
  credit: '#38BDF8',
  debit: '#4CB48E',
}

export const BarChartCustomTicker = (
  props: AxisTickProps<string> & { isLastIndex: boolean }
) => {
  if (props.tickIndex % 2 === 1) return <></>

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const theme = useTheme()

  return (
    <g
      className=""
      transform={`translate(${props.x},${props.y})`}
      style={{ opacity: props.opacity }}
    >
      <text
        alignmentBaseline="middle"
        style={{
          ...theme.axis.ticks.text,
          fontSize: 10,
        }}
        textAnchor={props.textAnchor}
        transform={`translate(${props.textX},${props.textY + 10})`}
      >
        {(props.tickIndex % 4 === 0 || props.isLastIndex) &&
          getTspanGroups(props.value, 6)}
      </text>
    </g>
  )
}

export function GatewayTransactionsChart(props: {
  merchantAccounts?: MerchantAccount[]
  selectedMerchantAccounts?: MerchantAccount[]
  tz: string
  ccTransactions?: GatewayTransaction[]
  achTransactions?: GatewayTransaction[]
  isLoading: boolean
  isError: boolean
}) {
  const { classes } = useStyles()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { hasMerchantAccountACH, hasMerchantAccountCC } = useLocations()
  const [totalTransactions, setTotalTransactions] = useState<number>(0)
  const [totalAmount, setTotalAmount] = useState<number>(0)
  const [transactions, setTransactions] = useState<
    GatewayTransaction[] | undefined
  >([])

  const [gatewayType, setGatewayType] = useState<PaymentMethodType>(
    PaymentMethodType.CC
  )

  const { clearFilter } = useReportFilters()

  const [showToggle, setShowToggle] = useState<boolean>(true)
  const [disableToggle, setDisableToggle] = useState<boolean>(false)
  const [hasTransactions, setHasTransactions] = useState<boolean>(false)

  const selectedFilter = filters[2]

  const totalHoursByFilterId: Record<Filter['id'], number> = {
    last24hours: 24,
    last48hours: 48,
    last72hours: 72,
  }

  const chartBlockTotalHours = 24

  const [chartBlockIndex, setChartBlockIndex] = useState<number>(
    selectedFilter.interval
  )

  const [lineRecord, setLineRecord] = useState<LineRecord | null>(null)

  const ccEntities: ChartEntityIDToNameMap[] = [
    {
      id: 'sale',
      name: t('merchant-portal.sales'),
      code: 101,
    },
    {
      id: 'auth',
      name: t('merchant-portal.auths'),
      code: 102,
    },
    {
      id: 'refund',
      name: t('merchant-portal.refunds'),
      code: 111,
    },
  ]

  const achEntities: ChartEntityIDToNameMap[] = [
    {
      id: 'debit',
      name: t('merchant-portal.debits'),
      code: 50,
    },
    {
      id: 'credit',
      name: t('merchant-portal.credits'),
      code: 40,
    },
    {
      id: 'refund',
      name: t('merchant-portal.refunds'),
      code: 30,
    },
  ]

  const getLineRecord = (chartEntities: ChartEntityIDToNameMap[]): LineRecord =>
    filters.reduce((record, filter) => {
      const currentTransactions = transactions?.find((d) => d.id === filter.id)

      let transactionsAmount = 0
      let amount = 0

      return {
        ...record,
        [filter.id]: {
          data: currentTransactions
            ? currentTransactions.values
                .map((transaction) => {
                  const chartEntity = chartEntities.find(
                    (ch) => ch.id === transaction.id
                  )
                  return {
                    id: chartEntity?.id,
                    name: chartEntity?.name,
                    data: transaction.values
                      .map<Datum | undefined>((value, index) => {
                        transactionsAmount += value[0]

                        if (
                          chartEntity?.id === 'sale' ||
                          chartEntity?.id === 'debit'
                        ) {
                          amount += value[1]
                        } else if (
                          chartEntity?.id === 'credit' ||
                          chartEntity?.id === 'refund'
                        ) {
                          amount -= value[1]
                        }

                        const record: Datum = {
                          id: chartEntity?.id,
                          name: chartEntity?.name,
                          date: currentTransactions.range[index],
                          code: chartEntity?.code,
                          x: `${formatUnixToTz(
                            currentTransactions.range[index],
                            'h a',
                            props.tz
                          )} ${
                            filter.id !== 'last24hours'
                              ? formatUnixToTz(
                                  currentTransactions.range[index],
                                  'MM/dd',
                                  props.tz
                                )
                              : ''
                          }`,
                          y: value[1],
                          transactions: value[0],
                        }

                        return record
                      })
                      .filter((record) => !!record),
                  }
                })
                .sort((a: Datum, b: Datum) => {
                  if (gatewayType === PaymentMethodType.CC)
                    return b.data[0].code - a.data[0].code
                  else return a.data[0].code - b.data[0].code
                })
            : [],
          transactions: transactionsAmount,
          amount: parseFloat(amount.toFixed(2)),
        },
      }
    }, {})

  const getColorByChartEntity = (chartEntity: { id: string }): string => {
    return colorsByChartEntity[chartEntity.id]
  }
  const gatewayTransactionsChartLegend = (options: Datum) => {
    return options.map((option) => {
      const record: Datum = {
        id: option.id,
        label: option.name,
        color: colorsByChartEntity[option.id],
      }
      return record
    })
  }

  const getChartData = <T extends LineData>(
    filterId: Filter['id']
  ): T | null => {
    const record = lineRecord

    if (!record) return null

    const chartDataRecord = record[filterId]

    if (!chartDataRecord) return null

    const totalHours = totalHoursByFilterId[selectedFilter.id]
    const chartBlockDataLength = totalHours / selectedFilter.interval

    const chartBlockStart =
      chartBlockIndex * chartBlockDataLength - chartBlockDataLength

    const chartBlockEnd = chartBlockIndex * chartBlockDataLength
    return {
      ...chartDataRecord,
      data: (chartDataRecord as LineData).data.map((record) => ({
        ...record,
        data: record.data.slice(chartBlockStart, chartBlockEnd),
      })),
    } as T
  }

  useEffect(() => {
    const report = filters[filters.length - chartBlockIndex]
    if (!report) return

    // Recalculate values using the difference between each range
    const reportData = getChartData(report.id)
    const nextReport = getChartData(
      filters[filters.length - chartBlockIndex - 1]?.id
    )

    setTotalTransactions(
      (reportData?.transactions || 0) - (nextReport?.transactions || 0)
    )
    setTotalAmount((reportData?.amount || 0) - (nextReport?.amount || 0))
  }, [
    chartBlockIndex,
    lineRecord,
    gatewayType,
    props.ccTransactions,
    props.achTransactions,
  ])

  useEffect(() => {
    if (!transactions) return
    setLineRecord(
      getLineRecord(
        gatewayType === PaymentMethodType.CC ? ccEntities : achEntities
      )
    )
  }, [transactions])

  useEffect(() => {
    setTransactions(
      gatewayType === PaymentMethodType.CC
        ? props.ccTransactions
        : props.achTransactions
    )
  }, [gatewayType, props.ccTransactions, props.achTransactions])

  useEffect(() => {
    const hasCcMerchantAccount = props.merchantAccounts?.some(
      (merchantAccount) => merchantAccount.payment_method === 'cc'
    )
    const hasAchMerchantAccount = props.merchantAccounts?.some(
      (merchantAccount) => merchantAccount.payment_method === 'ach'
    )

    // Check if any transactions has values to display data or not
    let hasTransactions = false
    if (hasCcMerchantAccount) {
      hasTransactions =
        props.ccTransactions?.some((transaction) =>
          transaction.values.some((value) =>
            value.values.some((subValue) => {
              return subValue[0] > 0
            })
          )
        ) ?? false
    }
    if (hasAchMerchantAccount) {
      hasTransactions =
        hasTransactions ||
        (props.achTransactions?.some((transaction) =>
          transaction.values.some((value) =>
            value.values.some((subValue) => subValue[0] > 0)
          )
        ) ??
          false)
    }

    setHasTransactions(hasTransactions)

    // If one of the payment method is not available, set the other one as default and hide toggle
    if (!hasCcMerchantAccount || !hasAchMerchantAccount) {
      setShowToggle(false)
      setGatewayType(
        hasCcMerchantAccount ? PaymentMethodType.CC : PaymentMethodType.ACH
      )
      return
    }
    // If both payment methods are available, check if you select only one of them and disable toggle and default to that one
    const selectedCcMerchantAccount = props.selectedMerchantAccounts.some(
      (merchantAccount) => merchantAccount.payment_method === 'cc'
    )
    const selectedAchMerchantAccount = props.selectedMerchantAccounts.some(
      (merchantAccount) => merchantAccount.payment_method === 'ach'
    )

    if (
      (selectedCcMerchantAccount && !selectedAchMerchantAccount) ||
      (!selectedCcMerchantAccount && selectedAchMerchantAccount)
    ) {
      setDisableToggle(true)
      setGatewayType(
        selectedCcMerchantAccount ? PaymentMethodType.CC : PaymentMethodType.ACH
      )
      return
    }

    if (!hasMerchantAccountACH || !hasMerchantAccountCC) {
      setDisableToggle(true)
      setGatewayType(
        !hasMerchantAccountACH ? PaymentMethodType.CC : PaymentMethodType.ACH
      )
      return
    }

    // Default values
    setDisableToggle(false)
    setShowToggle(true)
    setGatewayType(PaymentMethodType.CC)
  }, [props.ccTransactions, props.achTransactions])

  const selector = (
    <div className={classes.selectorContainer}>
      <ToggleButtonGroup
        value={gatewayType}
        disabled={disableToggle}
        exclusive
        onChange={(_, value) => {
          if (value !== null) {
            setGatewayType(value)
          }
        }}
        aria-label={t('common.toggle-cc-ach')}
        sx={{
          height: '2rem',
        }}
      >
        <ToggleButton
          value={PaymentMethodType.CC}
          data-guiding-id="mp-dashboard-gatewaytransactions-cc"
        >
          CC
        </ToggleButton>
        <ToggleButton
          value={PaymentMethodType.ACH}
          data-guiding-id="mp-dashboard-gatewaytransactions-ach"
        >
          ACH
        </ToggleButton>
      </ToggleButtonGroup>
    </div>
  )

  const transactionsInfo = (
    <Grid container spacing={2} justifyContent="flex-start" alignItems="center">
      <Grid item xs={10} md={6}>
        <div>
          <p className={classes.p}>{t('common.total-transactions')}</p>
          <p className={classes.p} style={{ fontWeight: 'bold' }}>
            {totalTransactions ? totalTransactions : 0}
          </p>
        </div>
      </Grid>
      <Grid item xs={12} md={6}>
        <div>
          <p className={classes.p}>{t('common.total-amount')}</p>
          <p className={classes.p} style={{ fontWeight: 'bold' }}>
            {currency(totalAmount as number)}
          </p>
        </div>
      </Grid>
    </Grid>
  )

  const formatUnixToTz = (unixTime, format, tz) => {
    const formatted = DateTime.fromSeconds(unixTime)
      .setZone(tz)
      .toFormat(format)
    return formatted.toLowerCase()
  }

  const navigateToTransactions = ({ data }: LineChartPoint) => {
    const report = filters[filters.length - chartBlockIndex]
    if (!report) return

    const from = DateTime.fromSeconds(data.date).setZone(props.tz)

    const to = from.plus({ hours: 1 })

    const filterCode =
      gatewayType === PaymentMethodType.CC
        ? 'filter[status_code]='
        : 'filter[type_id]='

    clearFilter(EnumServiceName.GatewayTransactionsReports)

    const reportURL = `/merchant/reports/gateway/gateway-transactions?filter[created_ts][$gte]=${from.toSeconds()}&filter[created_ts][$lte]=${to.toSeconds()}&${filterCode}${
      data.code
    }`

    navigate(reportURL)
  }

  return (
    <div className={classes.chartContainer}>
      <HasPermission
        permission="v2.transactions.get"
        unauthorizedComponent={
          <NoData
            title={t('common.validations.not-available-feature')}
            label={t('common.validations.please-contact-support')}
            icon="error"
          />
        }
      >
        <>
          {!props.isError && !props.isLoading && hasTransactions ? (
            <>
              {chartBlockIndex > 1 && (
                <Button
                  className={`${classes.navigationButton} ${classes.leftButton}`}
                  onClick={() => {
                    setChartBlockIndex((chartBlockIndex) => chartBlockIndex - 1)
                  }}
                  disableRipple
                  data-guiding-id="mp-dashboard-gatewaytransactions-left"
                >
                  <ChevronLeft className={classes.navigationIcon} />
                </Button>
              )}

              {chartBlockIndex < selectedFilter.interval && (
                <Button
                  className={`${classes.navigationButton} ${classes.rightButton}`}
                  onClick={() => {
                    setChartBlockIndex((chartBlockIndex) => chartBlockIndex + 1)
                  }}
                  disableRipple
                  data-guiding-id="mp-dashboard-gatewaytransactions-right"
                >
                  <ChevronRight className={classes.navigationIcon} />
                </Button>
              )}
            </>
          ) : null}
          <BaseChart
            title={t('merchant-portal.gateway-transactions')}
            extraButton={
              showToggle && hasTransactions && !props.isError ? selector : null
            }
            transactionsInfo={transactionsInfo}
            applyBorder={false}
            guidingId="mp-dashboard-gatewaytransactions"
          >
            {!props.isLoading && hasTransactions ? (
              <LineChart
                animate={false}
                data={getChartData(selectedFilter.id).data ?? []}
                margin={{ top: 30, bottom: 65, right: 15, left: 75 }}
                axisBottom={{
                  renderTick: (props: AxisTickProps<string>) => (
                    <BarChartCustomTicker
                      {...props}
                      isLastIndex={props.tickIndex === chartBlockTotalHours - 1}
                    />
                  ),
                }}
                colors={getColorByChartEntity}
                legends={[
                  {
                    data: gatewayTransactionsChartLegend(
                      gatewayType === PaymentMethodType.CC
                        ? ccEntities
                        : achEntities
                    ),
                    itemTextColor: '#000',
                    anchor: 'top-left',
                    direction: 'row',
                    translateX: -22,
                    translateY: -25,
                    itemsSpacing: 20,
                    itemDirection: 'left-to-right',
                    itemWidth: 90,
                    itemHeight: 20,
                    symbolShape: 'circle',
                    symbolSize: 10,
                  },
                ]}
                axisLeft={{
                  format: (value: number) => currency(value),
                  tickPadding: 10,
                }}
                yFormat={(value) => currency(Number(value))}
                tooltip={({ point }) => {
                  const { data } = point as LineChartPoint

                  return (
                    <Paper elevation={3} className={classes.tooltip}>
                      <Grid container direction={'column'}>
                        <Grid item>
                          <span>{t(data.name || '')}</span>
                        </Grid>
                        <Grid item>
                          {data.date && (
                            <span>
                              {formatUnixToTz(data.date, 'ha', props.tz)} -
                              {formatUnixToTz(
                                data.date + 3600,
                                'ha  - MM/dd',
                                props.tz
                              )}
                            </span>
                          )}
                        </Grid>
                        <Grid item>
                          <span>
                            {t('common.transactions')}: {data.transactions}
                          </span>
                        </Grid>
                        <Grid item>
                          <span>
                            {t('common.amount')}: {data.yFormatted}
                          </span>
                        </Grid>
                      </Grid>
                    </Paper>
                  )
                }}
                onClick={(point) => {
                  navigateToTransactions(point)
                }}
              />
            ) : (
              <Box className={classes.loadingContainer}>
                {props.isLoading ? (
                  <CircularProgress data-testid="loading-icon" size={45} />
                ) : props.isError ? (
                  <NoData
                    title={t('common.validations.loading-data')}
                    label={t('common.validations.loading-data-explainer')}
                    icon="error"
                  />
                ) : (
                  <NoData
                    title={t('common.validations.not-available-data')}
                    label={t('common.validations.not-available-data-display')}
                  />
                )}
              </Box>
            )}
          </BaseChart>
        </>
      </HasPermission>
    </div>
  )
}
