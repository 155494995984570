export const getTspanGroups = (
  value: string,
  maxLineLength: number,
  formatLine: (line: string) => string = (line) => line,
  maxLines = 2
) => {
  const words = value.split(' ')

  type linesAcc = {
    lines: string[]
    currLine: string
  }

  const assembleLines: linesAcc = words.reduce(
    (acc: linesAcc, word: string) => {
      if ((word + acc.currLine).length > maxLineLength && acc.currLine !== '') {
        return {
          lines: acc.lines.concat([acc.currLine]),
          currLine: word,
        }
      }

      return {
        ...acc,
        currLine: acc.currLine + ' ' + word,
      }
    },
    { lines: [], currLine: '' }
  )

  const allLines = assembleLines.lines.concat([assembleLines.currLine])

  const lines = allLines.slice(0, maxLines)

  const children: JSX.Element[] = []

  let dy = 0

  lines.forEach((lineText, i) => {
    lineText = formatLine(lineText)

    children.push(
      <tspan x={0} dy={dy} key={i}>
        {1 === i && allLines.length > 2
          ? lineText.slice(0, maxLineLength - 3) + '...'
          : lineText}
      </tspan>
    )

    dy += 15
  })

  return children
}
