import { Datum } from '@nivo/line'

import { RecurringTransactionsHistory } from '@shared/api/src/schemas/types'
import { ChartEntity, Filter } from '@shared/types'

const PositiveTransactions = ['paid']
const NegativeTransactions = ['unpaid']

interface SumByDate {
  [date: string]: {
    paid: number
    paidTotalTransactions: number
    unpaid: number
    unpaidTotalTransactions: number
  }
}

interface TransformedEntry {
  date: string
  paid: number
  paidTotalTransactions: number
  unpaid: number
  unpaidTotalTransactions: number
}

const transformData = (originalData) => {
  const sumsByDate: SumByDate = {}

  originalData.forEach((category) => {
    category.data.forEach((entry) => {
      const { x, y, transactions } = entry
      if (!sumsByDate[x]) {
        sumsByDate[x] = {
          paid: 0,
          unpaid: 0,
          paidTotalTransactions: 0,
          unpaidTotalTransactions: 0,
        }
      }
      if (category.id === 'Paid') {
        sumsByDate[x].paid += y
        sumsByDate[x].paidTotalTransactions += transactions
      } else if (category.id === 'Unpaid') {
        sumsByDate[x].unpaid += y
        sumsByDate[x].unpaidTotalTransactions += transactions
      }
    })
  })

  const transformedData = Object.entries(sumsByDate).map(
    ([date, values]): TransformedEntry => ({
      date,
      paid: values.paid,
      paidTotalTransactions: values.paidTotalTransactions,
      unpaid: values.unpaid,
      unpaidTotalTransactions: values.unpaidTotalTransactions,
    })
  )

  return transformedData
}

const mapBarChart = (
  filters: Filter[],
  transactions: RecurringTransactionsHistory[],
  chartEntities: ChartEntity[],
  formatX: (filter: string, date: number) => string
): Datum[] => {
  return filters.reduce((record, filter) => {
    const currentTransactions = transactions?.find((d) => d.id === filter.id)
    let transactionsAmount = 0
    let amount = 0

    return {
      ...record,
      [filter.id]: {
        data: transformData(
          currentTransactions
            ? currentTransactions.values.map((transaction) => {
                const chartEntity = chartEntities.find(
                  (ch) => ch.id === transaction.id
                )
                return {
                  id: chartEntity?.name,
                  data: transaction.values
                    .map<Datum | undefined>((value, index) => {
                      transactionsAmount += value[0]

                      if (
                        PositiveTransactions.some(
                          (transaction) => chartEntity.id === transaction
                        )
                      ) {
                        amount += value[1]
                      } else if (
                        NegativeTransactions.some(
                          (transaction) => chartEntity.id === transaction
                        )
                      ) {
                        amount -= value[1]
                      }

                      const record: Datum = {
                        id: chartEntity?.name,
                        date: currentTransactions.range[index],
                        code: chartEntity?.code,
                        x: formatX(
                          filter.id,
                          currentTransactions.range[index] * 1000
                        ),
                        y: value[1],
                        transactions: value[0],
                      }

                      return record
                    })
                    .filter((record) => !!record),
                }
              })
            : []
        ),
        transactions: transactionsAmount,
        amount: parseFloat(amount.toFixed(2)),
      },
    }
  }, [])
}

export default mapBarChart
