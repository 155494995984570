import { get, pick } from 'lodash'
import React, { useEffect, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { tss } from 'tss-react/mui'

import { Box, Grid } from '@mui/material'

import {
  api,
  MerchantAccount,
  ProductTransactionAutoSettle,
} from '@shared/api/src'
import {
  Button,
  ButtonBar,
  ButtonBarEnd,
  ButtonBarStart,
  EnumeratedStatus,
  FieldGroupContainer,
  FieldGroupRow,
  FieldGroupViewItem,
  FieldGroupViewItemWithChildren,
  HasPermission,
  Loading,
  UserNotAllowed,
  PageLayoutDivider,
} from '@shared/components'
import {
  useAuthorization,
  useFtpPortalHubCommunication,
  useLocations,
} from '@shared/hooks'
import { PaymentMethodType } from '@shared/types'
import {
  checkPermission,
  checkSomePermissions,
  formatAutoCloseTimes,
  formatCurrencyCode,
  formatNumberField,
  useFormatBooleanAsYesNo,
} from '@shared/utils'

import AllowedTransactionTypes from '../components/allowed-transaction-types/AllowedTransactionTypes'
import GeneralSettings from '../components/general-settings/GeneralSettings'
import ToggleMerchantDeactivateModal from '../components/merchant-deactivate-modal/ToggleMerchantDeactivateModal'
import EditFraudSettings from '../components/modals/edit-fraud-settings/EditFraudSettings'
import ShowFields from '../components/show-fields/ShowFields'

const useStyles = tss.withName('MerchantAccountView').create(({ theme }) => ({
  container: {
    display: 'flex',
    padding: '24px',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '24px',
    alignSelf: 'stretch',
    borderRadius: '6px',
    border: `1px solid ${theme.palette['neutral-100']}`,
    backgroundColor: theme.palette.background.paper,
  },
  section: {
    padding: 0,
  },
  editButton: {
    borderRadius: '6px',
  },
  deactiveButton: {
    deactiveButton: '6px',
    marginRight: '8px',
  },
  chipContainer: {
    display: 'flex',
    gap: '5px',
  },
}))

const MerchantAccountView = () => {
  const { t } = useTranslation()
  const { classes } = useStyles()
  const navigate = useNavigate()
  const { id } = useParams()

  const { userPermissionSet } = useAuthorization()
  const { selectedLocation } = useLocations()
  const { setAppBarTitle } = useFtpPortalHubCommunication()
  const { state } = useLocation()
  const methods = useForm<MerchantAccount>()

  const [merchantAccount, setMerchantAccount] = useState<MerchantAccount>()
  const [autoDeclines, setAutoDeclines] = useState<string[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [autoSettles, setAutoSettles] = useState<
    ProductTransactionAutoSettle[]
  >([])
  const [isToggleDeactivateModalOpen, setIsToggleDeactivateModalOpen] =
    useState(false)
  const [isEditFraudModalOpen, setIsEditFraudModalOpen] = useState(false)

  const MerchantAccountViewPrivs = ['v2.producttransactiondetails.get']

  useEffect(() => {
    setAppBarTitle(
      merchantAccount?.title,
      null,
      [t('common.settings'), t('common.merchant-account')],
      state?.backUrl || '/merchant/settings/merchant-accounts'
    )
  }, [merchantAccount])

  const industryType = merchantAccount?.industry_type
    ? t(merchantAccount?.industry_type as string)
    : null
  const merchantAccountPaymentMethod = merchantAccount?.payment_method

  const getMerchantAccount = async () => {
    try {
      await api
        .service('product-transaction-details')
        .get(id)
        .then((merchantAccount) => {
          if (!merchantAccount) return
          setMerchantAccount(merchantAccount)
          methods.reset(
            pick(merchantAccount, [
              'title',
              'vt_enable',
              'debit_allow_void',
              'display_avs',
              'vt_enable_tip',
              'ach_allow_debit',
              'ach_allow_credit',
              'ach_allow_refund',
              'default_transaction_type',
              'vt_billing_phone',
              'vt_zip',
              'vt_require_zip',
              'vt_street',
              'vt_require_street',
              'vt_clerk_number',
              'vt_show_company_name',
              'vt_show_currency',
              'vt_show_custom_fields',
              'vt_cvv',
              'vt_order_num',
              'vt_enable_sales_tax',
              'vt_override_sales_tax_allowed',
            ])
          )
        })
      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
      console.error(error)
    }
  }

  useEffect(() => {
    if (!merchantAccount) return

    const autoDeclineProps: { [key: string]: string } = {
      auto_decline_cvv: 'CVV',
      auto_decline_street: t('street'),
      auto_decline_zip: t('postal code'),
    }

    const declines = Object.keys(autoDeclineProps)
      .filter((key) => get(merchantAccount, key))
      .map((key) => autoDeclineProps[key])

    setAutoDeclines(declines)
  }, [merchantAccount])

  useEffect(() => {
    getMerchantAccount()
  }, [id, selectedLocation])

  useEffect(() => {
    if (!id) return

    api
      .service('product-transaction-auto-settles')
      .find()
      .then((autoSettles) => {
        const merchantAutoSettles = (
          autoSettles as unknown as ProductTransactionAutoSettle[]
        ).filter((a) => a.product_transaction_id === id)

        setAutoSettles(merchantAutoSettles)
      })
  }, [id])

  const isMerchantAccountCC =
    merchantAccount?.payment_method === PaymentMethodType.CC

  const allowBlindRefund = useFormatBooleanAsYesNo(
    merchantAccount?.allow_blind_refund
  )

  const vtEnableSalesTax = useFormatBooleanAsYesNo(
    merchantAccount?.vt_enable_sales_tax
  )

  const vtOverrideSalesTaxAllowed = useFormatBooleanAsYesNo(
    merchantAccount?.vt_override_sales_tax_allowed
  )
  const vtTaxSurchargeConfig =
    merchantAccount?.tax_surcharge_config === 2
      ? t('after surcharge')
      : merchantAccount?.tax_surcharge_config === 3
      ? t('before surcharge')
      : undefined

  const batchAutoCloseTime =
    autoSettles.length > 0 ? formatAutoCloseTimes(autoSettles) : undefined
  const batchCloseAmount = String(
    formatNumberField(
      merchantAccount?.processor_data?.batch_autoclose_amount || 0
    )
  )
  const batchCloseCount = String(
    formatNumberField(
      merchantAccount?.processor_data?.batch_autoclose_count || 0
    )
  )

  const currencyType = formatCurrencyCode(merchantAccount?.currency_code)

  const shouldRenderButtonBar = () => {
    return (
      checkSomePermissions(
        userPermissionSet,
        'v2.producttransactions.delete',
        'v2.producttransactions.post.active'
      ) || checkPermission(userPermissionSet, 'v2.producttransactions.put')
    )
  }

  if (isLoading) {
    return <Loading />
  }

  return (
    <HasPermission
      allPermissions={MerchantAccountViewPrivs}
      unauthorizedComponent={<UserNotAllowed />}
    >
      <>
        <ToggleMerchantDeactivateModal
          merchantAccount={merchantAccount}
          onFinish={getMerchantAccount}
          open={isToggleDeactivateModalOpen}
          handleClose={() => setIsToggleDeactivateModalOpen(false)}
        />
        {shouldRenderButtonBar && (
          <ButtonBar>
            {checkSomePermissions(
              userPermissionSet,
              'v2.producttransactions.delete',
              'v2.producttransactions.post.active'
            ) && (
              <ButtonBarStart>
                {merchantAccount.active &&
                  checkPermission(
                    userPermissionSet,
                    'v2.producttransactions.delete'
                  ) && (
                    <Button
                      className={classes.deactiveButton}
                      color="secondary"
                      label={t('common.deactivate')}
                      onClick={() => setIsToggleDeactivateModalOpen(true)}
                    />
                  )}

                {!merchantAccount.active &&
                  checkPermission(
                    userPermissionSet,
                    'v2.producttransactions.post.activate'
                  ) && (
                    <Button
                      className={classes.deactiveButton}
                      color="secondary"
                      label={t('common.reactivate')}
                      onClick={() => setIsToggleDeactivateModalOpen(true)}
                    />
                  )}
              </ButtonBarStart>
            )}
            {checkPermission(
              userPermissionSet,
              'v2.producttransactions.put'
            ) && (
              <ButtonBarEnd>
                <Button
                  className={classes.editButton}
                  color="primary"
                  label={t('common.edit')}
                  onClick={() =>
                    navigate(
                      `/merchant/settings/merchant-accounts/${id}/edit`,
                      {
                        state: {
                          backUrl: `/merchant/settings/merchant-accounts/${id}/view`,
                        },
                      }
                    )
                  }
                />
              </ButtonBarEnd>
            )}
          </ButtonBar>
        )}
        <Box className={classes.container}>
          <Grid container spacing={2}>
            <Grid item xs={12} className={classes.section}>
              <FieldGroupContainer title={t('common.general-settings')}>
                <FieldGroupRow spacing={2}>
                  <FieldGroupViewItem
                    title={t('common.title')}
                    value={merchantAccount?.title}
                  />
                  <FieldGroupViewItem
                    title={t(
                      'merchant-portal.merchant-account-settings.currency'
                    )}
                    value={currencyType}
                  />
                  <FieldGroupViewItemWithChildren
                    title={t(
                      'merchant-portal.merchant-account-settings.processing-details'
                    )}
                  >
                    {isMerchantAccountCC && (
                      <EnumeratedStatus value={industryType} />
                    )}
                    <EnumeratedStatus value={merchantAccountPaymentMethod} />
                  </FieldGroupViewItemWithChildren>
                </FieldGroupRow>
              </FieldGroupContainer>
              <PageLayoutDivider />
            </Grid>

            {isMerchantAccountCC && (
              <Grid item xs={12} className={classes.section}>
                <FieldGroupContainer
                  title={t(
                    'merchant-portal.merchant-account-settings.fraud-settings'
                  )}
                  buttonData={
                    merchantAccount?.active &&
                    checkPermission(
                      userPermissionSet,
                      'v2.producttransactiondetails.put'
                    )
                      ? {
                          label: t('common.edit'),
                          onClick: () => setIsEditFraudModalOpen(true),
                          color: 'secondary',
                        }
                      : null
                  }
                >
                  <FieldGroupRow spacing={2}>
                    <FieldGroupViewItemWithChildren
                      title={t(
                        'merchant-portal.merchant-account-settings.auto-decline'
                      )}
                    >
                      <Box className={classes.chipContainer}>
                        {autoDeclines.map((a, index) => (
                          <EnumeratedStatus key={index} value={a} />
                        ))}
                      </Box>
                    </FieldGroupViewItemWithChildren>
                    <FieldGroupViewItem
                      title={t(
                        'merchant-portal.merchant-account-settings.allow-blind-refund'
                      )}
                      value={allowBlindRefund}
                    />
                  </FieldGroupRow>
                </FieldGroupContainer>
                <PageLayoutDivider />
              </Grid>
            )}

            <Grid item xs={12} className={classes.section}>
              <FieldGroupContainer
                title={t(
                  'merchant-portal.merchant-account-settings.tax-settings'
                )}
              >
                <FieldGroupRow spacing={2}>
                  <FieldGroupViewItem
                    title={t(
                      'merchant-portal.merchant-account-settings.show-tax-virtual-terminal'
                    )}
                    value={vtEnableSalesTax}
                  />
                  <FieldGroupViewItem
                    title={t(
                      'merchant-portal.merchant-account-settings.allow-overriding-calculated-tax'
                    )}
                    value={vtOverrideSalesTaxAllowed}
                  />
                  <FieldGroupViewItem
                    title={t(
                      'merchant-portal.merchant-account-settings.tax-application-config'
                    )}
                    value={vtTaxSurchargeConfig}
                  />
                </FieldGroupRow>
              </FieldGroupContainer>
              <PageLayoutDivider />
            </Grid>

            {isMerchantAccountCC && (
              <Grid item xs={12} className={classes.section}>
                <FieldGroupContainer
                  title={t(
                    'merchant-portal.merchant-account-settings.batch-settings'
                  )}
                >
                  <FieldGroupRow spacing={2}>
                    <FieldGroupViewItem
                      title={t(
                        'merchant-portal.merchant-account-settings.auto-close-amount'
                      )}
                      value={batchCloseAmount}
                    />
                    <FieldGroupViewItem
                      title={t(
                        'merchant-portal.merchant-account-settings.auto-close-count'
                      )}
                      value={batchCloseCount}
                    />
                    <FieldGroupViewItem
                      title={t(
                        'merchant-portal.merchant-account-settings.auto-close-time'
                      )}
                      value={batchAutoCloseTime}
                    />
                  </FieldGroupRow>
                </FieldGroupContainer>
                <PageLayoutDivider />
              </Grid>
            )}

            <Grid item xs={12} className={classes.section}>
              <FieldGroupContainer title={t('common.virtual-terminal')}>
                <FormProvider {...methods}>
                  {merchantAccount.payment_method === 'cc' && (
                    <GeneralSettings merchantAccount={merchantAccount} />
                  )}
                  {merchantAccount.payment_method === 'ach' && (
                    <AllowedTransactionTypes />
                  )}
                  <ShowFields merchantAccount={merchantAccount} />
                </FormProvider>
              </FieldGroupContainer>
              <PageLayoutDivider />
            </Grid>
          </Grid>
        </Box>
        {isEditFraudModalOpen && (
          <EditFraudSettings
            open={isEditFraudModalOpen}
            handleClose={() => setIsEditFraudModalOpen(false)}
            merchantAccount={merchantAccount}
            onSuccess={getMerchantAccount}
          />
        )}
      </>
    </HasPermission>
  )
}

export default MerchantAccountView
