import { ColDef } from 'ag-grid-community'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import DateRangeFilter from '@shared/ag-grid/pagination-table/filters/date-range-filter/DateRangeFilter'
import PaginationTable from '@shared/ag-grid/pagination-table/PaginationTable'
import { ReportSettledTransaction } from '@shared/api/src/schemas/types'
import { HasPermission, UserNotAllowed, AccountType } from '@shared/components'
import {
  useEnforceLogin,
  useFilterModel,
  useFtpPortalHubCommunication,
  useLocations,
} from '@shared/hooks'
import {
  secCodeFilterOptions,
  filterAchAccountTypeValues,
  filterAchTransactionType,
} from '@shared/mapping/gateway-transactions'
import { EnumServiceName } from '@shared/types'
import {
  formatDate,
  currency,
  filterParams,
  useQueryParams,
  DataSource,
} from '@shared/utils'

export default function SettledTransactionsACH() {
  //TODO: add translation when email receipt or other functionality is added
  const { t } = useTranslation()
  const { setAppBarTitle } = useFtpPortalHubCommunication()
  const { user } = useEnforceLogin()
  const { selectedLocation, setSelectedLocation } = useLocations()
  const [selectedDate, setSelectedDate] = useState<string>(null)

  const TransactionsReportPrivs = ['v2.reports.get', 'v2.transactions.get']

  useEffect(() => {
    setAppBarTitle(
      t('common.settled-transactions-ach'),
      null,
      t('common.reporting')
    )
  }, [])

  useEffect(() => {
    const handleLocationChange = async (event) => {
      const newLocation = event.detail.location
      await setSelectedLocation(newLocation)
    }

    window.addEventListener(
      'FTP_NAVIGATOR_LOCATION_CHANGED',
      handleLocationChange
    )

    return () => {
      window.removeEventListener(
        'FTP_NAVIGATOR_LOCATION_CHANGED',
        handleLocationChange
      )
    }
  }, [])

  const { startDate, endDate } = useQueryParams([
    'filter[date_settled_ts][$gte]',
    'filter[date_settled_ts][$lte]',
  ])

  const datasource = useMemo(() => {
    const filters = {
      'filter[location_id]': selectedLocation?.id,
    }

    const extraParams = filterParams(filters)

    return new DataSource('settled-transactions-reports-ach', extraParams)
  }, [selectedLocation?.id])

  const columnDefs: ColDef<ReportSettledTransaction>[] = useMemo(
    () => [
      {
        headerName: t('common.transaction-id'),
        field: 'transaction_id',
        floatingFilter: true,
        filter: 'agTextColumnFilter',
        sortable: true,
      },
      {
        headerName: t('common.date-reported'),
        field: 'date_reported_ts',
        floatingFilter: true,
        filter: DateRangeFilter,
        valueGetter: (params) => {
          const timestamp = params.data?.date_reported_ts
          return formatDate(timestamp * 1000, user?.tz) || ''
        },
        filterParams: {
          typeOfSearch: 'contains',
          filterType: 'text',
          service: 'settled-transactions-reports-ach',
          filterPropName: 'date_reported_ts',
          value: selectedDate,
          onApply: (event) => {
            setSelectedDate(event)
          },
        },
        sortable: true,
      },
      {
        headerName: t('common.date-settled'),
        field: 'date_settled_ts',
        floatingFilter: true,
        filter: DateRangeFilter,
        valueGetter: (params) => {
          const timestamp = params.data?.date_settled_ts
          return formatDate(timestamp, user?.tz) || ''
        },
        filterParams: {
          typeOfSearch: 'contains',
          filterType: 'text',
          service: 'settled-transactions-reports-ach',
          filterPropName: 'date_settled_ts',
          value: selectedDate,
          onApply: (event) => {
            setSelectedDate(event)
          },
        },
        sortable: true,
        sort: 'desc',
      },
      {
        headerName: t('common.date-deposit'),
        field: 'date_deposited_ts',
        floatingFilter: true,
        filter: DateRangeFilter,
        valueGetter: (params) => {
          const timestamp = params.data?.date_deposited_ts
          return formatDate(timestamp, user?.tz) || ''
        },
        filterParams: {
          typeOfSearch: 'contains',
          filterType: 'text',
          service: 'settled-transactions-reports-ach',
          filterPropName: 'date_deposited_ts',
          value: selectedDate,
          onApply: (event) => {
            setSelectedDate(event)
          },
        },
        sortable: true,
      },
      {
        headerName: t('common.token-last-four'),
        field: 'account_number',
        floatingFilter: true,
        filter: 'agTextColumnFilter',
        sortable: true,
      },
      {
        headerName: t('common.account-holder-name'),
        field: 'individual_name',
        floatingFilter: true,
        filter: 'agTextColumnFilter',
        sortable: true,
      },
      {
        headerName: t('common.account-type'),
        field: 'account_type',
        filter: 'agSetColumnFilter',
        floatingFilter: true,
        filterParams: {
          values: filterAchAccountTypeValues.map((option) => option.value),
          valueFormatter: (params) => {
            const option = filterAchAccountTypeValues.find(
              (option) => option.value === params.value
            )

            return option ? option.label : params.value
          },
          comparator: (a, b) => {
            const aOption = filterAchAccountTypeValues.find(
              (option) => option.value === a
            )
            const bOption = filterAchAccountTypeValues.find(
              (option) => option.value === b
            )

            return aOption.label.localeCompare(bOption.label)
          },
        },
        cellRenderer: (data) => {
          return <AccountType type={data.value} />
        },
        sortable: true,
      },
      {
        headerName: t('common.transaction-sec-code'),
        field: 'entry_class',
        floatingFilter: true,
        filter: 'agSetColumnFilter',
        filterParams: {
          values: secCodeFilterOptions.map((option) => option.value),
          valueFormatter: (params) => {
            const option = secCodeFilterOptions.find(
              (option) => option.value === params.value
            )
            return option ? option.label : params.value
          },
          comparator: (a, b) => {
            const aOption = secCodeFilterOptions.find(
              (option) => option.value === a
            )
            const bOption = secCodeFilterOptions.find(
              (option) => option.value === b
            )
            return aOption.label.localeCompare(bOption.label)
          },
        },
        sortable: true,
      },
      {
        headerName: t('common.amount-transaction'),
        field: 'amount',
        type: 'rightAligned',
        floatingFilter: true,
        filter: 'agNumberColumnFilter',
        filterParams: {
          allowedCharPattern: '\\d\\-\\.\\$\\,',
          numberParser: (value: number) => {
            return value ? value * 100 : null
          },
        },
        sortable: true,
        valueGetter: (params) => currency(params.data?.amount / 100),
      },
      {
        headerName: t('common.amount-retained'),
        field: 'amount_2',
        type: 'rightAligned',
        floatingFilter: true,
        filter: 'agNumberColumnFilter',
        filterParams: {
          allowedCharPattern: '\\d\\-\\.\\$\\,',
          numberParser: (value: number) => {
            return value ? value * 100 : null
          },
        },
        sortable: true,
        valueGetter: (params) => currency(params.data?.amount_2 / 100),
      },
      {
        headerName: t('common.date-transaction'),
        field: 'trxn_utc_date_ts',
        floatingFilter: true,
        filter: DateRangeFilter,
        valueGetter: (params) => {
          const timestamp = params.data?.trxn_utc_date_ts
          return formatDate(timestamp, user?.tz) || '-'
        },
        filterParams: {
          typeOfSearch: 'contains',
          filterType: 'text',
          service: 'settled-transactions-reports-ach',
          filterPropName: 'trxn_utc_date_ts',
          value: selectedDate,
          onApply: (event) => {
            setSelectedDate(event)
          },
        },
        sortable: true,
      },
      {
        headerName: t('common.transaction-type'),
        field: 'transaction_type',
        filter: 'agSetColumnFilter',
        filterParams: {
          values: filterAchTransactionType.map((option) => option.value),
          valueFormatter: (params) => {
            const option = filterAchTransactionType.find(
              (option) => option.value === params.value
            )
            return option ? option.label : params.value
          },
          comparator: (a, b) => {
            const aOption = filterAchTransactionType.find(
              (option) => option.value === a
            )
            const bOption = filterAchTransactionType.find(
              (option) => option.value === b
            )
            return aOption.label.localeCompare(bOption.label)
          },
        },
        floatingFilter: true,
        sortable: true,
      },
      {
        headerName: t('merchant-portal.batch-number'),
        field: 'batch_ref_no',
        floatingFilter: true,
        filter: 'agTextColumnFilter',
        sortable: true,
      },
    ],
    []
  )

  const defaultColDef = useMemo(
    () => ({
      resizable: true,
    }),
    []
  )

  const defaultFilters = {
    date_settled_ts: {
      type: 'equal',
      filterType: 'text',
      filter: 'yesterday',
    },
  }

  const paramKeys = [
    {
      queryKey: 'filter[date_settled_ts][$gte]',
      filterKey: 'date_settled_ts',
      filterType: 'greaterThanOrEqual',
      includeTime: true,
    },
  ]

  const initialFilterModel = useFilterModel(defaultFilters, paramKeys)

  return (
    <section>
      <HasPermission
        allPermissions={TransactionsReportPrivs}
        unauthorizedComponent={<UserNotAllowed />}
      >
        <PaginationTable<ReportSettledTransaction>
          getRowId={(data) => data.data.transaction_id}
          columnDefs={columnDefs}
          rowModelType={'serverSide'}
          serverSideDatasource={datasource}
          defaultColDef={defaultColDef}
          initialFilterModel={initialFilterModel}
          showExportButton={true}
          showClearFiltersButton={true}
          serviceName={EnumServiceName.SettledTransactionsReportsAch}
          guidingId="reports-settledtransactionsach"
        />
      </HasPermission>
    </section>
  )
}
