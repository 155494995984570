import { Datum } from '@nivo/line'
import { DateTime } from 'luxon'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { tss } from 'tss-react/mui'

import {
  ToggleButtonGroup,
  ToggleButton,
  Grid,
  Paper,
  CircularProgress,
  Box,
  SelectChangeEvent,
} from '@mui/material'

import { MerchantAccount, api } from '@shared/api'
import {
  HasPermission,
  BaseChart,
  LineChart,
  NoData,
  SelectComponent,
} from '@shared/components'
import { useLocations, useReportFilters } from '@shared/hooks'
import {
  ChartEntity,
  Filter,
  SettledResponse,
  PaymentMethodType,
  EnumServiceName,
} from '@shared/types'
import { currency } from '@shared/utils'

import mapChart from './utils/mapChart'

const useStyles = tss.withName('SettledTransactionsChart').create(() => ({
  tooltip: {
    padding: '0.5rem',
    backgroundColor: '#4B5563',
    color: '#F3F4F6',
    fontSize: '0.75rem',
    fontFamily: 'Inter',
  },
  p: {
    margin: 0,
    padding: 0,
  },
  loadingContainer: {
    display: 'grid',
    height: '100%',
    placeContent: 'center',
  },
  selectorContainer: {
    textAlign: 'left',
    padding: '10 10',
    position: 'relative',
    display: 'grid',
    placeContent: 'end',
  },
  buttonsContainer: {
    display: 'flex',
    gap: '8px',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
}))

const filters: Filter[] = [
  {
    id: 'last30days',
    name: 'Last 30 days',
    skipTicks: 4,
  },
  {
    id: 'last12months',
    name: 'Last 12 months',
    skipTicks: 1,
  },
]

const entities: ChartEntity[] = [{ id: 'sale', name: 'Sales', code: 101 }]

const colorsByChartEntity: Record<string, string> = {
  sale: '#4CB48E',
}

export function SettledTransactionsChart(props: {
  merchantAccounts?: MerchantAccount[]
  selectedMerchantAccounts?: MerchantAccount[]
  merchantAccountId: string
  locationId: string
}) {
  const { classes } = useStyles()
  const { clearFilter } = useReportFilters()
  const {
    selectedLocation,
    hasMerchantAccountACHActive,
    hasMerchantAccountCCActive,
  } = useLocations()

  const { t } = useTranslation()
  const navigate = useNavigate()
  const [data, setData] = useState({ cc: [], ach: [] })

  const [transactionType, setTransactionType] = useState(PaymentMethodType.CC)
  const [showToggle, setShowToggle] = useState<boolean>(true)
  const [disableToggle, setDisableToggle] = useState<boolean>(false)
  const [filterType, setFilterType] = useState('last30days')

  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)

  const formatX = (filter: string, date: number) => {
    if (filter === 'last30days') {
      return DateTime.fromMillis(date).toFormat('MM/dd')
    }
    if (filter === 'last12months') {
      return DateTime.fromMillis(date).toFormat('MMM')
    }
  }

  const checkTransactions = () => {
    if (lineTransactions?.transactions === 0) {
      return false
    } else {
      return true
    }
  }

  useEffect(() => {
    const fetchData = async () => {
      if (
        transactionType === PaymentMethodType.CC &&
        !hasMerchantAccountCCActive
      ) {
        return
      }

      if (
        transactionType === PaymentMethodType.ACH &&
        !hasMerchantAccountACHActive
      ) {
        return
      }

      setIsLoading(true)
      setIsError(false)

      try {
        const fetchDataFunction =
          transactionType === PaymentMethodType.CC
            ? 'settled-transactions-cc'
            : 'settled-transactions-ach'
        const response = (await api.service(fetchDataFunction).find({
          query: {
            filter: {
              location_id: props.locationId,
              product_transaction_id: props.merchantAccountId,
            },
          },
        })) as unknown as SettledResponse[]

        setData((prevData) => ({
          ...prevData,
          [transactionType]: mapChart(
            filters,
            response.length ? response : [],
            entities,
            formatX
          ),
        }))
      } catch (error) {
        console.error(error)
        setIsLoading(false)
        setIsError(true)
      } finally {
        setIsLoading(false)
      }
    }

    if (selectedLocation) {
      fetchData()
    }
  }, [transactionType, selectedLocation, props.merchantAccountId])

  useEffect(() => {
    if (!hasMerchantAccountCCActive || !hasMerchantAccountACHActive) {
      setShowToggle(false)
      setTransactionType(
        hasMerchantAccountCCActive
          ? PaymentMethodType.CC
          : PaymentMethodType.ACH
      )
      return
    }

    const selectedCcMerchantAccount = props.selectedMerchantAccounts?.some(
      (merchantAccount) =>
        merchantAccount.payment_method === PaymentMethodType.CC
    )
    const selectedAchMerchantAccount = props.selectedMerchantAccounts?.some(
      (merchantAccount) =>
        merchantAccount.payment_method === PaymentMethodType.ACH
    )

    if (
      (selectedCcMerchantAccount && !selectedAchMerchantAccount) ||
      (!selectedCcMerchantAccount && selectedAchMerchantAccount)
    ) {
      setDisableToggle(true)

      setTransactionType(
        selectedCcMerchantAccount ? PaymentMethodType.CC : PaymentMethodType.ACH
      )
      return
    }

    setDisableToggle(false)
    setShowToggle(true)
  }, [selectedLocation, props.merchantAccounts, props.selectedMerchantAccounts])

  const lineTransactions = data[transactionType][filterType]

  const handleChange = (event: SelectChangeEvent) => {
    setFilterType(event.target.value as string)
  }
  const getColorByChartEntity = (chartEntity: { id: string }): string => {
    return colorsByChartEntity[chartEntity.id]
  }

  const settledTransactionsChartLegend = (options: Datum) => {
    return options.map((option) => {
      const record: Datum = {
        id: option.id,
        label: option.name,
        color: colorsByChartEntity[option.id],
      }
      return record
    })
  }

  const selector = (
    <>
      <ToggleButtonGroup
        value={transactionType}
        exclusive
        disabled={disableToggle}
        onChange={(_, value) => {
          if (value !== null) {
            setTransactionType(value)
          }
        }}
        aria-label={t('common.toggle-cc-ach')}
        sx={{
          height: '2rem',
        }}
      >
        <ToggleButton
          value="cc"
          data-guiding-id="mp-dashboard-settledtransactions-cc"
        >
          CC
        </ToggleButton>
        <ToggleButton
          value="ach"
          data-guiding-id="mp-dashboard-settledtransactions-ach"
        >
          ACH
        </ToggleButton>
      </ToggleButtonGroup>
    </>
  )

  const selectorType = (
    <div className={classes.selectorContainer}>
      <SelectComponent
        placeholder={t('common.date-range-select')}
        options={[
          {
            label: t('common.filter-last-30-days'),
            value: 'last30days',
          },
          {
            label: t('common.filter-last-12-months'),
            value: 'last12months',
          },
        ]}
        value={filterType}
        onChange={handleChange}
        style={{ minWidth: '150px', height: '32px' }}
        guidingId="mp-dashboard-settledtransactions-filter"
      />
    </div>
  )

  const transactionsInfo = (
    <Grid container spacing={2} justifyContent="flex-start" alignItems="center">
      <Grid item xs={10} md={6}>
        <div>
          <p className={classes.p}>{t('common.total-transactions')}</p>
          <p className={classes.p} style={{ fontWeight: 'bold' }}>
            {lineTransactions ? lineTransactions['transactions'] : 0}
          </p>
        </div>
      </Grid>
      <Grid item xs={10} md={6}>
        <div>
          <p className={classes.p}>{t('common.total-amount')}</p>
          <p className={classes.p} style={{ fontWeight: 'bold' }}>
            {currency(lineTransactions ? lineTransactions['amount'] : 0)}
          </p>
        </div>
      </Grid>
    </Grid>
  )

  const navigateToTransactions = (pointData) => {
    if (!pointData || !pointData.data) return

    const dateClicked = pointData.data.date

    const clickedDateStart = Math.floor(
      DateTime.fromSeconds(dateClicked).startOf('day').toSeconds()
    )

    const clickedDateEnd = Math.floor(
      DateTime.fromSeconds(dateClicked).endOf('day').toSeconds()
    )

    clearFilter(
      transactionType === PaymentMethodType.CC
        ? EnumServiceName.SettledTransactionsReportsCc
        : EnumServiceName.SettledTransactionsReportsAch
    )

    const url =
      transactionType === PaymentMethodType.CC
        ? `/merchant/reports/settled-transactions-cc?page[number]=1&page[size]=15&filter[transaction_date_ts][$gte]=${clickedDateStart}&filter[transaction_date_ts][$lte]=${clickedDateEnd}&sort[transaction_date_ts]=desc`
        : `/merchant/reports/settled-transactions-ach?page[number]=1&page[size]=15&filter[date_settled_ts][$gte]=${clickedDateStart}&filter[date_settled_ts][$lte]=${clickedDateEnd}&sort[date_settled_ts]=desc`

    navigate(url)
  }

  return (
    <BaseChart
      title={t('common.settled-transactions')}
      extraButton={
        <Box className={classes.buttonsContainer}>
          {showToggle ? selector : null}
          {selectorType}
        </Box>
      }
      transactionsInfo={transactionsInfo}
      applyBorder={false}
      guidingId="mp-dashboard-settledtransactions"
    >
      <HasPermission
        permission="v2.transactions.get"
        unauthorizedComponent={
          <NoData
            title={t('common.validations.not-available-feature')}
            label={t('common.validations.please-contact-support')}
            icon="error"
          />
        }
      >
        {!isLoading && !!lineTransactions && checkTransactions() ? (
          <LineChart
            data={lineTransactions ? lineTransactions.data : []}
            margin={{ top: 30, bottom: 65, right: 15, left: 75 }}
            colors={getColorByChartEntity}
            legends={[
              {
                data: settledTransactionsChartLegend(entities),
                itemTextColor: '#000',
                anchor: 'top-left',
                direction: 'row',
                translateX: -22,
                translateY: -25,
                itemsSpacing: 20,
                itemDirection: 'left-to-right',
                itemWidth: 90,
                itemHeight: 20,
                symbolShape: 'circle',
                symbolSize: 10,
              },
            ]}
            skipTicks={filters.find((f) => f.id === filterType)?.skipTicks}
            tooltip={({ point }) => {
              const { data } = point
              return (
                <Paper elevation={3} className={classes.tooltip}>
                  <Grid container direction={'column'}>
                    <Grid item>
                      <span>{data.xFormatted}</span>
                    </Grid>
                    <Grid item>
                      <span>
                        {t('common.transactions')}: {data['transactions']}
                      </span>
                    </Grid>
                    <Grid item>
                      <span>
                        {t('common.amount')}:{' '}
                        {currency(Number(point.data.yFormatted))}
                      </span>
                    </Grid>
                  </Grid>
                </Paper>
              )
            }}
            onClick={(point) => {
              navigateToTransactions(point)
            }}
          />
        ) : (
          <Box className={classes.loadingContainer}>
            {isLoading ? (
              <CircularProgress data-testid="loading-icon" size={45} />
            ) : isError ? (
              <NoData
                title={t('common.validations.loading-data')}
                label={t('common.validations.loading-data-explainer')}
                icon="error"
              />
            ) : (
              <NoData
                title={t('common.validations.not-available-data')}
                label={t('common.validations.not-available-data-display')}
              />
            )}
          </Box>
        )}
      </HasPermission>
    </BaseChart>
  )
}
