import React from 'react'
import { tss } from 'tss-react/mui'

import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'

import appTheme from '@shared/design'

import Statement from './Statement'

const useStyles = tss.withName('StatementsColumn').create(({ theme }) => ({
  paper: {
    width: '100%',
    border: `2px solid ${theme.palette.grey[200]}`,
    borderRadius: theme.shape.borderRadius,
    marginTop: theme.spacing(2),
    padding: theme.spacing(2),
  },
  heading: {
    marginLeft: theme.spacing(2),
    fontSize: theme.typography.pxToRem(20),
    fontWeight: theme.typography.fontWeightMedium,
    marginBottom: theme.spacing(2),
    cursor: 'default',
  },
}))

const StatementsColumn = ({ year, statements, onDownload }) => {
  const { classes } = useStyles(appTheme)

  return (
    <Paper className={classes.paper}>
      <Typography variant="h2" className={classes.heading}>
        {year}
      </Typography>
      {statements.map((statement, index) => (
        <Statement
          key={statement.document_id}
          statement={statement}
          onDownload={onDownload}
          guidingId={`merchant-statements-${year}-${index}`}
        />
      ))}
    </Paper>
  )
}

export default StatementsColumn
