import { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { tss } from 'tss-react/mui'

import { Box, Typography } from '@mui/material'

import { api, UserPref, UserPrefSubscribe } from '@shared/api/src'
import { ActionModal, Checkbox } from '@shared/components'
import { useEnforceLogin, useAuthorization, useLocations } from '@shared/hooks'
import { checkPermission, arraysContainSameElements } from '@shared/utils'

import NotificationList from '../../components/notification-list/NotificationList'
import { updateMyNotificationSubscriptions } from '../../utils/updateNotificationSubscription'

export interface EditMyNotificationsProps<T> {
  allPrefs: UserPref[]
  subscribedPrefs: UserPrefSubscribe[]
  open?: boolean
  handleClose: (event: any) => void
}

const useStyles = tss.withName('EditMyNotifications').create(({ theme }) => ({
  modal: {
    width: '40%',
  },
  description: {
    fontFamily: 'Inter',
    fontSize: '14px',
    fontWeight: '400',
    lineHeight: '20px',
    color: theme.palette['neutral-600'],
    marginBottom: '24px',
  },
  checkAll: {
    marginTop: '20px',
    marginBottom: '20px',
    display: 'flex',
    justifyContent: 'flex-end',
    backgroundColor: theme.palette['neutral-100'],
    padding: '8px 0',
  },
}))

export default function EditMyNotifications({
  allPrefs,
  subscribedPrefs,
  open,
  handleClose,
}: EditMyNotificationsProps<any>) {
  const { t } = useTranslation()
  const { classes } = useStyles()
  const { updateUser, user } = useEnforceLogin()
  const { selectedLocation } = useLocations()
  const { userPermissionSet } = useAuthorization()
  const navigate = useNavigate()

  const [isSaving, setIsSaving] = useState(false)
  const [selectedPrefsState, setSelectedPrefsState] = useState<any>(
    subscribedPrefs.map((p) => ({ id: p?.user_pref_id, type: 'email' }))
  )
  const [selectAllEmail, setSelectAllEmail] = useState(
    subscribedPrefs.length === allPrefs.length
  )

  const canCreate = checkPermission(userPermissionSet, 'v2.useruserprefs.post')
  const canDelete = checkPermission(
    userPermissionSet,
    'v2.useruserprefs.delete'
  )

  const saveMyNotifications = async () => {
    setIsSaving(true)

    try {
      const subs = await selectedPrefsState.map((pref) => pref.id)
      await updateMyNotificationSubscriptions(
        selectedLocation.id,
        user.id,
        subscribedPrefs,
        subs ?? []
      )
      const updatedUser = await api.service('users').get(user.id)
      await updateUser(updatedUser)

      setIsSaving(false)
      navigate('/merchant/settings/notifications')
      handleClose(true)
    } catch (error) {
      handleClose(true)
      setIsSaving(false)
    }
  }

  useEffect(() => {
    const allPrefsIds = allPrefs.map((p) => {
      return {
        id: p.id,
        type: 'email',
      }
    })

    arraysContainSameElements(selectedPrefsState, allPrefsIds)
      ? setSelectAllEmail(true)
      : setSelectAllEmail(false)
  }, [selectedPrefsState])

  return (
    <ActionModal
      open={open}
      title={t('merchant-portal.notifications-settings.edit-my-notifications')}
      buttons={[
        {
          label: t('common.cancel'),
          color: 'secondary',
          onClick: () => handleClose(true),
        },
        {
          label: t('common.save-changes'),
          onClick: saveMyNotifications,
          isLoading: isSaving,
          color: 'primary',
        },
      ]}
      onClose={() => handleClose(false)}
      className={classes.modal}
      isScrollableContent
    >
      <Typography className={classes.description}>
        {t(
          'merchant-portal.notifications-settings.the-notifications-listed-on-this-page-are-specific-to-location-only'
        )}
      </Typography>

      <Box>
        {canCreate && canDelete && (
          <div className={classes.checkAll}>
            <Checkbox
              checked={selectAllEmail}
              label={t('merchant-portal.notifications-settings.all-email')}
              onChange={(event: React.ChangeEvent) => {
                setSelectAllEmail(!selectAllEmail)
                const allPrefsIds = allPrefs.map((p) => ({
                  id: p.id,
                  type: 'email',
                }))

                if (
                  arraysContainSameElements(selectedPrefsState, allPrefsIds)
                ) {
                  setSelectedPrefsState([])
                } else {
                  setSelectedPrefsState(allPrefsIds)
                }
              }}
            />
          </div>
        )}
        <NotificationList
          prefs={allPrefs}
          subscriptions={subscribedPrefs.map((p) => p?.user_pref_id)}
          isEdit={true}
          onChange={(value: string, type: string) => {
            const existingIndex = selectedPrefsState.findIndex(
              (pref) => pref.id === value && pref.type === type
            )
            const updatedPrefsState = [...selectedPrefsState]

            if (existingIndex !== -1) {
              updatedPrefsState.splice(existingIndex, 1)
            } else {
              updatedPrefsState.push({ id: value, type })
            }

            setSelectedPrefsState(updatedPrefsState)
          }}
          selectedPrefs={selectedPrefsState}
        />
      </Box>
    </ActionModal>
  )
}
