import { api } from '@shared/api'
import { Statement } from '@shared/types'
import { checkPermission, getDocumentPeriod } from '@shared/utils'

type StatementsRecord = Record<string, Statement[]>

export const getStatementsRecord = (
  statements: Statement[]
): StatementsRecord => {
  const statementsRecord: StatementsRecord = {}

  statements.forEach((statement) => {
    const year = parseInt(getDocumentPeriod(statement).toString().slice(0, 4))
    if (!statementsRecord[year]) statementsRecord[year] = []

    statementsRecord[year].push(statement)
  })

  Object.keys(statementsRecord).forEach((year) => {
    statementsRecord[year].sort((a, b) => {
      return getDocumentPeriod(b) - getDocumentPeriod(a)
    })
  })

  return statementsRecord
}

export const getStatements = async (selectedMerchantAccount) => {
  if (!selectedMerchantAccount) return

  try {
    const { list: statements } = await api
      .service('statements')
      .list(selectedMerchantAccount.id)
    const statementsRecord = getStatementsRecord(statements)

    return statementsRecord
  } catch (error) {
    return error
  }
}

export const getReportDownloadUrl = async (
  documentId: string,
  selectedMerchantAccount,
  userPermissionSet
) => {
  if (
    !selectedMerchantAccount ||
    !checkPermission(userPermissionSet, 'v2.files.get')
  )
    return

  try {
    const res = await api
      .service('statements')
      .download(selectedMerchantAccount.id, documentId)

    if (res.url) {
      return res.url
    }
  } catch (error) {
    console.error('Error downloading statement', error)
  }
}
