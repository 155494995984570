import { DateTime } from 'luxon'

export function getFiltersForUserTimezone(tz: string) {
  const dateToUserTimezone = (dateTime: DateTime): DateTime =>
    dateTime.setZone(tz)

  const now = DateTime.now()
  const filters = {
    today: {
      $gte: Math.floor(
        dateToUserTimezone(now.startOf('day')).toMillis() / 1000
      ),
      $lte: Math.floor(dateToUserTimezone(now.endOf('day')).toMillis() / 1000),
    },
    yesterday: {
      $gte: Math.floor(
        dateToUserTimezone(now.minus({ days: 1 }).startOf('day')).toMillis() /
          1000
      ),
      $lte: Math.floor(
        dateToUserTimezone(now.minus({ days: 1 }).endOf('day')).toMillis() /
          1000
      ),
    },
    last7days: {
      $gte: Math.floor(
        dateToUserTimezone(now.minus({ days: 7 }).startOf('day')).toMillis() /
          1000
      ),
      $lte: Math.floor(dateToUserTimezone(now.endOf('day')).toMillis() / 1000),
    },
    received_last7days: {
      $gte: Math.floor(
        dateToUserTimezone(now.minus({ days: 7 }).startOf('day')).toMillis() /
          1000
      ),
      $lte: Math.floor(dateToUserTimezone(now.endOf('day')).toMillis() / 1000),
    },
    open: {
      $gte: Math.floor(
        dateToUserTimezone(now.minus({ days: 90 }).startOf('day')).toMillis() /
          1000
      ),
      $lte: Math.floor(dateToUserTimezone(now.endOf('day')).toMillis() / 1000),
    },
  }

  return filters
}
