import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { LocationInfo } from '@shared/api/src'
import {
  FieldGroupContainer,
  FieldGroupRow,
  FieldGroupViewItem,
} from '@shared/components'
import { countryCodeToCountryName } from '@shared/utils'

interface GeneralSettingsProps {
  locationInfo: LocationInfo
}

export const GeneralSettings: React.FC<GeneralSettingsProps> = ({
  locationInfo,
}) => {
  const { t } = useTranslation()

  const defaultCC = useMemo(
    () =>
      locationInfo?.product_transactions?.find(
        (l) => l.id === locationInfo.default_cc
      ),
    [locationInfo]
  )

  const defaultACH = useMemo(
    () =>
      locationInfo?.product_transactions?.find(
        (l) => l.id === locationInfo.default_ach
      ),
    [locationInfo]
  )

  const valueLocationName = locationInfo?.name
  const valueLocationParentName = (locationInfo?.parent as { name: string })
    ?.name
  const valueLocationDefaultCC = defaultCC?.title
  const valueLocationDefaultACH = defaultACH?.title
  const valueTZ = locationInfo?.tz
  const valueLocationStreet = locationInfo?.address?.street1 as string
  const valueLocationStreet2 = locationInfo?.address?.street2
  const valueLocationCity = locationInfo?.address?.city
  const valueLocationState = locationInfo?.address?.state
  const valueLocationPostalCode = locationInfo?.address?.postal_code
  const valueLocationCountry = countryCodeToCountryName(
    locationInfo?.address?.country
  )

  return (
    <FieldGroupContainer title={t('common.general-settings')}>
      <FieldGroupRow>
        <FieldGroupViewItem
          title={t('merchant-portal.location.location-name')}
          value={valueLocationName}
        />
        <FieldGroupViewItem
          title={t('merchant-portal.location.parent-location')}
          value={valueLocationParentName}
        />
      </FieldGroupRow>

      <FieldGroupRow>
        <FieldGroupViewItem
          title={t('merchant-portal.location.default-merchant-account-cc')}
          value={valueLocationDefaultCC}
        />
        <FieldGroupViewItem
          title={t('merchant-portal.location.default-merchant-account-ach')}
          value={valueLocationDefaultACH}
        />
      </FieldGroupRow>

      <FieldGroupRow>
        <FieldGroupViewItem
          title={t('common.address')}
          value={valueLocationStreet}
        />
        <FieldGroupViewItem
          title={t('common.address2')}
          value={valueLocationStreet2}
        />
      </FieldGroupRow>

      <FieldGroupRow>
        <FieldGroupViewItem
          title={t('common.city')}
          value={valueLocationCity}
        />
        <FieldGroupViewItem
          title={t('common.state')}
          value={valueLocationState}
        />
      </FieldGroupRow>

      <FieldGroupRow>
        <FieldGroupViewItem
          title={t('common.postalcode')}
          value={valueLocationPostalCode}
        />
        <FieldGroupViewItem
          title={t('common.country')}
          value={valueLocationCountry}
        />
      </FieldGroupRow>

      <FieldGroupRow>
        <FieldGroupViewItem title={t('common.timezone')} value={valueTZ} />
      </FieldGroupRow>
    </FieldGroupContainer>
  )
}
