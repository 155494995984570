import { ColDef } from 'ag-grid-community'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { AppBar, Box } from '@mui/material'
import { SelectChangeEvent } from '@mui/material/Select'

import DateRangeFilter from '@shared/ag-grid/pagination-table/filters/date-range-filter/DateRangeFilter'
import PaginationTable from '@shared/ag-grid/pagination-table/PaginationTable'
import {
  Transaction,
  MerchantAccount,
  Batch,
} from '@shared/api/src/schemas/types'
import {
  SelectComponent,
  SelectOption,
  HasPermission,
  UserNotAllowed,
  ButtonBar,
  ButtonBarEnd,
} from '@shared/components'
import { useEnforceLogin, useFtpPortalHubCommunication } from '@shared/hooks'
import { useLocations } from '@shared/hooks/useLocations'
import { filterBatchTransactionStatusValues } from '@shared/mapping/gateway-transactions'
import { PaymentMethodType, EnumServiceName } from '@shared/types'
import {
  filterParams,
  sortMerchantAccounts,
  currency,
  DataSource,
  formatDatetime,
} from '@shared/utils'

import BatchStatus from '@/components/batch-status/BatchStatus'

export default function Batches() {
  //TODO: add translation when email receipt or other functionality is added
  const { t } = useTranslation()
  const { user } = useEnforceLogin()
  const { allMerchantAccounts, selectedLocation } = useLocations()
  const navigate = useNavigate()

  const { setAppBarTitle } = useFtpPortalHubCommunication()
  const [ccMerchantAccounts, setCCMerchantAccounts] = useState<
    MerchantAccount[]
  >([])
  const [selectedMerchantAccount, setSelectedMerchantAccount] =
    useState<MerchantAccount | null>(null)

  const TransactionsReportPrivs = [
    'v2.reports.get',
    'v2.transactionbatches.get',
  ]

  const extraParamsToExport = {
    product_transaction_id: selectedMerchantAccount?.id,
  }

  useEffect(() => {
    setAppBarTitle(t('merchant-portal.batches'), null, [
      t('common.reporting'),
      t('merchant-portal.gateway-reports'),
    ])
  }, [])

  useEffect(() => {
    if (!allMerchantAccounts) return

    const ccMerchantAccounts = sortMerchantAccounts(
      allMerchantAccounts.filter(
        ({ payment_method }) => payment_method === PaymentMethodType.CC
      )
    )

    const defaultCCMerchantAccount =
      ccMerchantAccounts.find(({ id }) => {
        return id === selectedLocation.default_cc
      }) ?? null

    setCCMerchantAccounts(ccMerchantAccounts)
    setSelectedMerchantAccount(defaultCCMerchantAccount)
  }, [allMerchantAccounts])

  const datasource = useMemo(() => {
    return new DataSource('batches', {
      fixedFilters: {
        product_transaction_id: selectedMerchantAccount?.id,
      },
      filterVariant: 'filter',
    })
  }, [selectedMerchantAccount])

  const columnDefs: ColDef<Batch>[] = useMemo(
    () => [
      {
        headerName: t('common.date-open'),
        field: 'created_ts',
        floatingFilter: true,
        filter: DateRangeFilter,
        valueGetter: (params) => {
          const timestamp = params.data?.created_ts
          return formatDatetime(timestamp, user?.tz)
        },
        filterParams: {
          type: 'past',
          showTimePicker: true,
        },
        sortable: true,
        sort: 'desc',
        sortIndex: 0,
      },
      {
        headerName: t('common.date-close'),
        field: 'batch_close_ts',
        floatingFilter: true,
        filter: DateRangeFilter,
        valueGetter: (params) => {
          const timestamp = params.data?.batch_close_ts
          return formatDatetime(timestamp, user?.tz) || '-'
        },
        filterParams: {
          type: 'past',
          showTimePicker: true,
        },
        sortable: true,
      },
      {
        headerName: t('common.total-amount-net'),
        field: 'total_net_amount',
        type: 'rightAligned',
        valueGetter: (params) => {
          return currency(
            (params.data?.total_sale_amount -
              params.data?.total_refund_amount) /
              100
          )
        },
      },
      {
        headerName: t('merchant-portal.total-amount-sale'),
        field: 'total_sale_amount',
        type: 'rightAligned',
        floatingFilter: true,
        filter: 'agNumberColumnFilter',
        filterParams: {
          allowedCharPattern: '\\d\\-\\.\\$\\,',
          numberParser: (value: number) => {
            return value ? value * 100 : null
          },
        },
        sortable: true,
        valueGetter: (params) => currency(params.data?.total_sale_amount / 100),
      },
      {
        headerName: t('merchant-portal.total-count-sale'),
        field: 'total_sale_count',
        floatingFilter: true,
        filter: 'agNumberColumnFilter',
        sortable: true,
      },
      {
        headerName: t('merchant-portal.total-amount-refund'),
        field: 'total_refund_amount',
        type: 'rightAligned',
        floatingFilter: true,
        filter: 'agNumberColumnFilter',
        filterParams: {
          allowedCharPattern: '\\d\\-\\.\\$\\,',
          numberParser: (value: number) => {
            return value ? value * 100 : null
          },
        },
        sortable: true,
        valueGetter: (params) =>
          currency(params.data?.total_refund_amount / 100),
      },
      {
        headerName: t('merchant-portal.total-count-refund'),
        field: 'total_refund_count',
        floatingFilter: true,
        filter: 'agNumberColumnFilter',
        sortable: true,
      },
      {
        headerName: t('merchant-portal.total-amount-refund-blind'),
        field: 'total_blind_refund_amount',
        type: 'rightAligned',
        floatingFilter: true,
        filter: 'agNumberColumnFilter',
        filterParams: {
          allowedCharPattern: '\\d\\-\\.\\$\\,',
          numberParser: (value: number) => {
            return value ? value * 100 : null
          },
        },
        sortable: true,
        valueGetter: (params) =>
          currency(params.data?.total_blind_refund_amount / 100),
      },
      {
        headerName: t('merchant-portal.total-count-refund-blind'),
        field: 'total_blind_refund_count',
        floatingFilter: true,
        filter: 'agNumberColumnFilter',
        sortable: true,
      },
      {
        headerName: t('merchant-portal.total-amount-void'),
        field: 'total_void_amount',
        floatingFilter: true,
        type: 'rightAligned',
        filter: 'agNumberColumnFilter',
        filterParams: {
          allowedCharPattern: '\\d\\-\\.\\$\\,',
          numberParser: (value: number) => {
            return value ? value * 100 : null
          },
        },
        sortable: true,
        valueGetter: (params) => currency(params.data?.total_void_amount / 100),
      },
      {
        headerName: t('merchant-portal.total-count-void'),
        field: 'total_void_count',
        floatingFilter: true,
        filter: 'agNumberColumnFilter',
        sortable: true,
      },
      {
        headerName: t('merchant-portal.batch-number'),
        field: 'batch_num',
        floatingFilter: true,
        filter: 'agTextColumnFilter',
        sortable: true,
      },
      {
        headerName: t('common.status'),
        field: 'processing_status_id',
        floatingFilter: true,
        filter: 'agSetColumnFilter',
        filterParams: {
          values: filterBatchTransactionStatusValues.map(
            (option) => option.value
          ),
          valueFormatter: (params) => {
            const option = filterBatchTransactionStatusValues.find(
              (option) => option.value === params.value
            )
            return option ? option.label : params.value
          },
          comparator: (a, b) => {
            const optionA = filterBatchTransactionStatusValues.find(
              (option) => option.value === a
            )
            const optionB = filterBatchTransactionStatusValues.find(
              (option) => option.value === b
            )

            return optionA?.label.localeCompare(optionB?.label)
          },
        },
        cellRenderer: (data) => <BatchStatus statusCode={data.value} />,
        sortable: true,
      },
    ],
    []
  )

  const defaultColDef = useMemo(
    () => ({
      resizable: true,
    }),
    []
  )

  const getMerchantAccountsSelectOptions = (
    merchantAccounts: MerchantAccount[]
  ): SelectOption<MerchantAccount>[] =>
    sortMerchantAccounts(merchantAccounts).map<SelectOption<MerchantAccount>>(
      (merchantAccount) => ({
        label: merchantAccount.title,
        value: merchantAccount.id,
      })
    )

  const ccMerchantAccountsOptions =
    getMerchantAccountsSelectOptions(ccMerchantAccounts)

  const handleChange = (event: SelectChangeEvent) => {
    setSelectedMerchantAccount(
      ccMerchantAccounts.find(({ id }) => id === event.target.value)
    )
  }

  const handleRowClick = useCallback(
    (event) => {
      const rowData = event.data
      const id = rowData.id
      navigate(`/merchant/reports/gateway/batches/${id}/details`, {
        state: {
          merchantAccountID: selectedMerchantAccount?.id,
        },
      })
    },
    [selectedMerchantAccount]
  )

  return (
    <HasPermission
      allPermissions={TransactionsReportPrivs}
      unauthorizedComponent={<UserNotAllowed />}
    >
      <>
        {ccMerchantAccountsOptions.length > 1 && (
          <ButtonBar>
            <ButtonBarEnd>
              <SelectComponent
                placeholder={t('common.merchant-account-filter')}
                options={ccMerchantAccountsOptions}
                value={selectedMerchantAccount?.id}
                onChange={handleChange}
                style={{
                  width: '300px',
                }}
              />
            </ButtonBarEnd>
          </ButtonBar>
        )}
        <PaginationTable<Transaction>
          getRowId={(data) => data.data.id}
          columnDefs={columnDefs}
          rowModelType={'serverSide'}
          serverSideDatasource={datasource}
          onRowClicked={handleRowClick}
          defaultColDef={defaultColDef}
          showExportButton={true}
          showClearFiltersButton={true}
          serviceName={EnumServiceName.Batches}
          extraParamsToExport={extraParamsToExport}
          guidingId="reports-gateway-batches"
        />
      </>
    </HasPermission>
  )
}
