import { useTranslation } from 'react-i18next'
import { tss } from 'tss-react'

import { Grid, Typography } from '@mui/material'

import { MerchantAccount } from '@shared/api'
import { PageLayoutDivider } from '@shared/components'
import { PaymentMethod, PaymentMethodType } from '@shared/types'

import MerchantAccountCard from '../merchant-account-card/MerchantAccountCard'
import MerchantAccountCashToggle from '../merchant-account-cash-toggle/MerchantAccountCashToggle'

interface MerchantAccountsSectionProps {
  merchantAccounts: MerchantAccount[]
  paymentMethod: PaymentMethod
  openDeactivateAccount: (merchantAccount: MerchantAccount) => void
}

const useStyles = tss.withName('MerchantAccountSection').create({
  container: {
    padding: '0px 24px',
    backgroundColor: 'white',
    width: '100%',
    marginBottom: '16px',
    border: 'none',
  },
  title: {
    fontSize: '1.25rem',
    fontWeight: 500,
    lineHeight: '28px',
    padding: '24px 0px',
  },
})

const Section = ({
  merchantAccounts,
  paymentMethod,
  openDeactivateAccount,
}: MerchantAccountsSectionProps) => {
  const { t } = useTranslation()
  const { classes } = useStyles()

  const getTitle = (paymentMethod: PaymentMethod) => {
    switch (paymentMethod) {
      case PaymentMethodType.CASH:
        return t('merchant-portal.merchant-account-settings.cash')
      case PaymentMethodType.ACH:
        return 'ACH'
      case PaymentMethodType.CC:
        return t('common.credit-card')
      default:
        return ''
    }
  }

  if (!merchantAccounts.length) return null

  return (
    <Grid container className={classes.container}>
      <Grid item xs={12}>
        <Typography className={classes.title}>
          {getTitle(paymentMethod)}
        </Typography>
      </Grid>

      {merchantAccounts.map((merchantAccount, index) => (
        <Grid key={index} item xs={12}>
          {paymentMethod === PaymentMethodType.CASH ? (
            <MerchantAccountCashToggle merchantAccount={merchantAccount} />
          ) : (
            <MerchantAccountCard
              merchantAccount={merchantAccount}
              openDeactivateAccount={openDeactivateAccount}
            />
          )}

          {index !== merchantAccounts.length - 1 && <PageLayoutDivider />}
        </Grid>
      ))}
    </Grid>
  )
}

export default Section
