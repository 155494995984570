import React, { useState, useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { tss } from 'tss-react/mui'

import { Box, Typography } from '@mui/material'

import { api, MerchantAccount } from '@shared/api/src'
import { ActionModal, Checkbox } from '@shared/components'
import { useNotification } from '@shared/hooks'
import { toArrayFieldErrors } from '@shared/utils'

const useStyles = tss.withName('EditFraudSettings').create(({ theme }) => ({
  modal: {
    width: '600px',
    maxWidth: '100%',
    overflow: 'hidden',
  },
  sectionTitle: {
    display: 'flex',
    color: theme.palette['neutral-900'],
    fontFamily: 'Inter',
    fontSize: '16px',
    fontWeight: '600',
    marginBottom: '16px',
  },
  divider: {
    width: '100%',
    height: '1px',
    backgroundColor: theme.palette['neutral-300'],
    margin: '24px 0',
  },
}))

interface EditFraudSettingsProps {
  open: boolean
  handleClose: (event: React.MouseEvent<HTMLElement>) => void
  merchantAccount: MerchantAccount
  onSuccess: () => void
}

export default function EditFraudSettings({
  open,
  handleClose,
  merchantAccount,
  onSuccess,
}: EditFraudSettingsProps) {
  const { classes } = useStyles()
  const { t } = useTranslation()
  const { setNotification } = useNotification()

  const [initialAutoDeclineSettings] = useState({
    cvv: merchantAccount?.auto_decline_cvv || false,
    street: merchantAccount?.auto_decline_street || false,
    zip: merchantAccount?.auto_decline_zip || false,
  })

  const [initialOtherSettings] = useState({
    allowBlindRefund: merchantAccount?.allow_blind_refund || false,
  })

  const [autoDeclineSettings, setAutoDeclineSettings] = useState(
    initialAutoDeclineSettings
  )
  const [otherSettings, setOtherSettings] = useState(initialOtherSettings)

  const [isLoading, setIsLoading] = useState<boolean>(false)

  const handleAutoDeclineChange = useCallback(
    (setting: keyof typeof autoDeclineSettings, value: boolean) => {
      setAutoDeclineSettings((prev) => ({ ...prev, [setting]: value }))
    },
    []
  )

  const handleOtherSettingsChange = useCallback(
    (setting: keyof typeof otherSettings, value: boolean) => {
      setOtherSettings((prev) => ({ ...prev, [setting]: value }))
    },
    []
  )

  const hasChanges = useCallback(() => {
    const autoDeclineChanged =
      autoDeclineSettings.cvv !== initialAutoDeclineSettings.cvv ||
      autoDeclineSettings.street !== initialAutoDeclineSettings.street ||
      autoDeclineSettings.zip !== initialAutoDeclineSettings.zip

    const otherSettingsChanged =
      otherSettings.allowBlindRefund !== initialOtherSettings.allowBlindRefund

    return autoDeclineChanged || otherSettingsChanged
  }, [
    autoDeclineSettings,
    otherSettings,
    initialAutoDeclineSettings,
    initialOtherSettings,
  ])

  const handleSave = useCallback(async () => {
    setIsLoading(true)
    const isElavonSubprocess = merchantAccount.title
      .toLowerCase()
      .includes('elavon')

    try {
      await api.service('merchant-accounts').patch(merchantAccount.id, {
        auto_decline_cvv: autoDeclineSettings.cvv,
        auto_decline_street: autoDeclineSettings.street,
        auto_decline_zip: autoDeclineSettings.zip,
        allow_blind_refund: otherSettings.allowBlindRefund,
        merchant_id: isElavonSubprocess ? merchantAccount.id : undefined,
        paylink_allow: Boolean(merchantAccount.paylink_allow),
        hosted_payment_page_allow: Boolean(
          merchantAccount.hosted_payment_page_allow
        ),
      })
      setIsLoading(false)
      setNotification({
        type: 'success',
        label: t(
          'merchant-portal.merchant-account-settings.fraud-settings-successfully-updated'
        ),
      })

      handleClose(null)
      onSuccess()
    } catch (error) {
      const errors = toArrayFieldErrors(error)
      const messages = errors.map((error) => error.message)
      setIsLoading(false)
      setNotification({
        type: 'error',
        label: messages.join(' | '),
      })
    }
  }, [
    autoDeclineSettings,
    otherSettings,
    merchantAccount,
    handleClose,
    setNotification,
    t,
  ])

  return (
    <ActionModal
      open={open}
      title={t('merchant-portal.merchant-account-settings.fraud-settings')}
      onClose={handleClose}
      buttons={[
        {
          label: t('common.cancel'),
          color: 'secondary',
          onClick: handleClose,
        },
        {
          label: t('common.save-changes'),
          onClick: handleSave,
          color: 'primary',
          isLoading: isLoading,
          disabled: !hasChanges(),
        },
      ]}
      className={classes.modal}
    >
      <Typography className={classes.sectionTitle}>
        {t(
          'merchant-portal.merchant-account-settings.fraud-settings.auto-decline-settings'
        )}
      </Typography>
      <Box mb={2}>
        <Checkbox
          label={t(
            'merchant-portal.merchant-account-settings.fraud-settings.auto-decline-cvv'
          )}
          description={t(
            'merchant-portal.merchant-account-settings.fraud-settings.auto-decline-cvv-description'
          )}
          checked={autoDeclineSettings.cvv}
          onChange={() =>
            handleAutoDeclineChange('cvv', !autoDeclineSettings.cvv)
          }
        />
      </Box>
      <Box mb={2}>
        <Checkbox
          label={t(
            'merchant-portal.merchant-account-settings.fraud-settings.auto-decline-street'
          )}
          description={t(
            'merchant-portal.merchant-account-settings.fraud-settings.auto-decline-street-description'
          )}
          checked={autoDeclineSettings.street}
          onChange={() =>
            handleAutoDeclineChange('street', !autoDeclineSettings.street)
          }
        />
      </Box>
      <Box mb={2}>
        <Checkbox
          label={t(
            'merchant-portal.merchant-account-settings.fraud-settings.auto-decline-postal-code'
          )}
          description={t(
            'merchant-portal.merchant-account-settings.fraud-settings.auto-decline-postal-code-description'
          )}
          checked={autoDeclineSettings.zip}
          onChange={() =>
            handleAutoDeclineChange('zip', !autoDeclineSettings.zip)
          }
        />
      </Box>
      <Box className={classes.divider} />

      <Typography className={classes.sectionTitle}>
        {t(
          'merchant-portal.merchant-account-settings.fraud-settings.other-settings'
        )}
      </Typography>
      <Box mb={2}>
        <Checkbox
          label={t(
            'merchant-portal.merchant-account-settings.fraud-settings.allow-blind-refunds'
          )}
          description={t(
            'merchant-portal.merchant-account-settings.fraud-settings.allow-blind-refunds-description'
          )}
          checked={otherSettings.allowBlindRefund}
          onChange={() =>
            handleOtherSettingsChange(
              'allowBlindRefund',
              !otherSettings.allowBlindRefund
            )
          }
        />
      </Box>
    </ActionModal>
  )
}
