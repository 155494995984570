import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { tss } from 'tss-react/mui'

import { Box, CircularProgress, Grid, Paper } from '@mui/material'

import { RecurringBillingDecline, api } from '@shared/api/src'
import { HasPermission, NoData } from '@shared/components'
import { useLocations } from '@shared/hooks'
import { EnumServiceName } from '@shared/types'

import { getFiltersForUserTimezone } from '../../utils/GetFiltersForUserTimezone'
import { InfoPanel } from '../../utils/InfoPanel'

const useStyles = tss.withName('RecurringBillingDeclinesChart').create({
  loadingContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
  },
  paperContainer: {
    backgroundColor: 'white',
    padding: '2rem',
    height: '240px',
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '1rem',
  },
  titleH2: {
    margin: 0,
    padding: 0,
  },
})

const RecurringBillingDeclines = ({
  locationId,
  merchantAccountId,
  tz,
}: {
  locationId: string
  merchantAccountId?: string
  tz?: string
}) => {
  const { classes } = useStyles()
  const { t } = useTranslation()
  const { hasRecurringActive } = useLocations()

  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const [recurringTransactions, setRecurringTransactions] = useState<
    RecurringBillingDecline[]
  >([])

  useEffect(() => {
    if (!locationId) return

    const fetchData = async () => {
      try {
        await fetchRecurringData()
      } catch (error) {
        setIsLoading(false)
        setIsError(true)
        console.error(error)
      }
    }
    fetchData()
  }, [locationId, merchantAccountId])

  const fetchRecurringData = async () => {
    if (!hasRecurringActive) return

    try {
      setIsLoading(true)
      const response = (await api.service('recurring-billing-declines').find({
        query: {
          filter: {
            location_id: locationId,
            product_transaction_id: merchantAccountId,
          },
        },
      })) as unknown as RecurringBillingDecline[]
      const list = response as RecurringBillingDecline[]
      await setRecurringTransactions(list.length ? list : [])
      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
      setIsError(true)
      console.error(error)
    }
  }

  const getTitleById = (id: string) => {
    switch (id) {
      case 'today':
        return t('common.filter-today')
      case 'yesterday':
        return t('common.filter-yesterday')
      case 'last7days':
        return t('common.filter-last-7-days')
      default:
        return id
    }
  }

  const checkTransactions = () => {
    if (recurringTransactions.length === 0 || !recurringTransactions) {
      return false
    } else {
      return true
    }
  }

  const filters = getFiltersForUserTimezone(tz)

  const transactionsWithFilters = recurringTransactions.map((transaction) => ({
    ...transaction,
    filter: filters[transaction.id],
  }))

  return (
    <Paper
      className={classes.paperContainer}
      elevation={0}
      data-guiding-id="mp-dashboard-recurringbillingdeclines"
    >
      <div className={classes.titleContainer}>
        <h2 className={classes.titleH2}>
          {t('merchant-portal.recurring-billing-declines')}
        </h2>
      </div>
      {!isLoading && checkTransactions() && transactionsWithFilters.length ? (
        <HasPermission
          allPermissions={['v2.reports.get', 'v2.recurrings.get']}
          unauthorizedComponent={
            <NoData
              title={t('common.validations.not-available-feature')}
              label={t('common.validations.please-contact-support')}
              icon="error"
            />
          }
        >
          <Grid container spacing={2}>
            {transactionsWithFilters.map((transaction) => (
              <Grid item xs={12} sm={4} key={transaction.id}>
                <InfoPanel
                  to={`/merchant/reports/gateway/recurring-billing-declines?filter[created_ts][$gte]=${transaction.filter.$gte}&filter[created_ts][$lte]=${transaction.filter.$lte}`}
                  title={getTitleById(transaction.id)}
                  cashAmount={transaction.values[1] / 100}
                  details={`${transaction.values[0]} ${t(
                    'merchant-portal.declines'
                  )}`}
                  serviceName={EnumServiceName.RecurringBillingDeclinesReports}
                  guidingId={`mp-dashboard-recurringbillingdeclines-${transaction.id}`}
                />
              </Grid>
            ))}
          </Grid>
        </HasPermission>
      ) : (
        <Box className={classes.loadingContainer}>
          {isLoading ? (
            <CircularProgress data-testid="loading-icon" size={45} />
          ) : isError ? (
            <NoData
              title={t('common.validations.loading-data')}
              label={t('common.validations.loading-data-explainer')}
              icon="error"
              style={{ marginBottom: 5 }}
            />
          ) : (
            <NoData
              title={t('common.validations.not-available-data')}
              label={t('common.validations.not-available-data-display')}
              style={{ marginBottom: 5 }}
            />
          )}
        </Box>
      )}
    </Paper>
  )
}

export default RecurringBillingDeclines
