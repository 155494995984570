import { ColDef } from 'ag-grid-community'
import { useState, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import DateRangeFilter from '@shared/ag-grid/pagination-table/filters/date-range-filter/DateRangeFilter'
import PaginationTable from '@shared/ag-grid/pagination-table/PaginationTable'
import { Deposit } from '@shared/api/src/schemas/types'
import { HasPermission, UserNotAllowed } from '@shared/components'
import {
  useFtpPortalHubCommunication,
  useEnforceLogin,
  useLocations,
} from '@shared/hooks'
import { filterMerchantAccount } from '@shared/mapping/gateway-transactions'
import { EnumServiceName } from '@shared/types'
import { formatDatetime, currency, DataSource } from '@shared/utils'

import DepositDetail from './components/DepositDetails'

export default function Deposits() {
  //TODO: add translation when email receipt or other functionality is added
  const { t } = useTranslation()

  const { setAppBarTitle } = useFtpPortalHubCommunication()
  const { user } = useEnforceLogin()
  const { selectedLocation, allMerchantAccounts } = useLocations()
  const [showDetails, setShowDetails] = useState<boolean>(false)
  const [depositId, setDepositId] = useState<string>('')
  const [selectedDate, setSelectedDate] = useState<string>(null)

  const DepositReportPrivs = ['v2.reports.get']

  useEffect(() => {
    setAppBarTitle(t('merchant-portal.deposits'))
  }, [])

  const datasource = useMemo(() => {
    const extraParams = {}

    const locationId = selectedLocation?.id

    extraParams['filter[location_id]'] = locationId
    return new DataSource('deposits-reports', extraParams)
  }, [selectedLocation?.id])

  const columnDefs: ColDef<Deposit>[] = useMemo(
    () => [
      {
        headerName: t('common.account-number'),
        field: 'bank_account_number',
        floatingFilter: true,
        filter: 'agTextColumnFilter',
        sortable: true,
      },
      {
        headerName: t('common.date-deposit'),
        field: 'date_ts',
        floatingFilter: true,
        filter: DateRangeFilter,
        valueGetter: (params) => {
          const timestamp = params.data?.date_ts
          return formatDatetime(timestamp, user?.tz) || '-'
        },
        filterParams: {
          typeOfSearch: 'contains',
          filterType: 'text',
          service: 'deposits-reports',
          filterPropName: 'date_ts',
          value: selectedDate,
          onApply: (event) => {
            setSelectedDate(event)
          },
        },
        sortable: true,
      },
      {
        headerName: t('common.amount'),
        field: 'amount',
        floatingFilter: true,
        filter: 'agNumberColumnFilter',
        type: 'rightAligned',
        filterParams: {
          allowedCharPattern: '\\d\\-\\.\\$\\,',
          numberParser: (value: number) => {
            return value ? value * 100 : null
          },
        },
        sortable: true,
        valueGetter: (params) => currency(params.data?.amount / 100),
      },
      {
        headerName: t('common.merchant-account'),
        field: 'merchant_name',
        floatingFilter: true,
        filter: 'agSetColumnFilter',
        filterParams: {
          values: filterMerchantAccount(allMerchantAccounts).map(
            (option) => option.value
          ),
          valueFormatter: (params) => {
            const option = filterMerchantAccount(allMerchantAccounts).find(
              (option) => option.value === params.value
            )
            return option ? option.label : params.value
          },
        },
        sortable: true,
      },
    ],
    []
  )

  const defaultColDef = useMemo(
    () => ({
      resizable: true,
    }),
    []
  )

  return (
    <section>
      <HasPermission
        allPermissions={DepositReportPrivs}
        unauthorizedComponent={<UserNotAllowed />}
      >
        <PaginationTable<Deposit>
          columnDefs={columnDefs}
          rowModelType={'serverSide'}
          serverSideDatasource={datasource}
          defaultColDef={defaultColDef}
          onRowClicked={(event) => {
            const depositId = event.data.trxn_src_ck as string
            setDepositId(depositId)
            setShowDetails(true)
          }}
          showExportButton={true}
          showClearFiltersButton={true}
          serviceName={EnumServiceName.DepositsReports}
          guidingId="deposits"
          sizeColumnsToFit
        />
      </HasPermission>
      {showDetails && (
        <DepositDetail
          depositId={depositId}
          locationId={selectedLocation?.id}
          open={showDetails}
          handleClose={() => setShowDetails(false)}
        />
      )}
    </section>
  )
}
