import { ColDef } from 'ag-grid-community'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import DateRangeFilter from '@shared/ag-grid/pagination-table/filters/date-range-filter/DateRangeFilter'
import PaginationTable from '@shared/ag-grid/pagination-table/PaginationTable'
import { ReportSettledTransaction } from '@shared/api/src/schemas/types'
import {
  HasPermission,
  TransactionStatus,
  UserNotAllowed,
  AccountType,
} from '@shared/components'
import {
  useFtpPortalHubCommunication,
  useLocations,
  useEnforceLogin,
  useFilterModel,
} from '@shared/hooks'
import {
  mapTransactionTypeId,
  filterTransactionTypeValues,
  filterTransactionStatusValues,
  filterAccountTypeValues,
} from '@shared/mapping/gateway-transactions'
import { EnumServiceName } from '@shared/types'
import {
  formatDate,
  currency,
  filterParams,
  DataSource,
  formatDatetime,
} from '@shared/utils'

export default function SettledTransactionsCC() {
  //TODO: add translation when email receipt or other functionality is added
  const { t } = useTranslation()

  const { setAppBarTitle } = useFtpPortalHubCommunication()
  const { selectedLocation, setSelectedLocation } = useLocations()
  const { user } = useEnforceLogin()
  const [selectedDate, setSelectedDate] = useState<string>(null)

  const useQuery = () => {
    return new URLSearchParams(location.search)
  }

  const TransactionsReportPrivs = ['v2.reports.get', 'v2.transactions.get']

  useEffect(() => {
    setAppBarTitle(
      t('common.settled-transactions-cc'),
      null,
      t('common.reporting')
    )
  }, [])

  const datasource = useMemo(() => {
    const filters = {
      'filter[location_id]': selectedLocation?.id,
    }

    const extraParams = filterParams(filters)

    return new DataSource('settled-transactions-reports-cc', extraParams)
  }, [selectedLocation?.id])

  const columnDefs: ColDef<
    ReportSettledTransaction & {
      transaction_amount?: number
      transaction_date_ts?: number
    }
  >[] = useMemo(
    () => [
      {
        headerName: t('common.transaction-id'),
        field: 'trxn_src_ck',
        floatingFilter: true,
        filter: 'agTextColumnFilter',
        sortable: true,
      },
      {
        headerName: t('common.date-transaction'),
        field: 'transaction_date_ts',
        floatingFilter: true,
        filter: DateRangeFilter,
        valueGetter: (params) => {
          const timestamp = params.data?.transaction_date_ts
          return formatDatetime(timestamp * 1000, user?.tz) || '-'
        },
        filterParams: {
          typeOfSearch: 'contains',
          filterType: 'text',
          service: 'settled-transactions-reports-cc',
          filterPropName: 'transaction_date_ts',
          value: selectedDate,
          onApply: (event) => {
            setSelectedDate(event)
          },
        },
        sortable: true,
        sort: 'desc',
      },
      {
        headerName: t('common.amount-transaction'),
        field: 'transaction_amount',
        floatingFilter: true,
        filter: 'agNumberColumnFilter',
        type: 'rightAligned',
        filterParams: {
          allowedCharPattern: '\\d\\-\\.\\$\\,',
          numberParser: (value: number) => {
            return value ? value * 100 : null
          },
        },
        sortable: true,
        valueGetter: (params) =>
          currency(params.data?.transaction_amount / 100),
      },
      {
        headerName: t('common.transaction-type'),
        field: 'transaction_type',
        valueGetter: (params) => {
          return mapTransactionTypeId(params.data?.transaction_type)
        },
        filter: 'agSetColumnFilter',
        filterParams: {
          values: filterTransactionTypeValues
            .filter(
              (option) =>
                option && (option.value === '20' || option.value === '30')
            )
            .map((option) => option.value),
          valueFormatter: (params) => {
            const option = filterTransactionTypeValues.find(
              (option) => option.value === params.value
            )
            return option ? option.label : params.value
          },
          comparator: (a, b) => {
            const optionA = filterTransactionTypeValues.find(
              (option) => option.value === a
            )

            const optionB = filterTransactionTypeValues.find(
              (option) => option.value === b
            )

            return optionA.label.localeCompare(optionB.label)
          },
        },
        floatingFilter: true,
        sortable: true,
      },
      {
        headerName: t('merchant-portal.batch-number'),
        field: 'batch_number',
        floatingFilter: true,
        filter: 'agTextColumnFilter',
        sortable: true,
      },
      {
        headerName: t('common.account-type'),
        field: 'account_type',
        filter: 'agSetColumnFilter',
        floatingFilter: true,
        filterParams: {
          values: filterAccountTypeValues.map((option) => option.value),
          valueFormatter: (params) => {
            const option = filterAccountTypeValues.find(
              (option) => option.value === params.value
            )
            return option ? option.label : params.value
          },
        },
        cellRenderer: (data) => {
          return (
            <AccountType
              type={data.value === 'MASTERCARD' ? 'mc' : data.value}
            />
          )
        },
        sortable: true,
      },
      {
        headerName: t('common.amount-surcharge'),
        field: 'surcharge_amount',
        valueGetter: (params) => {
          const data = params.data as any
          const surchargeAmount = data.surcharge?.surcharge_amount
          return surchargeAmount ? currency(surchargeAmount / 100) : '$0.00'
        },
        cellRenderer: (data) => {
          return data.value
        },
      },
      {
        headerName: t('common.status'),
        field: 'transaction_status',
        floatingFilter: true,
        filter: 'agSetColumnFilter',
        filterParams: {
          values: filterTransactionStatusValues.map((option) => option.value), // Send values to API
          valueFormatter: (params) => {
            const option = filterTransactionStatusValues.find(
              (option) => option.value === params.value
            )
            return option ? option.label : params.value
          },
          comparator: (a, b) => {
            const optionA = filterTransactionStatusValues.find(
              (option) => option.value === a
            )

            const optionB = filterTransactionStatusValues.find(
              (option) => option.value === b
            )

            return optionA.label.localeCompare(optionB.label)
          },
        },
        cellRenderer: (data) => <TransactionStatus statusCode={data.value} />,
        sortable: true,
      },
      {
        headerName: t('common.auth-code'),
        field: 'auth_code',
        floatingFilter: true,
        filter: 'agTextColumnFilter',
        sortable: true,
      },
      {
        headerName: t('common.token-last-four'),
        field: 'last_four_digits',
        filterParams: {
          numberParser: (value: number) => value, // Needed to prevent removing leading zeros
        },
        floatingFilter: true,
        filter: 'agNumberColumnFilter',
        sortable: true,
      },
      {
        headerName: t('merchant-portal.merchant-account-id'),
        field: 'merchant_id',
        floatingFilter: true,
        filter: 'agTextColumnFilter',
        sortable: true,
      },
      {
        headerName: t('common.account-holder-name'),
        field: 'account_holder_name',
        floatingFilter: true,
        filter: 'agTextColumnFilter',
        sortable: true,
      },
      {
        headerName: t('merchant-portal.card-classification'),
        field: 'card_classification',
      },
      {
        headerName: t('common.entry-mode'),
        field: 'entry_mode',
        floatingFilter: true,
        filter: 'agTextColumnFilter',
        sortable: true,
      },
      {
        headerName: t('common.order-number'),
        field: 'order_number',
        floatingFilter: true,
        filter: 'agNumberColumnFilter',
        sortable: true,
      },
      {
        headerName: t('merchant-portal.provider-name'),
        field: 'provider_name',
        floatingFilter: true,
        filter: 'agTextColumnFilter',
        sortable: true,
      },
    ],
    []
  )

  const defaultColDef = useMemo(
    () => ({
      resizable: true,
    }),
    []
  )

  useEffect(() => {
    const handleLocationChange = async (event) => {
      const newLocation = event.detail.location
      await setSelectedLocation(newLocation)
    }

    window.addEventListener(
      'FTP_NAVIGATOR_LOCATION_CHANGED',
      handleLocationChange
    )

    return () => {
      window.removeEventListener(
        'FTP_NAVIGATOR_LOCATION_CHANGED',
        handleLocationChange
      )
    }
  }, [])

  const defaultFilters = {
    transaction_date_ts: {
      type: 'equal',
      filterType: 'text',
      filter: 'yesterday',
    },
  }

  const paramKeys = [
    {
      queryKey: 'filter[transaction_date_ts][$gte]',
      filterKey: 'transaction_date_ts',
      filterType: 'greaterThanOrEqual',
      includeTime: true,
    },
  ]

  const initialFilterModel = useFilterModel(defaultFilters, paramKeys)

  return (
    <section>
      <HasPermission
        allPermissions={TransactionsReportPrivs}
        unauthorizedComponent={<UserNotAllowed />}
      >
        <>
          <PaginationTable<ReportSettledTransaction>
            columnDefs={columnDefs}
            rowModelType={'serverSide'}
            serverSideDatasource={datasource}
            defaultColDef={defaultColDef}
            showExportButton={true}
            showClearFiltersButton={true}
            serviceName={EnumServiceName.SettledTransactionsReportsCc}
            initialFilterModel={initialFilterModel}
            guidingId="reports-settledtransactionscc"
          />
        </>
      </HasPermission>
    </section>
  )
}
