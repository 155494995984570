import { GatewayTransaction, api } from '@shared/api'

interface GatewayData {
  achData: GatewayTransaction[] | null
  ccData: GatewayTransaction[] | null
}

export async function fetchGatewayData(
  selectedLocation: string | undefined,
  selectedMerchantAccount: string | undefined,
  hasMerchantAccountACHActive: boolean | undefined,
  hasMerchantAccountCCActive: boolean | undefined
): Promise<GatewayData> {
  const gatewayData: GatewayData = {
    achData: null,
    ccData: null,
  }

  try {
    if (selectedLocation) {
      if (hasMerchantAccountCCActive) {
        const ccResponse = await api.service('gateway-transactions-cc').find({
          query: {
            filter: {
              location_id: selectedLocation,
              product_transaction_id: selectedMerchantAccount,
            },
          },
        })

        gatewayData.ccData = ccResponse
      }

      if (hasMerchantAccountACHActive) {
        const achResponse = await api.service('gateway-transactions-ach').find({
          query: {
            filter: {
              location_id: selectedLocation,
              product_transaction_id: selectedMerchantAccount,
            },
          },
        })

        gatewayData.achData = achResponse
      }
    }
  } catch (error) {
    console.error(error)
  }

  return gatewayData
}
