import { ColDef } from 'ag-grid-community'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import DateRangeFilter from '@shared/ag-grid/pagination-table/filters/date-range-filter/DateRangeFilter'
import PaginationTable from '@shared/ag-grid/pagination-table/PaginationTable'
import { Chargeback } from '@shared/api/src/schemas/types'
import { HasPermission, UserNotAllowed, AccountType } from '@shared/components'
import {
  useFtpPortalHubCommunication,
  useEnforceLogin,
  useLocations,
  useFilterModel,
} from '@shared/hooks'
import { filterAccountTypeValues } from '@shared/mapping/gateway-transactions'
import { EnumServiceName } from '@shared/types'
import { formatDate, currency, DataSource } from '@shared/utils'

export default function ChargebacksGrid() {
  const { t } = useTranslation()

  const { setAppBarTitle } = useFtpPortalHubCommunication()
  const { selectedLocation } = useLocations()
  const [selectedDate, setSelectedDate] = useState<string>(null)

  const { user } = useEnforceLogin()
  const ChargebacksReportPrivs = ['v2.reports.get', 'v2.transactions.get']

  useEffect(() => {
    setAppBarTitle(t('common.chargebacks'))
  }, [])

  const datasource = useMemo(() => {
    const extraParams = {
      'filter[location_id]': selectedLocation?.id,
    }

    return new DataSource('chargebacks-reports', extraParams)
  }, [selectedLocation?.id])

  const columnDefs: ColDef<Chargeback>[] = useMemo(
    () => [
      {
        headerName: t('common.date-dispute'),
        field: 'date_received_ts',
        floatingFilter: true,
        filter: DateRangeFilter,
        valueGetter: (params) => {
          const timestamp = params.data?.date_received_ts
          return formatDate(timestamp, user?.tz) || '-'
        },
        filterParams: {
          typeOfSearch: 'equal',
          filterType: 'text',
          service: 'chargebacks-reports',
          filterPropName: 'date_received_ts',
          value: selectedDate,
          onApply: (event) => {
            setSelectedDate(event)
          },
        },
        sortable: true,
      },
      {
        headerName: t('common.amount-chargeback'),
        field: 'transaction_amount',
        type: 'rightAligned',
        floatingFilter: true,
        filter: 'agNumberColumnFilter',
        filterParams: {
          allowedCharPattern: '\\d\\-\\.\\$\\,',
          numberParser: (value: number) => {
            return value ? value * 100 : null
          },
        },
        sortable: true,
        valueGetter: (params) =>
          currency(params.data?.transaction_amount / 100),
      },
      {
        headerName: t('common.date-transaction'),
        field: 'transaction_date_ts',
        floatingFilter: true,
        filter: DateRangeFilter,
        valueGetter: (params) => {
          const timestamp = params.data?.transaction_date_ts
          return formatDate(timestamp, user?.tz) || '-'
        },
        filterParams: {
          typeOfSearch: 'equal',
          filterType: 'text',
          service: 'chargebacks-reports',
          filterPropName: 'transaction_date_ts',
          value: selectedDate,
          onApply: (event) => {
            setSelectedDate(event)
          },
        },
        sortable: true,
      },
      {
        headerName: t('common.token-last-four'),
        field: 'last_four_digits',
        floatingFilter: true,
        filter: 'agTextColumnFilter',
        sortable: true,
      },
      {
        headerName: t('common.account-type'),
        field: 'account_type',
        filter: 'agSetColumnFilter',
        floatingFilter: true,
        filterParams: {
          values: filterAccountTypeValues.map((option) => option.value),
          valueFormatter: (params) => {
            const option = filterAccountTypeValues.find(
              (option) => option.value === params.value
            )
            return option ? option.label : params.value
          },
        },
        cellRenderer: (data) => {
          return <AccountType type={data.value} />
        },
        sortable: true,
      },
    ],
    []
  )

  const defaultColDef = useMemo(
    () => ({
      resizable: true,
    }),
    []
  )

  const paramKeys = [
    {
      queryKey: 'filter[transaction_date_ts][$gte]',
      filterKey: 'transaction_date_ts',
      filterType: 'greaterThanOrEqual',
      includeTime: true,
    },
  ]

  const initialFilterModel = useFilterModel({}, paramKeys)

  return (
    <section>
      <HasPermission
        allPermissions={ChargebacksReportPrivs}
        unauthorizedComponent={<UserNotAllowed />}
      >
        <PaginationTable<Chargeback>
          getRowId={(data) => data.data.trxn_src_ck}
          columnDefs={columnDefs}
          rowModelType={'serverSide'}
          serverSideDatasource={datasource}
          defaultColDef={defaultColDef}
          showExportButton={true}
          showClearFiltersButton={true}
          serviceName={EnumServiceName.ChargebacksReports}
          initialFilterModel={initialFilterModel}
          guidingId="chargebacks"
          sizeColumnsToFit
        />
      </HasPermission>
    </section>
  )
}
