import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { tss } from 'tss-react/mui'

import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import { Grid, Icon } from '@mui/material'
import Paper from '@mui/material/Paper'
import { SelectChangeEvent } from '@mui/material/Select'
import Typography from '@mui/material/Typography'

//Types
import { MerchantAccount } from '@shared/api/src/schemas/types'
import {
  SelectComponent,
  SelectOption,
  NoData,
  HasPermission,
  Loading,
  UserNotAllowed,
} from '@shared/components'
import AppTheme from '@shared/design'
import {
  useFtpPortalHubCommunication,
  useAuthorization,
  useLocations,
} from '@shared/hooks'
import { Statement } from '@shared/types'
import {
  getMonthFromString,
  sortMerchantAccounts,
  checkPermission,
  getDocumentPeriod,
} from '@shared/utils'

import { getStatements, getReportDownloadUrl } from './api/index'
import StatementsColumn from './components/StatementsColumn'

const useStyles = tss.withName('Statements').create(({ theme }) => ({
  page: {
    background: '#FFF',
    padding: 20,
    border: 'none',
  },
  select: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: 20,
  },
  paper: {
    backgroundColor: theme.palette.common.white,
    padding: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(1),
    },
    border: `2px solid ${theme.palette.grey[300]}`,
    borderRadius: theme.shape.borderRadius,
    overflowY: 'auto',
    width: '100%',
  },
  statementItem: {
    backgroundColor: theme.palette.common.white,
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    border: `2px solid ${theme.palette.grey[300]}`,
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(1),
    marginBottom: theme.spacing(2),
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.palette.grey[100],
    },
  },
  boldText: {
    fontWeight: 'bold',
    color: theme.palette.primary.main, // Adjust color as needed
    marginLeft: theme.spacing(2),
    borderBottom: `2px solid ${theme.palette.primary.main}`,
  },
}))

type StatementsRecord = Record<string, Statement[]>

export default function Statements() {
  const { setAppBarTitle } = useFtpPortalHubCommunication()
  const { classes } = useStyles(AppTheme)
  const { t } = useTranslation()

  const StatementsPrivs = ['v2.billingstatements.get']
  const { userPermissionSet } = useAuthorization()

  const { selectedLocation } = useLocations()
  const [merchantAccounts, setMerchantAccounts] = useState<MerchantAccount[]>(
    []
  )
  const [selectedMerchantAccount, setSelectedMerchantAccount] =
    useState<MerchantAccount | null>(null)

  const [statements, setStatements] = useState<StatementsRecord | null>(null)
  const [mostRecentStatement, setMostRecentStatement] =
    useState<Statement | null>(null)
  const [mostRecentStatementYear, setMostRecentStatementYear] = useState<
    string | null
  >(null)

  const [isLoading, setIsLoading] = useState<boolean>(
    Boolean(!statements || !Object.keys(statements).length)
  )

  useEffect(() => {
    setAppBarTitle(t('common.statements'))
  }, [])

  useEffect(() => {
    if (selectedLocation.product_transactions?.length === 0) return

    const defaultMerchantAccount =
      selectedLocation.product_transactions?.find(
        (merchantAccount) => merchantAccount.id === selectedLocation.default_cc
      ) ??
      selectedLocation.product_transactions?.find(
        (merchantAccount) => merchantAccount.id === selectedLocation.default_ach
      )

    setMerchantAccounts(selectedLocation.product_transactions)
    setSelectedMerchantAccount(defaultMerchantAccount)
  }, [selectedLocation])

  useEffect(() => {
    if (selectedMerchantAccount) getStatementsApi()
  }, [selectedMerchantAccount])

  useEffect(() => {
    if (!statements || !mostRecentStatementYear) return
    const [mostRecentStatement] = statements[mostRecentStatementYear]

    setMostRecentStatement(mostRecentStatement)
  }, [statements, mostRecentStatementYear])

  const getReportDownloadUrlApi = async (documentId: string) => {
    if (
      !selectedMerchantAccount ||
      !checkPermission(userPermissionSet, 'v2.files.get')
    )
      return

    try {
      const url = await getReportDownloadUrl(
        documentId,
        selectedMerchantAccount,
        userPermissionSet
      )
      if (url) {
        window.open(url, '_blank')
      }
    } catch (error) {}
  }

  const mostRecentStatementTitle =
    mostRecentStatement && mostRecentStatementYear
      ? `${getMonthFromString(
          getDocumentPeriod(mostRecentStatement).toString()
        )} - ${mostRecentStatementYear}`
      : ''

  const statementsYears = statements ? Object.keys(statements) : []

  const getStatementsApi = async () => {
    if (!selectedMerchantAccount) return
    setIsLoading(true)
    try {
      const statementsRecord = await getStatements(selectedMerchantAccount)
      const statementsYears = Object.keys(statementsRecord)
      if (statementsYears.length) {
        const [mostRecentStatementYear] = statementsYears.sort().reverse()
        setMostRecentStatementYear(mostRecentStatementYear)
      }
      await setStatements(statementsRecord)
      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
    }
  }

  const getMerchantAccountsSelectOptions = (
    merchantAccounts: MerchantAccount[]
  ): SelectOption<MerchantAccount>[] =>
    sortMerchantAccounts(merchantAccounts).map<SelectOption<MerchantAccount>>(
      (merchantAccount) => ({
        label: merchantAccount.title,
        value: merchantAccount.id,
      })
    )

  const merchantAccountsOptions =
    getMerchantAccountsSelectOptions(merchantAccounts)

  const handleChange = (event: SelectChangeEvent) => {
    setSelectedMerchantAccount(
      merchantAccounts.find(({ id }) => id === event.target.value)
    )
  }

  return (
    <section>
      <HasPermission
        allPermissions={StatementsPrivs}
        unauthorizedComponent={<UserNotAllowed />}
      >
        <>
          {merchantAccountsOptions.length > 1 && (
            <div className={classes.select}>
              <SelectComponent
                data-testid="select-component"
                guidingId="merchant-statements-merchantaccount"
                options={merchantAccountsOptions}
                value={selectedMerchantAccount?.id}
                onChange={handleChange}
              />
            </div>
          )}

          {isLoading && !statementsYears.length ? (
            <Loading />
          ) : !statementsYears.length ? (
            <NoData
              title={t('common.statements')}
              label={t('common.validations.statements.none-available')}
              testId="no-data-statements"
            />
          ) : Boolean(!isLoading && statementsYears.length) ? (
            <div className={`ag-theme-alpine ${classes.page}`}>
              <Paper elevation={0} className={classes.paper}>
                {mostRecentStatement && (
                  <button
                    className={classes.statementItem}
                    onClick={() =>
                      getReportDownloadUrlApi(mostRecentStatement.document_id)
                    }
                    data-guiding-id="merchant-statements-current"
                  >
                    <Typography>
                      {t('merchant-portal.statements.current-statement')}:
                      <span className={classes.boldText}>
                        {mostRecentStatementTitle}
                      </span>
                    </Typography>
                    <Icon>
                      <ChevronRightIcon />
                    </Icon>
                  </button>
                )}

                <Grid container spacing={2} columns={{ xs: 1, md: 2, lg: 3 }}>
                  {selectedMerchantAccount &&
                    statements &&
                    statementsYears
                      .slice()
                      .reverse()
                      .map((year, index) => (
                        <Grid
                          item
                          key={`statement-column-${index}`}
                          sx={{ minWidth: '350px' }}
                        >
                          <StatementsColumn
                            year={year}
                            statements={statements[year]}
                            onDownload={getReportDownloadUrlApi}
                          />
                        </Grid>
                      ))}
                </Grid>
              </Paper>
            </div>
          ) : null}
        </>
      </HasPermission>
    </section>
  )
}
