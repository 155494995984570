export type SettledTransactionCCEntryModeKey = {
  code: string
  description: string
}

export const settledTransactionsCCEntryModes: SettledTransactionCCEntryModeKey[] =
  [
    { code: 'HARDCOPY', description: 'Terminal Not Used/Hardcopy' },
    { code: 'MANUAL ENTRY', description: 'Manual/Key Entered' },
    {
      code: 'SWIPED - TRACK 2 READ',
      description: 'Magnetic Stripe Read - Track 2 Only',
    },
    {
      code: 'SWIPED - TRACK 1 READ',
      description: 'Magnetic Stripe Read - Track 1 Only',
    },
    { code: 'CHIP READ', description: 'Contactless Chip' },
    {
      code: 'MANUAL ENTRY',
      description: 'Card Present Defective Mag Stripe Cannot Be Read.',
    },
    { code: 'ON FILE', description: 'Credential on File' },
    { code: 'UNKNOWN', description: 'Unspecified or Unknown' },
    { code: 'SWIPED - PIN SUPPORTED', description: 'PIN Entry Capable' },
    {
      code: 'SWIPED - PIN NOT SUPPORTED',
      description: 'POS Device Not PIN Entry Capable',
    },
    {
      code: 'SWIPED - PINPAD DEFECT',
      description: 'PIN Capable PINpad Not Working',
    },
    {
      code: 'SWIPED - PIN VERIFIED',
      description: 'PIN Verified by POS Device',
    },
    { code: 'FALLBACK', description: 'Fallback, chip cannot be read' },
    { code: 'ECOM', description: 'Electronic Commerce Transaction' },
    { code: 'MCOM', description: 'Mobile Commerce' },
    {
      code: 'RFID',
      description: 'RFID (Radio Frequency Identification Indicator - Chip',
    },
    { code: 'CONTACTLESS', description: 'Contactless Magnetic Stripe' },
    { code: 'BATCH AUTH', description: 'Batch Authorizations' },
    { code: 'BIOMETRIC', description: 'Biometrics' },
    {
      code: 'SWIPED',
      description: 'Magnetic Stripe Read - Track 1 or 2 not specified',
    },
    { code: 'MANUAL ENTRY', description: 'Auto Entry via Server' },
    { code: 'VOICE AUTH', description: 'Voice Authorizations' },
    { code: 'VOICE RESPONSE', description: 'Voice Response Unit' },
    {
      code: 'CHIP READ - NO CVV',
      description: 'ICC Read - CVV Data Unreliable',
    },
    {
      code: 'QR TRANSACTION',
      description: 'Optical code Used for QR transactions',
    },
    {
      code: 'RFID',
      description: 'Radio Frequency Identification Indicator - Magnetic Stripe',
    },
    { code: 'BARCODE READ', description: 'Bar Code Read' },
    { code: 'READ VIA OCR', description: 'OCR Read' },
  ]
