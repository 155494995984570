import React from 'react'
export const DocumentIcon = () => (
  <svg
    width="32"
    height="33"
    viewBox="0 0 32 33"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="document-icon">
      <path
        id="Path"
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M24.5518 8.95129L20.7812 5.18063C20.2812 4.68063 19.6025 4.39929 18.8958 4.39929H9.33317C7.85984 4.39929 6.6665 5.59263 6.6665 7.06596V25.7326C6.6665 27.206 7.85984 28.3993 9.33317 28.3993H22.6665C24.1398 28.3993 25.3332 27.206 25.3332 25.7326V10.8366C25.3332 10.13 25.0518 9.45129 24.5518 8.95129V8.95129Z"
        stroke="#D64123"
        stroke-width="1.7"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        id="Path_2"
        d="M10.6665 12.3993H21.3332"
        stroke="#D64123"
        stroke-width="1.7"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        id="Path_3"
        d="M10.6665 16.3993H21.3332"
        stroke="#D64123"
        stroke-width="1.7"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        id="Path_4"
        d="M10.6665 20.3993H15.9998"
        stroke="#D64123"
        stroke-width="1.7"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
  </svg>
)
