import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { User } from '@shared/api/src'
import {
  FieldGroupContainer,
  FieldGroupRow,
  FieldGroupViewItem,
} from '@shared/components'
import { formatPhoneNumber } from '@shared/utils'

import EditMyNotificationsMethods from './components/EditMyNotificationsMethods'

interface MyNotificationsMethodsProps {
  user: User
}

export default function MyNotificationsMethods({
  user,
}: MyNotificationsMethodsProps) {
  const { t } = useTranslation()

  const [editMyNotificationMethods, setEditMyNotificationMethods] =
    useState(false)

  const valueUserEmail = user?.email
  const valueUserCellPhone = formatPhoneNumber(user?.cell_phone)

  return (
    <>
      <FieldGroupContainer
        title={t(
          'merchant-portal.notifications-settings.my-notification-methods'
        )}
        description={t(
          'merchant-portal.notifications-settings.changing-the-email-address-here'
        )}
        titleTestID="my-notification-methods-title"
        buttonData={{
          label: t(
            'merchant-portal.notifications-settings.edit-my-notification-methods'
          ),
          color: 'secondary',
          onClick: () => setEditMyNotificationMethods(true),
        }}
      >
        <FieldGroupRow spacing={2}>
          <FieldGroupViewItem
            title={t('common.email')}
            value={valueUserEmail}
          />
          <FieldGroupViewItem
            title={t('common.phone-cell')}
            value={valueUserCellPhone}
          />
        </FieldGroupRow>
      </FieldGroupContainer>

      {editMyNotificationMethods && (
        <EditMyNotificationsMethods
          user={user}
          open={editMyNotificationMethods}
          handleClose={() => setEditMyNotificationMethods(false)}
        />
      )}
    </>
  )
}
