import { ColDef } from 'ag-grid-enterprise'
import { AgGridReact } from 'ag-grid-react'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'

import { SelectChangeEvent } from '@mui/material'

import DateRangeFilter from '@shared/ag-grid/pagination-table/filters/date-range-filter/DateRangeFilter'
import PaginationTable from '@shared/ag-grid/pagination-table/PaginationTable'
import { ACHRejectReport, MerchantAccount } from '@shared/api'
import {
  SelectComponent,
  SelectOption,
  HasPermission,
  UserNotAllowed,
  ButtonBar,
  ButtonBarEnd,
} from '@shared/components'
import {
  useEnforceLogin,
  useFilterModel,
  useFtpPortalHubCommunication,
  useLocations,
} from '@shared/hooks'
import { secCodeFilterOptionsDatahub } from '@shared/mapping/gateway-transactions'
import { EnumServiceName, PaymentMethodType } from '@shared/types'
import {
  currency,
  sortMerchantAccounts,
  formatDate,
  DataSource,
} from '@shared/utils'

export default function ACHRejects() {
  const { t } = useTranslation()
  const { user } = useEnforceLogin()
  const { search } = useLocation()

  const { allMerchantAccounts, selectedLocation, setSelectedLocation } =
    useLocations()

  const { setAppBarTitle } = useFtpPortalHubCommunication()
  const [merchantAccounts, setMerchantAccounts] = useState<MerchantAccount[]>(
    []
  )
  const [selectedMerchantAccount, setSelectedMerchantAccount] =
    useState<MerchantAccount | null>(null)

  const gridRef = useRef<AgGridReact>(null)

  const ACHRejectsReportsPriv = ['v2.reports.get', 'v2.transactions.get']

  const extraParamsToExport = useMemo(
    () => ({
      product_transaction_id: selectedMerchantAccount?.id,
    }),
    [selectedMerchantAccount]
  )

  const getGridRef = useCallback((ref) => {
    gridRef.current = ref
  }, [])

  useEffect(() => {
    setAppBarTitle(
      t('merchant-portal.ach-rejects'),
      null,
      t('common.reporting')
    )
  }, [])

  useEffect(() => {
    if (!allMerchantAccounts || !selectedLocation) return

    const achMerchantAccounts = allMerchantAccounts.filter(
      ({ payment_method }) => payment_method === PaymentMethodType.ACH
    )

    const defaultACHMerchantAccount =
      achMerchantAccounts.find(({ id }) => {
        return id === selectedLocation.default_ach
      }) ?? achMerchantAccounts[0]

    setMerchantAccounts(achMerchantAccounts)
    setSelectedMerchantAccount(defaultACHMerchantAccount)
  }, [allMerchantAccounts])

  const datasource = useMemo(() => {
    if (!selectedMerchantAccount || !selectedLocation) return null

    return new DataSource(
      'ach-rejects-reports',
      {
        filterVariant: 'filterBy',
        fixedFilters: {
          product_transaction_id: selectedMerchantAccount.id,
        },
      },
      {
        'filter[location_id]': selectedLocation.id,
      }
    )
  }, [selectedMerchantAccount, selectedLocation])

  const columnDefs: ColDef<ACHRejectReport>[] = useMemo(
    () => [
      {
        headerName: t('common.date-reported'),
        field: 'date_reported_ts',
        floatingFilter: true,
        filter: DateRangeFilter,
        valueGetter: (params) => {
          const timestamp = params.data?.date_reported_ts
          return formatDate(timestamp, user?.tz)
        },
        filterParams: {
          type: 'past',
          forceOnlyCustom: true,
          showTimePicker: true,
        },
        sortable: true,
      },
      {
        headerName: t('common.date-transaction'),
        field: 'entry_date_ts',
        floatingFilter: true,
        filter: DateRangeFilter,
        valueGetter: (params) => {
          const timestamp = params.data?.entry_date_ts
          return formatDate(timestamp, user?.tz)
        },
        filterParams: {
          type: 'past',
          forceOnlyCustom: true,
          showTimePicker: true,
        },
        sortable: true,
      },
      {
        headerName: t('common.amount-rejected'),
        field: 'amount',
        type: 'rightAligned',
        floatingFilter: true,
        filter: 'agNumberColumnFilter',
        valueGetter: (params) => currency(params.data?.amount / 100),
        sortable: true,
        filterParams: {
          allowedCharPattern: '\\d\\-\\.\\$\\,',
          numberParser: (value: number) => {
            return value ? value * 100 : null
          },
          filterOptions: ['equals'],
          maxNumConditions: 1,
        },
      },
      {
        headerName: t('merchant-portal.reason-code'),
        field: 'reason_code',
        floatingFilter: true,
        sortable: true,
        filter: 'agTextColumnFilter',
        filterParams: {
          filterOptions: ['contains'],
          maxNumConditions: 1,
        },
      },
      {
        headerName: t('common.reason-description'),
        field: 'reason_desc',
        floatingFilter: true,
        sortable: true,
      },
      {
        headerName: t('common.account-number'),
        field: 'account_number',
        floatingFilter: true,
        sortable: true,
        filter: 'agTextColumnFilter',
        filterParams: {
          filterOptions: ['contains'],
          maxNumConditions: 1,
        },
      },
      {
        headerName: t('merchant-portal.trace-number'),
        field: 'trace_no',
        floatingFilter: true,
        sortable: true,
        filter: 'agTextColumnFilter',
        filterParams: {
          filterOptions: ['contains'],
          maxNumConditions: 1,
        },
      },
      {
        headerName: t('common.transaction-sec-code'),
        field: 'entry_class',
        floatingFilter: true,
        sortable: true,
        filter: 'agSetColumnFilter',
        filterParams: {
          values: secCodeFilterOptionsDatahub,
          valueFormatter: (params) => {
            const option = secCodeFilterOptionsDatahub.find(
              (option) => option === params.value
            )
            return option ? option : params.value
          },
          comparator: (a, b) => {
            const aOption = secCodeFilterOptionsDatahub.find(
              (option) => option === a
            )
            const bOption = secCodeFilterOptionsDatahub.find(
              (option) => option === b
            )
            return aOption.localeCompare(bOption)
          },
        },
      },
    ],
    []
  )

  const defaultColDef = useMemo(
    () => ({
      resizable: true,
    }),
    []
  )

  const getMerchantAccountsSelectOptions = (
    merchantAccounts: MerchantAccount[]
  ): SelectOption<MerchantAccount>[] =>
    sortMerchantAccounts(
      merchantAccounts.filter((merchantAccount) => merchantAccount.active)
    ).map<SelectOption<MerchantAccount>>((merchantAccount) => ({
      label: merchantAccount.title,
      value: merchantAccount.id,
    }))

  const options = useMemo(
    () => getMerchantAccountsSelectOptions(merchantAccounts),
    [merchantAccounts]
  )

  const handleChange = (event: SelectChangeEvent) => {
    setSelectedMerchantAccount(
      merchantAccounts.find(({ id }) => id === event.target.value)
    )
  }

  const paramKeys = [
    {
      queryKey: 'filter[date_reported_ts][$gte]',
      filterKey: 'date_reported_ts',
      filterType: 'greaterThanOrEqual',
      includeTime: true,
    },
  ]

  const initialFilterModel = useFilterModel({}, paramKeys)

  return (
    <HasPermission
      allPermissions={ACHRejectsReportsPriv}
      unauthorizedComponent={<UserNotAllowed />}
    >
      <>
        {options.length > 1 && (
          <ButtonBar>
            <ButtonBarEnd>
              <SelectComponent
                placeholder={t('common.merchant-account-filter')}
                options={options}
                value={selectedMerchantAccount?.id}
                onChange={handleChange}
                style={{
                  width: '300px',
                }}
              />
            </ButtonBarEnd>
          </ButtonBar>
        )}
        <PaginationTable<ACHRejectReport>
          getRowId={(data) => data.data.transaction_id}
          getGridRef={getGridRef}
          columnDefs={columnDefs}
          rowModelType="serverSide"
          serverSideDatasource={datasource}
          defaultColDef={defaultColDef}
          showExportButton
          showClearFiltersButton
          serviceName={EnumServiceName.AchRejectsReports}
          extraParamsToExport={extraParamsToExport}
          initialFilterModel={initialFilterModel}
          guidingId="reports-achrejects"
        />
      </>
    </HasPermission>
  )
}
