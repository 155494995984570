import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { tss } from 'tss-react/mui'

import { Typography } from '@mui/material'

import { api, User } from '@shared/api'
import {
  InputPhone,
  Input,
  Button,
  FieldGroupRow,
  ActionModal,
} from '@shared/components'
import { useEnforceLogin } from '@shared/hooks'
import { toFieldErrors } from '@shared/utils'

export interface EditMyNotificationsMethodsProps {
  user: User
  open?: boolean
  handleClose: (event: any) => void
}

const useStyles = tss
  .withName('EditMyNotificationsMethods')
  .create(({ theme }) => ({
    modal: {
      width: '40%',
    },
    description: {
      fontFamily: 'Inter',
      fontSize: '14px',
      fontWeight: '400',
      lineHeight: '20px',
      color: theme.palette['neutral-600'],
      marginBottom: '24px',
    },
  }))

export default function EditMyNotificationsMethods({
  user,
  open,
  handleClose,
}: EditMyNotificationsMethodsProps) {
  const { t } = useTranslation()
  const { classes } = useStyles()
  const [email, setEmail] = useState(user?.email)
  const [cellPhone, setCellPhone] = useState(user?.cell_phone)
  const [isSaving, setIsSaving] = useState(false)
  const [errors, setErrors] = useState<{ cell_phone: string; email: string }>(
    null
  )
  const { updateUser } = useEnforceLogin()

  const processPhone = (phone: string | null | undefined) => {
    if (!phone) {
      return phone
    }
    return phone.replace(/^(\+?1|1)/, '').replace(/[()-\s]/g, '')
  }

  const saveNotificationMethods = async (
    user: User | undefined,
    email: string,
    cellPhone: string
  ) => {
    setErrors(null)
    setIsSaving(true)
    try {
      const updatedUser = await api.service('users').patch(user?.id, {
        email: email,
        cell_phone: processPhone(cellPhone),
      })
      updateUser(updatedUser)
      setIsSaving(false)
      handleClose(true)
    } catch (error) {
      await setErrors(toFieldErrors(error))
      setIsSaving(false)
    }
  }

  return (
    <ActionModal
      open={open}
      title={t(
        'merchant-portal.notifications-settings.edit-my-notification-methods'
      )}
      buttons={[
        {
          label: t('common.cancel'),
          color: 'secondary',
          onClick: () => handleClose(true),
        },
        {
          label: t('common.save-changes'),
          onClick: () => saveNotificationMethods(user, email, cellPhone),
          isLoading: isSaving,
          color: 'primary',
        },
      ]}
      onClose={() => handleClose(false)}
      className={classes.modal}
    >
      <Typography className={classes.description}>
        {t(
          'merchant-portal.notifications-settings.changing-the-email-address-here'
        )}
      </Typography>

      <FieldGroupRow>
        <Input
          type="text"
          label={t('common.email')}
          placeholder={t('common.email-placeholder')}
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          error={!!errors?.email}
          helperText={errors?.email}
          testId="email-input"
          style={{ width: '100%' }}
        />
      </FieldGroupRow>

      <FieldGroupRow>
        <InputPhone
          label={t('common.phone-cell')}
          value={cellPhone}
          onChange={(event) => setCellPhone(event.target.value)}
          error={!!errors?.cell_phone}
          helperText={errors?.cell_phone}
          testId="phone-input"
          style={{ width: '100%' }}
        />
      </FieldGroupRow>
    </ActionModal>
  )
}
