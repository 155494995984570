import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { tss } from 'tss-react/mui'

import ErrorRoundedIcon from '@mui/icons-material/ErrorRounded'
import { Box, Typography, Paper } from '@mui/material'

import DisplayTable from '@shared/ag-grid/display-table/DisplayTable'
import { api } from '@shared/api/src'
import {
  CustomMessage,
  FieldGroupContainer,
  FieldGroupRow,
  FieldGroupViewItem,
  FieldGroupViewItemWithChildren,
  Toggle,
} from '@shared/components'
import { useAuthorization, useLocations } from '@shared/hooks'
import { PaymentMethodType } from '@shared/types'
import {
  checkSomePermissions,
  sortMerchantAccounts,
  useFormatBooleanAsYesNo,
} from '@shared/utils'

import EditQuickInvoiceSettings from './modals/EditQuickInvoiceSettings'

const useStyles = tss.withName('ServicesSettings').create(({ theme }) => ({
  title: {
    color: theme.palette['neutral-600'],
    fontFamily: 'Inter',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '24px',
    marginBottom: '12px',
  },
}))

export const ServicesSettings = () => {
  const { t } = useTranslation()
  const { userPermissionSet } = useAuthorization()
  const { classes } = useStyles()
  const [openEditQuickInvoiceModal, setOpenEditQuickInvoiceModal] =
    useState(false)
  const { selectedLocation, setSelectedLocation } = useLocations()
  const [isLoadingAvs, setIsLoadingAvs] = useState<boolean>(false)

  const handleOpenModal = () => {
    setOpenEditQuickInvoiceModal(true)
  }

  const handleCloseModal = () => {
    setOpenEditQuickInvoiceModal(false)
  }

  const productRecurring = selectedLocation?.product_recurring
  const valueProductRecurringRequireFullPayment = useFormatBooleanAsYesNo(
    productRecurring?.require_full_payment
  )

  const productTransactionsAbleToQuickInvoice =
    sortMerchantAccounts(
      selectedLocation?.product_transactions?.filter((producttransaction) => {
        return (
          producttransaction?.payment_method !== PaymentMethodType.CASH &&
          producttransaction?.active
        )
      }),
      true,
      true
    ) || []

  const columnDefs = useMemo(
    () => [
      { headerName: t('common.merchant-account'), field: 'title' },
      {
        headerName: t('merchant-portal.location.quick-invoice-status'),
        field: 'quick_invoice_allow',
        valueFormatter: (params: any) =>
          params.value ? t('common.enabled') : t('common.disabled'),
      },
    ],
    [t]
  )

  const getProductRecurringDefaultNotificationDays = (
    value: number,
    formatBoolean: (val: boolean) => string
  ) => {
    return value > 0
      ? `${formatBoolean(true)}, ${value} ${t('common.day-s')}`
      : formatBoolean(false)
  }

  const valueProductRecurringDefaultNotificationDays =
    getProductRecurringDefaultNotificationDays(
      productRecurring?.notification_days_default,
      useFormatBooleanAsYesNo
    )
  const valueProductRecurringSendDeclinedNotification = useFormatBooleanAsYesNo(
    productRecurring?.send_declined_notifications
  )
  const valueProductRecurringSendExpiringTokenNotification =
    useFormatBooleanAsYesNo(productRecurring?.expire_notification_email_enable)
  const productToken = selectedLocation?.product_token

  const defaultCC = useMemo(
    () =>
      selectedLocation?.product_transactions?.find(
        (l) => l.id === selectedLocation.default_cc
      ),
    [selectedLocation?.product_transactions, selectedLocation?.default_cc]
  )
  const valueRunAVSonTokenCreate = useMemo(
    () => !!defaultCC?.run_avs_on_accountvault_create,
    [defaultCC?.run_avs_on_accountvault_create]
  )

  const onChangeAvs = async (value: boolean) => {
    setIsLoadingAvs(true)
    try {
      const updatedDefaultMerchantAccount = await api
        .service('merchant-accounts')
        .patch(selectedLocation.default_cc as string, {
          run_avs_on_accountvault_create: value,
        })

      const updatedMerchantAccounts = selectedLocation.product_transactions.map(
        (transaction) =>
          transaction.id === selectedLocation.default_cc
            ? updatedDefaultMerchantAccount
            : transaction
      )

      setSelectedLocation({
        ...selectedLocation,
        product_transactions: updatedMerchantAccounts,
      })
    } catch (error) {
      console.error('Failed to update AVS:', error)
    } finally {
      setIsLoadingAvs(false)
    }
  }

  return (
    <FieldGroupContainer
      title={t('merchant-portal.location.quick-invoice-settings')}
      buttonData={
        checkSomePermissions(
          userPermissionSet,
          'v2.users.delete.removeverification',
          'v2.users.post.sendverification'
        )
          ? {
              label: t('common.edit'),
              color: 'secondary',
              onClick: handleOpenModal,
            }
          : null
      }
    >
      <Box marginBottom="24px">
        <CustomMessage
          showIcon
          message={t(
            'merchant-portal.location.quick-invoice-cannot-be-enabled'
          )}
          IconComponent={ErrorRoundedIcon}
          iconStyle={{
            color: '#4197CB',
            background: '#E5E7EB',
          }}
        />

        <DisplayTable
          data={productTransactionsAbleToQuickInvoice}
          columnDefs={columnDefs}
          height="auto"
        />
      </Box>

      {productRecurring && productRecurring?.active ? (
        <Box marginBottom="24px">
          <Typography className={classes.title}>
            {t('merchant-portal.location.recurring-billing-settings')}
          </Typography>
          <FieldGroupRow>
            <FieldGroupViewItem
              title={t(
                'merchant-portal.recurring-settings.require-full-payment'
              )}
              value={valueProductRecurringRequireFullPayment}
            />
            <FieldGroupViewItem
              title={t(
                'merchant-portal.recurring-settings.default-notification-days'
              )}
              value={valueProductRecurringDefaultNotificationDays}
            />
          </FieldGroupRow>
          <FieldGroupRow>
            <FieldGroupViewItem
              title={t(
                'merchant-portal.recurring-settings.send-declined-notifications'
              )}
              value={valueProductRecurringSendDeclinedNotification}
            />
            <FieldGroupViewItem
              title={t(
                'merchant-portal.recurring-settings.send-expiring-token-notifications'
              )}
              value={valueProductRecurringSendExpiringTokenNotification}
            />
          </FieldGroupRow>
        </Box>
      ) : null}

      {productToken && productToken?.active ? (
        <Box marginBottom="24px">
          <Typography className={classes.title}>
            {t('merchant-portal.location.token-settings')}
          </Typography>
          <FieldGroupRow>
            <FieldGroupViewItemWithChildren
              title={t('merchant-portal.location.avs-card-addition')}
              subtitle={t(
                'merchant-portal.location.avs-card-addition-if-enabled'
              )}
              isValueAlignRight
              isFullWidth
            >
              <Toggle
                checked={valueRunAVSonTokenCreate}
                testId="avs-toggle"
                onChange={() => onChangeAvs(!valueRunAVSonTokenCreate)}
                isLoading={isLoadingAvs}
              />
            </FieldGroupViewItemWithChildren>
          </FieldGroupRow>
        </Box>
      ) : null}

      {openEditQuickInvoiceModal && (
        <EditQuickInvoiceSettings
          open={openEditQuickInvoiceModal}
          handleClose={handleCloseModal}
          merchantAccounts={productTransactionsAbleToQuickInvoice}
          locationInfo={selectedLocation}
        />
      )}
    </FieldGroupContainer>
  )
}
